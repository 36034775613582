// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, authStr, getKeys, getBrandProfiles } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from "../../actions";
import { isNil } from "ramda";

export function* callUpdateBrandProfileWorker ({ payload: { profile }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId: account_uid } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/brand-profile/${profile.id}${ isNil(profile.status) ? '' : '/set-status' }`, {
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                ...profile,
                account_uid
            }),
        });
        const { response: profileData, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }
        const profiles = yield select(getBrandProfiles);
        const data = [...profiles];
        const index = profiles.findIndex(o => `${o.id}` === `${profile.id}`);

        data.splice(index, 1, profileData);
        yield put(userActions.setUserState('brandProfiles', data));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
