// Core
import {call, put, select} from 'redux-saga/effects';

// Instruments
import { api, getCompanyBranding } from '../../../../helpers/api';
import { uiActions } from '../../actions';
import { userActions } from '../../../user/actions';
import { modalsActions } from "../../../modals/actions";

export function* callUploadFileWorker ({ payload: { file, action }}) {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(fetch, `${api}/v1/upload_file`, {
            method: 'POST',
            body: file,
        });
        const { response: data, error: {message}} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( action === 'upload_favicon' ) {
            const branding = yield select(getCompanyBranding);
            yield put(modalsActions.setModal(null));
            yield put(userActions.setUserCompanyState('branding', { ...branding, favicon: { file_id: data[0].file_id, url: data[0].url }}));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
