// Core
import { useEffect, useRef } from "react";

// Components
import Checkbox from "../../icons/Checkbox";
import CheckedCheckbox from "../../icons/CheckedCheckbox";

// Hooks
import { useModals } from "../../../hooks/useModals";
import { useLists } from "../../../hooks/useLists";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { isNil } from "ramda";
import update from 'immutability-helper';

export const ModalAccountsList = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);

    /* Hooks */
    const { activeSub } = useModals();
    const { accountsWithoutSubs: list, setListsState } = useLists();
    const { assignAccountsToSubAsync } = useUser();

    /* Actions */
    const onAccountChange = ({ currentTarget: { id, value }}) => {
        const index = list.findIndex(o => o.account_uid === id);
        const data = update(list, {
            [index]: {
                current_state: {$set: value}
            }
        });

        setListsState('accountsWithoutSubs',data);
    };
    const onUpdateList = () => {
        if ( isNil(activeSub) ) return null;
        const { subscription_uid, accounts } = activeSub;
        const { account_uid } = accounts[0];
        const data = list.map(o => ({ account_uid: o.account_uid, state: o.current_state }));

        assignAccountsToSubAsync({
            subscription_uid,
            account_uid,
            accounts: data,
        });
    };

    /* Did mount */
    useEffect(() => {
        setPos( modalRef );
    }, []);

    useEffect(() => {
        if ( !isNil(list) ) {
            setPos( modalRef );
        }
    }, [list]);

    /* Html */
    const getList = () => {
        if ( isNil(list) ) return null;

        return list.map(o => {
            const isChecked = o.current_state === 'assigned';

            return <div key={ o.account_uid } className={`gac-acc-list__item ${ o.is_billing ? 'disabled' : '' }`}>
                <label htmlFor={`${o.account_uid}`}>
                    <input
                        type = 'checkbox'
                        id = {`${o.account_uid}`}
                        name = 'accounts'
                        value = { isChecked ? 'unassigned' : 'assigned' }
                        checked = { isChecked }
                        onChange = { o.is_billing ? () => {} : onAccountChange }/>
                    <span>{ isChecked ? <CheckedCheckbox/> : <Checkbox/> }</span>
                    { o.account_name }
                </label>
            </div>
        });
    };
    const data = getList();

    return(
        <div ref = { modalRef } className = 'gac-modal-subscription gac-update-acc-list'>
            <span className = 'gac-close-modal' onClick = { closeModal }/>
            <h1>Update account list</h1>
            { !isNil(data) && <div className="gac-acc-list">
                { data }
            </div> }
            { !isNil(data) && <div className="gac-btn-wrap">
                <div className='gac-btn gac-btn-s' onClick = { onUpdateList }>Update list</div>
            </div> }
        </div>
    );
};