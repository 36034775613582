// Core
import { call, put } from 'redux-saga/effects';

// Components
import Cookies from 'universal-cookie';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";

// Instruments
import { api, domain, epiic, isStage } from '../../../../helpers/api';
import { authActions } from '../../../auth/actions';
import { formsActions } from "../../../forms/actions";
import { uiActions } from '../../../ui/actions';
import { includes, isEmpty, isNil } from 'ramda';
import { isLocal, isWhiteLabel } from '../../../../helpers/helpers';
import { initialState } from "../../../forms/reducer";

export function* callCreateCompanyWorker ({ payload: { company, navigate }}) {
    try {
        yield put(uiActions.enableFetching());
        let data = {
            ...company,
            host: includes(epiic, window.location.href) ? epiic : 'gac',
        };
        const cookies = new Cookies();
        const codes = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_id', 'ref'];
        codes.forEach(code => {
            const value = cookies.get(`_gac_${code}`);
            if ( !isNil(value) && !isEmpty(value) ) {
                data = {
                    ...data,
                    [code === 'ref' ? 'inviter_code' : code]: value,
                }
            }
        });

        const response = yield call(fetch, `${api}/v1/company`, {
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(data),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(authActions.login({ email: company.user_email, password: company.user_password }, navigate ));
        yield put(formsActions.updateFormState('companyNew', initialState.companyNew));
        let options = {
            path: '/',
        };
        if (!isLocal) {
            options = {
                ...options,
                domain: isWhiteLabel() ? window.location.hostname : `.${domain}`
            };
        }
        cookies.remove('_gac_inviter_id', options);
        if ( !isStage ) {
            ReactPixel.track('CompleteRegistration');
            ReactGA.event('CompleteRegistration');
            window.twq('event', 'tw-ohd21-ohdlk', {
                email_address: company.user_email
            });
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
