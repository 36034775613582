// Core
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { userActions } from "../bus/user/actions";
import { userActionsAsync } from "../bus/user/saga/asyncActions";

export const useUser = () => {
    const selector = (state) => state.user;
    const user = useSelector(selector);
    const dispatch = useDispatch();

    /* Actions */
    const setUserState = (prop, data) => {
        dispatch(userActions.setUserState(prop, data));
    };
    const setUserCompanyState = (prop, data) => {
        dispatch(userActions.setUserCompanyState(prop, data));
    };
    const updateOrderCount = (account, order_count) => {
        dispatch(userActions.updateOrderCount(account, order_count));
    };

    /* Async actions */
    const fetchBalanceAsync = (account_id) => {
        dispatch(userActionsAsync.fetchBalanceAsync(account_id));
    };
    const fetchBrandProfilesAsync = () => {
        dispatch(userActionsAsync.fetchBrandProfilesAsync());
    };
    const createBrandProfileAsync = (profile) => {
        dispatch(userActionsAsync.createBrandProfileAsync(profile));
    };
    const updateBrandProfileAsync = (profile) => {
        dispatch(userActionsAsync.updateBrandProfileAsync(profile));
    };
    const fetchTalentsBlacklistAsync = (type, accountId) => {
        dispatch(userActionsAsync.fetchTalentsBlacklistAsync(type, accountId));
    };
    const fetchTalentsAsync = (data) => {
        dispatch(userActionsAsync.fetchTalentsAsync(data));
    };
    const createDesignerAsync = (data, navigate) => {
        dispatch(userActionsAsync.createDesignerAsync(data, navigate));
    };
    const updateTalentAsync = (data, action) => {
        dispatch(userActionsAsync.updateTalentAsync(data, action));
    };
    const sendNpsAsync = (data) => {
        dispatch(userActionsAsync.sendNpsAsync(data));
    };

    /* Accounts */
    const fetchAccountsAsync = () => {
        dispatch(userActionsAsync.fetchAccountsAsync());
    };
    const fetchAccountAsync = (accountId, action) => {
        dispatch(userActionsAsync.fetchAccountAsync(accountId, action));
    };
    const deleteAccountAsync = (accountId, navigate) => {
        dispatch(userActionsAsync.deleteAccountAsync(accountId, navigate));
    };
    const createAccountAsync = (account, navigate) => {
        dispatch(userActionsAsync.createAccountAsync(account, navigate));
    };
    const updateAccountAsync = (account, action) => {
        dispatch(userActionsAsync.updateAccountAsync(account, action));
    };
    const createPublicationAsync = (publication, action) => {
        dispatch(userActionsAsync.createPublicationAsync(publication, action));
    };
    const updatePublicationAsync = (publication, action) => {
        dispatch(userActionsAsync.updatePublicationAsync(publication, action));
    };

    /* User */
    const fetchUserDetailsAsync = () => {
        dispatch(userActionsAsync.fetchUserDetailsAsync());
    };
    const fetchUsersAsync = () => {
        dispatch(userActionsAsync.fetchUsersAsync());
    };
    const createUserAsync = (user, action, navigate) => {
        dispatch(userActionsAsync.createUserAsync(user, action, navigate));
    };
    const updateUserAsync = (user, isCurrentUser, action) => {
        dispatch(userActionsAsync.updateUserAsync(user, isCurrentUser, action));
    };
    const deleteUserAsync = (userId, action, navigate) => {
        dispatch(userActionsAsync.deleteUserAsync(userId, action, navigate));
    };
    const addUserToAccountAsync = (userId, accountId, isCurrentUser) => {
        dispatch(userActionsAsync.addUserToAccountAsync(userId, accountId, isCurrentUser));
    };
    const removeUserFromAccountAsync = (userId, accountId, isCurrentUser) => {
        dispatch(userActionsAsync.removeUserFromAccountAsync(userId, accountId, isCurrentUser));
    };
    const restorePasswordAsync = (email) => {
        dispatch(userActionsAsync.restorePasswordAsync(email));
    };
    const updatePasswordAsync = (password, secret, navigate) => {
        dispatch(userActionsAsync.updatePasswordAsync(password, secret, navigate));
    };
    const fetchDataByIpAsync = () => {
        dispatch(userActionsAsync.fetchDataByIpAsync());
    };
    const sendProspectAsync = (email, phone) => {
        dispatch(userActionsAsync.sendProspectAsync(email, phone));
    };

    /* Company */
    const fetchCompanyAsync = (action) => {
        dispatch(userActionsAsync.fetchCompanyAsync(action));
    };
    const fetchCompanySubscriptionsAsync = (action) => {
        dispatch(userActionsAsync.fetchCompanySubscriptionsAsync(action));
    };
    const createCompanyAsync = (company, navigate) => {
        dispatch(userActionsAsync.createCompanyAsync(company, navigate));
    };
    const updateCompanyAsync = (company, action) => {
        dispatch(userActionsAsync.updateCompanyAsync(company, action));
    };
    const updateProposalCompanyAsync = (company, isProposalCompanyUpdated) => {
        dispatch(userActionsAsync.updateProposalCompanyAsync(company, isProposalCompanyUpdated));
    };

    /* Subscriptions */
    const createDesignSubscriptionAsync = (subscription, navigate, action) => {
        dispatch(userActionsAsync.createDesignSubscriptionAsync(subscription, navigate, action));
    };
    const createSubscriptionAsync = (subscription, navigate, action) => {
        dispatch(userActionsAsync.createSubscriptionAsync(subscription, navigate, action));
    };
    const updateSubscriptionAsync = (data, navigate, action) => {
        dispatch(userActionsAsync.updateSubscriptionAsync(data, navigate, action));
    };
    const updateSubscriptionCustomAsync = (data, navigate, action) => {
        dispatch(userActionsAsync.updateSubscriptionCustomAsync(data, navigate, action));
    };
    const updateDesignSubscriptionAsync = (data, navigate, action) => {
        dispatch(userActionsAsync.updateDesignSubscriptionAsync(data, navigate, action));
    };
    const cancelPauseSubscriptionAsync = (data, navigate) => {
        dispatch(userActionsAsync.cancelPauseSubscriptionAsync(data, navigate));
    };
    const assignAccountsToSubAsync = (data) => {
        dispatch(userActionsAsync.assignAccountsToSubAsync(data));
    };
    const changeCardAsync = (data) => {
        dispatch(userActionsAsync.changeCardAsync(data));
    };
    const cancelSubscriptionAsync = (data, action, navigate) => {
        dispatch(userActionsAsync.cancelSubscriptionAsync(data, action, navigate));
    };
    const pauseSubscriptionAsync = (data, navigate) => {
        dispatch(userActionsAsync.pauseSubscriptionAsync(data, navigate));
    };

    /* Other */
    const fetchProposalAsync = (id) => {
        dispatch(userActionsAsync.fetchProposalAsync(id));
    };
    const validateCnameAsync = (domain) => {
        dispatch(userActionsAsync.validateCnameAsync(domain));
    };
    const checkEmailExistAsync = (email) => {
        dispatch(userActionsAsync.checkEmailExistAsync(email));
    };
    const downloadTransactionsAsync = (id, range) => {
        dispatch(userActionsAsync.downloadTransactionsAsync(id, range));
    };

    const getReferralIdAsync = () => {
        dispatch(userActionsAsync.getReferralIdAsync());
    };
    const getReferralInfoAsync = () => {
        dispatch(userActionsAsync.getReferralInfoAsync());
    };


    return {
        ...user,
        setUserState,
        setUserCompanyState,
        updateOrderCount,
        fetchUserDetailsAsync,
        fetchCompanyAsync,
        fetchCompanySubscriptionsAsync,
        fetchBalanceAsync,
        fetchBrandProfilesAsync,
        createBrandProfileAsync,
        updateBrandProfileAsync,
        fetchTalentsBlacklistAsync,
        fetchTalentsAsync,
        fetchDataByIpAsync,
        restorePasswordAsync,
        updatePasswordAsync,
        createDesignerAsync,
        fetchProposalAsync,
        createCompanyAsync,
        checkEmailExistAsync,
        fetchUsersAsync,
        fetchAccountsAsync,
        fetchAccountAsync,
        createUserAsync,
        updateUserAsync,
        deleteUserAsync,
        addUserToAccountAsync,
        removeUserFromAccountAsync,
        validateCnameAsync,
        updateCompanyAsync,
        updateProposalCompanyAsync,
        deleteAccountAsync,
        createAccountAsync,
        updateAccountAsync,
        updateTalentAsync,
        createPublicationAsync,
        updatePublicationAsync,
        downloadTransactionsAsync,
        updateSubscriptionAsync,
        updateSubscriptionCustomAsync,
        updateDesignSubscriptionAsync,
        cancelPauseSubscriptionAsync,
        assignAccountsToSubAsync,
        changeCardAsync,
        cancelSubscriptionAsync,
        pauseSubscriptionAsync,
        createSubscriptionAsync,
        createDesignSubscriptionAsync,
        sendNpsAsync,
        getReferralIdAsync,
        getReferralInfoAsync,
        sendProspectAsync
    };
};
