// Instruments
import { types } from './types';
import update from 'immutability-helper';

const initialState = {
    keys: {
        accountId: null,
        privateKey: null,
        publicKey: null,
        sessid: null,
    },
};

export const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.LOGIN_SUCCESS:
        case types.AUTHENTICATE_SUCCESS:
            return Object.assign({}, state, {
                keys: payload,
            });

        case types.LOGIN_FAKE_USER_SUCCESS:
            return Object.assign({}, state, {
                keys: payload,
            });

        case types.SET_ACCOUNT:
            return update(state, {
                keys: {
                    accountId: {$set: payload}
                },
            });

        case types.LOGOUT_SUCCESS:
            return Object.assign({}, initialState );

        default:
            return state;
    }
};
