// Core
import { call, put } from 'redux-saga/effects';

// Instruments
import { api } from '../../../../helpers/api';
import { userActions } from '../../actions';
import { uiActions } from '../../../ui/actions';
import { getErrorMsg } from '../../../../helpers/helpers';

export function* callFetchProposalWorker ({ payload }) {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(fetch, `${api}/v1/proposal/${payload}`,{
            method: 'GET',
        });
        const { response: proposal, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(getErrorMsg(message));
        }

        yield put(userActions.setUserState('proposal',proposal));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
