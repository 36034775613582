// Instruments
import { types } from './types';

export const modalsActions = Object.freeze({
    setModal: (modal) => ({
        type:    types.SET_MODAL,
        payload: modal,
    }),
    setModalState: (prop, data) => ({
        type:    types.SET_MODAL_STATE,
        payload: {
            prop,
            data
        },
    }),
});
