// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getAuth } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { orderActions } from '../../../order/actions';

export function* callFetchCopywritersListWorker ({ payload: list }) {
    try {
        yield put(uiActions.enableFetching());
        const isAuth = yield select(getAuth);
        let accountId = '';
        if ( isAuth ) {
            const obj = yield select(getKeys);
            accountId = obj.accountId;
        }
        const response = yield call(fetch, `${api}/v1/copywriters/${list}${ isAuth ? `?account_uid=${accountId}` : '' }`, {
            method: 'GET',
        });
        const { response: invite_writers, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(orderActions.setOrderFormState({ // TODO actions.fetchCopywritersList(query[key]) 2;
            invite_writers,
            invite_writer_active_for: invite_writers.length ? 168 : 0,
        }));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
