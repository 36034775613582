export const filters = {
    types: [
        {
            value: 'all',
            title: 'All images',
        },
        {
            value: 'photo',
            title: 'Photos',
        },
        {
            value: 'vector',
            title: 'Vector graphics',
        },
        {
            value: 'illustration',
            title: 'Illustrations',
        },
    ],
    orientation: [
        {
            value: 'all',
            title: 'Any orientation',
        },
        {
            value: 'horizontal',
            title: 'Horizontal',
        },
        {
            value: 'vertical',
            title: 'Vertical',
        },
    ],
};
