// Core
import { useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

// Components
import { Brief } from "./Brief";
import { Content } from "./Content";
import { Messages } from "./Messages";
import { Overview } from "./Overview";
import { Sharing } from "./Sharing";
import { Sidebar } from "./Sidebar";

// Hooks
import { useAuth } from "../../hooks/useAuth";
import { useLists } from "../../hooks/useLists";
import { useProject } from "../../hooks/useProject";
import { useSocket } from "../../hooks/useSocket";
import { useUser } from "../../hooks/useUser";
import { useUi } from "../../hooks/useUi";

// Instruments
import cx from 'classnames';
import { subProjectChannel, unsubProjectChannel, bindNewMessageEvent, bindAwardPitchEvent, bindAddLogEvent, bindEditProjectEvent } from '../../init/pusher';
import { setAccountCookies, isClientFn, isTouchDevice } from '../../helpers/helpers';
import { isEmpty, isNil, includes } from 'ramda';

export const ProjectDetails = ({ page }) => {
    /* Hooks */
    const { keys: { accountId }, setAccount } = useAuth();
    const { unreadMessages, getUnreadMessagesAsync, getOrderListAsync } = useLists();
    const { details, revisions, setProjectState, fetchProjectDetailsAsync, fetchProjectDetailsByCodeAsync } = useProject();
    const { project_id, topic, status, job_type, revision, account_uid } = details || {};
    const { company, details: { user_role }, fetchBrandProfilesAsync, fetchTalentsBlacklistAsync } = useUser();
    const { pusher, channels: { projectChannel }} = useSocket();
    const { isAuth, isMobile, isFakeUser } = useUi();
    const { projectId, shareCode } = useParams();
    const navigate = useNavigate();

    /* Did mount */
    useEffect(() => {
        return () => {
            setProjectState('details', null);
        };
    }, []);
    /* Did update */
    useEffect(() => {
        if ( isAuth ) {
            getOrderListAsync(navigate);
            fetchProjectDetailsAsync(projectId, null, navigate);
            if ( !isClientFn(user_role) ) {
                fetchBrandProfilesAsync()
                getUnreadMessagesAsync();
            }
        } else {
            fetchProjectDetailsByCodeAsync(projectId, shareCode);
        }
    }, [projectId]);
    useEffect(() => {
        if ( !isNil(details) ) {
            if ( account_uid !== accountId && !isFakeUser ) {
                setAccountCookies(account_uid);
                setAccount(account_uid);
            }
            /* Redirect to order page if status = draft */
            if ( status === 'draft' ) {
                navigate(`/order/${project_id}`)
            }
        }
    }, [details]);
    useEffect(() => {
        if ( !isEmpty(pusher) && !isNil(details) ) {
            subProjectChannel(pusher, project_id);
        }

        return () => {
            if ( !isEmpty(pusher) && !isNil(details) ) {
                unsubProjectChannel(pusher,project_id);
            }
        };
    }, [pusher, details]);
    useEffect(() => {
        if ( !isNil(projectChannel) ) {
            bindNewMessageEvent(projectChannel);
            bindAwardPitchEvent(projectChannel);
            bindAddLogEvent(projectChannel);
            bindEditProjectEvent(projectChannel);
        }
    }, [projectChannel]);
    useEffect(() => {
        if ( !isNil(user_role) ) {
            if ( !isClientFn(user_role) ) {
                fetchBrandProfilesAsync()
                getUnreadMessagesAsync();
                fetchTalentsBlacklistAsync('copywriters');
                fetchTalentsBlacklistAsync('designers');
            }
        }
        // if (prevProps.location.key !== key) {
        //     actions.getUnreadMessagesAsync();
        // }
    }, [user_role]);

    if ( isNil(details) ) return null;

    const isWriting = status === 'writing' && page === 'content';
    const isPitching = status === 'pitching' && page === 'content';
    const isMatching = status === 'matching' && page === 'content' && (job_type !== 'design' || !revision );
    const isEditing = status === 'editing' && page === 'content' && !isNil(revisions) && isEmpty(revisions);
    const messagesArr = isNil(unreadMessages) ? [] : unreadMessages.filter(o => `${o.project_id}` === `${project_id}`);
    const count = isEmpty(messagesArr) ? null : messagesArr[0].message_count;

    /* Html */
    const contentProps = { company };
    const content = {
        'overview': <Overview { ...contentProps }/>,
        'sharing': includes(status, 'pitching/matching') ? null : <Sharing { ...contentProps }/>,
        'brief': <Brief { ...contentProps }/>,
        'messages': <Messages { ...contentProps }/>,
        'content': <Content { ...contentProps }/>
    };
    const getContent = () => {
        if ( status === 'draft' ) return null;
        if ( account_uid !== accountId && !isFakeUser ) return null;

        return <>
            <Sidebar
                count = { count }
                page = { page }
                status = { status }
                job_type = { job_type }
                revision = { revision }
                projectId = { `${project_id}` } />
            { content[page] }
        </>;
    };

    const classNames = cx( 'gac-project-details main-container', {
        'gac-mobile-view': isMobile,
        'gac-empty-content': isWriting || isPitching || isMatching || isEditing,
    });
    const classNames2 = cx( 'gac-project-wrapper', {
        'gac-content-page': page === 'content',
        'gac-touch-device': isTouchDevice(),
    });

    return <div className = { classNames }>
        <div className = { classNames2 }>
            { isMobile && <div className="gac-project-title">{ topic }</div> }
            { getContent() }
        </div>
    </div>;
};