// Core
import { useEffect } from "react";
import { CSSTransition } from 'react-transition-group';

// Components
import { ModalFeedBack } from '../modals/ModalFeedBack';
import { ModalDeactivateUser } from "../modals/ModalDeactivateUser";
import { ModalDeactivateAccount } from "../modals/ModalDeactivateAccount";
import { ModalUploadImage } from "../modals/ModalUploadImage";
import { ModalAccountsList } from "../modals/ModalAccountsList";
import { ModalChangeCard } from "../modals/ModalChangeCard";
import { ModalCancelSubscription } from "../modals/ModalCancelSubscription";
import { ModalPauseSubscription } from "../modals/ModalPauseSubscription";
import { ModalCancelPauseSubscription } from "../modals/ModalCancelPauseSubscription";
import { ModalFullImage } from "../modals/ModalFullImage";
import { ModalEditProject } from "../modals/ModalEditProject";
import { ModalCreateProject } from "../modals/ModalCreateProject";
import { ModalRateWriter } from "../modals/ModalRateWriter";
import { ModalNps } from "../modals/ModalNps";
import { ModalClientFeedback } from "../modals/ModalClientFeedback";
import { ModalCancelProject } from "../modals/ModalCancelProject";
import { ModalTalents } from "../modals/ModalTalents";
import { ModalCreateBrandProfile } from "../modals/ModalCreateBrandProfile";
import { ModalImages } from "../modals/ModalImages";
import { ModalReferral } from "../modals/ModalReferral";
import { ModalDownloadFile } from "../modals/ModalDownloadFile";
import { ModalCreateAccount } from "../modals/ModalCreateAccount";
import { ModalAiResults } from "../modals/ModalAiResults";
import { ModalRequestRevision } from "../modals/ModalRequestRevision";

// Hooks
import { useModals } from "../../hooks/useModals";
import { useUi } from "../../hooks/useUi";

// Instruments
import { isFetching } from "../../helpers/helpers";
import { mimeTypes } from "../../helpers/constants";
import { isNil } from 'ramda';

export const ModalsHolder = () => {
    /* Hooks */
    const { activeModal, setModal, setModalState } = useModals();
    const { fetching } = useUi();

    /* Actions */
    const closeModal = () => {
        if ( !isFetching(fetching) ) {
            setModal(null);
            if ( activeModal === 'requestRevision' ) {
                setModalState('isRequestRevisionModalDisabled', true);
            }
        }
    };
    const setPos = (modal) => {
        if ( isNil(modal.current) ) return;
        modal.current.style.marginTop = `-${modal.current.offsetHeight / 2}px`;
    };

    useEffect(() => {
        const onKeyPress = ({ keyCode }) => {
            if(keyCode === 27){
                closeModal();
            }
        };

        if ( !isNil(activeModal) ) {
            document.addEventListener('keydown', onKeyPress);
        }

        return () => {
            if ( !isNil(activeModal) ) {
                document.removeEventListener('keydown', onKeyPress);
            }
        };
    }, [activeModal]);

    /* Html */
    const getFeedBackModal = (classStr, title) => <ModalFeedBack classStr = { classStr } title = { title } />;

    const isModal = !isNil(activeModal);

    const props = { closeModal, setPos };
    const uploadImageProps = {
        accept: { ...mimeTypes['jpg'], ...mimeTypes['png'] },
        aspect: 1,
        crop: { unit: '%', width: 100, height: 100 },
        size: { width: 48, height: 48 },
        settings: { circularCrop: true }
    };

    const content = {
        'deactivateUser': <ModalDeactivateUser { ...props } action = 'delete_user' />,
        'deactivateClient': <ModalDeactivateUser { ...props } action = 'delete_client' />,
        'deactivateAccount': <ModalDeactivateAccount { ...props } />,
        'createProject': <ModalCreateProject { ...props } />,
        'editProject': <ModalEditProject { ...props } />,
        'fullImage': <ModalFullImage { ...props } />,
        'accountsList': <ModalAccountsList { ...props } />,
        'changeCard': <ModalChangeCard { ...props } />,
        'cancelSubscription': <ModalCancelSubscription { ...props } />,
        'pauseSubscription': <ModalPauseSubscription { ...props } />,
        'cancelPauseSubscription': <ModalCancelPauseSubscription { ...props } />,
        'uploadClientPhoto': <ModalUploadImage { ...props }  parameter = 'client_logo' { ...uploadImageProps } />,
        'uploadUserPhoto': <ModalUploadImage { ...props } parameter = 'user_photo' { ...uploadImageProps } />,
        'uploadAccountLogo': <ModalUploadImage { ...props } parameter = 'account_logo' { ...uploadImageProps } />,
        'uploadHeaderLogo': <ModalUploadImage { ...props } aspect = { 7.769 } accept = {{ ...mimeTypes['jpg'], ...mimeTypes['png'] }} parameter = { 'header_logo' } crop = {{ unit: 'px', width: 202, height: 26 }} size = {{ width: 202, height: 26 }} />,
        'uploadFavicon': <ModalUploadImage { ...props } aspect = { 1 } accept = {{ ...mimeTypes['ico'], ...mimeTypes['jpg'], ...mimeTypes['png'] }} parameter = { 'favicon' } crop = {{ unit: '%', width: 100, height: 100 }} size = {{ width: 192, height: 192 }} />,
        'messageSupport': getFeedBackModal('gac-message-support-modal', 'Message support'),
        'rateWriter': <ModalRateWriter { ...props } />,
        'npsModal': <ModalNps { ...props } />,
        'clientFeedback': <ModalClientFeedback { ...props } />,
        'extendProject': getFeedBackModal('gac-message-support-modal', 'To extend the approval deadline more than once, please message support the extension date.'),
        'cancelDesignProject': <ModalCancelProject { ...props } />,
        'modalTalents': <ModalTalents { ...props } />,
        'createBrandProfile': <ModalCreateBrandProfile { ...props } />,
        'images': <ModalImages { ...props } />,
        'referral': <ModalReferral { ...props } />,
        'downloadFile': <ModalDownloadFile { ...props } />,
        'createAccount': <ModalCreateAccount { ...props } />,
        'aiResults': <ModalAiResults { ...props } />,
        'requestRevision': <ModalRequestRevision { ...props } />,
    };

    return <>
        <CSSTransition classNames='gac-feedback-modal-an' in = { activeModal === 'improveThisPage' } timeout = { 400 } exit = { false } unmountOnExit>
            { getFeedBackModal('gac-feedback-modal', 'How can we improve this page?') }
        </CSSTransition>
        <CSSTransition classNames = 'gac-opacity-300' in = { isModal } timeout = { 300 } exit = { false } unmountOnExit>
            <>
                <div className = 'gac-overlay' onClick = { closeModal }/>
                { content[activeModal] }
            </>
        </CSSTransition>
    </> ;
};