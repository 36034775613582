// Core
import { call, put } from 'redux-saga/effects';

// Instruments
import { userActions } from '../../actions';

export function* callFetchDataByIpWorker () {
    try {
        const response = yield call(fetch, `https://api.ipgeolocation.io/ipgeo?apiKey=8a7dca4ec28b403c95ef368e242782b9&fields=country_code2`, {
            method: 'GET',
        });
        const data = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error('You\'ve just got an error');
        }

        yield put(userActions.setUserState('ipDetails', data));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
    }
}
