// Core
import { call, put, select, delay } from 'redux-saga/effects';
import update from 'immutability-helper';

// Instruments
import { api, authStr, getKeys, getSubs, getStaticData, getCompanySubs } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from '../../actions';
import { userActionsAsync } from "../asyncActions";
import { paymentsActions } from "../../../payments/actions";
import { listsActions } from "../../../lists/actions";
import { includes, isEmpty, isNil } from "ramda";
import moment from 'moment/moment';

export function* callCreateSubscriptionWorker ({ payload: { subscription, navigate, /*action*/ } }) {
    try {
        yield put(uiActions.enableFetching());
        const { type, id } = subscription;
        const isCustom = id === 'custom';
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/${type}/subscribe${isCustom ? '-custom' : ''}`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                ...subscription
            }),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( isCustom ) {
            if ( type === 'webbuilding' ) {} else {
                const newSub = {
                    id: data.subscription_id,
                    amount: data.amount,
                    frequency: data.frequency,
                    level: data.level,
                    name: data.plan_name,
                    word_count: data.words,
                    billing_interval_code: subscription.billing_interval_code,
                    currency: subscription.currency,
                    discount: 10
                };

                const staticData = yield select(getStaticData);
                const subs = yield select(getSubs);
                const newStaticData = update(staticData, {
                    subscriptions: {
                        [type]: { $set: [...subs[type],newSub] }
                    },
                });
                yield put(listsActions.setListsState('staticData',newStaticData));
            }
        }
        yield put(userActionsAsync.fetchBalanceAsync(accountId));

        let companySubs = yield select(getCompanySubs);
        companySubs = isNil(companySubs) || isEmpty(companySubs) ? [] : companySubs;
        yield put(userActions.setUserCompanyState('subscriptions', [...companySubs, data]));
        yield put(uiActions.setUiState('notification', {
            msg: 'Processing payment',
            type: 'processing',
            fn: null,
        }));
        if ( !moment(subscription.start_date).isSame(moment(), 'day') ) {
            yield delay(3000);
            yield put(uiActions.setUiState('notification', {
                msg:  null,
                type: null,
                fn:   null
            }));
            navigate('/subscriptions');
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
        if ( subscription.payment_processor === 'braintree' ) {
            yield put(uiActions.setUiState('isWritingSubBraintreePaymentFailed', true));
            yield put(paymentsActions.setPaymentsState('subBraintree', {}));
        }
    } finally {
        yield put(uiActions.disableFetching());
        yield put(uiActions.setUiState('isBlocking', false));
    }
}
