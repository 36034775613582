// Core
import { useState, useEffect, useRef } from "react";

// Components
import Checkbox from '../../icons/Checkbox';
import CheckedCheckbox from '../../icons/CheckedCheckbox';

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useProject } from "../../../hooks/useProject";
import { useUser } from "../../../hooks/useUser";
import { useUi } from "../../../hooks/useUi";

// Instruments
import { getScoreHtml, isFetching } from '../../../helpers/helpers';
import { isNil } from "ramda";

export const ModalRateWriter = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);

    /* State */
    const [score, setScore] = useState(null);
    const [feedback, setFeedback] = useState('');
    const [isShareFeedback, setIsShareFeedback] = useState(true);
    const [isRemoveWriter, setIsRemoveWriter] = useState(false);

    /* Hooks */
    const { keys: { accountId }} = useAuth();
    const { details, approveProjectAsync } = useProject();
    const { writer_name, job_type, writer_id, project_id } = details;
    const { details: { accounts = [] }, updateTalentAsync } = useUser();
    const { fetching } = useUi();

    /* Actions */
    const onScoreChange = ({ currentTarget: { dataset: { score }}}) => {
        setScore(score);
    };
    const onFeedbackChange = ({ currentTarget: { value }}) => {
        setFeedback(value);
    };
    const onCheckboxChange = ({ currentTarget: { dataset: { prop }}}) => {
        if ( prop === 'isRemoveWriter' ) {
            setIsRemoveWriter(state => !state);
        } else if ( prop === 'isShareFeedback' ) {
            setIsShareFeedback(state => !state);
        }
    };
    const onSubmit = () => {
        if ( !isFetching(fetching) && !isNil(score) ) {
            if (isRemoveWriter) {
                updateTalentAsync({
                    type: job_type === 'design' ? 'designers' : 'copywriters',
                    account_uid: accountId,
                    writer_uid: writer_id,
                }, 'add_to_blacklist');
            }
            approveProjectAsync({
                project_id,
                project_score: score,
                project_feedback: feedback,
            });
        }
    };

    /* Did mount */
    useEffect(() => {
        const onResize = () => {
            setPos( modalRef );
        };

        setPos( modalRef );
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);
    useEffect(() => {
        if ( !isNil(score) ) {
            setPos( modalRef );
        }
    }, [score]);

    /* Html */
    const scoreData = getScoreHtml(score, onScoreChange);
    let accountName = '';
    if ( accounts.length ) {
        accountName = accounts.filter(o => o.account_id === accountId)[0].account_name;
    }

    return(
        <div ref = { modalRef } className = 'gac-modal gac-rate-writer-modal'>
            <span className = 'gac-close-modal' onClick = { closeModal }/>
            <h1>{`Rate ${writer_name}.`}</h1>
            <div className = 'gac-score-html'>
                { scoreData }
            </div>
            <div className = 'gac-score-desc'>
                <span>Poor quality</span>
                <span>Excellent quality</span>
            </div>
            <div className = 'gac-rate-desc'>
                <label htmlFor="">What is the reason for your score?</label>
                <textarea value = { feedback } onChange = { onFeedbackChange }/>
            </div>
            { score !== null && score < 7 && <label className = 'gac-remove-writer' htmlFor="remove-writer">
                <input id = 'remove-writer' data-prop = 'isRemoveWriter' checked = { isRemoveWriter } onChange = { onCheckboxChange } type="checkbox"/>
                <i>{ isRemoveWriter ? <CheckedCheckbox/> : <Checkbox/> }</i>
                <span>{`Blacklist ${writer_name} from the ${accountName}`}</span>
            </label> }
            <label className = 'gac-share-feedback' htmlFor="share-feedback">
                <input id = 'share-feedback' data-prop = 'isShareFeedback' checked = { isShareFeedback } onChange = { onCheckboxChange } type="checkbox"/>
                <i>{ isShareFeedback ? <CheckedCheckbox/> : <Checkbox/> }</i>
                <span>{`Share feedback with ${writer_name}.`}</span>
            </label>
            <div className="gac-rate-writer-btns">
                <div onClick = { onSubmit } className = { `gac-btn gac-btn-m ${ isNil(score) ? 'gac-btn-disable' : '' }` }>Confirm</div>
            </div>
        </div>
    );
};