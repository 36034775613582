// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getProjectDetails, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { projectActions } from "../../actions";
import { modalsActions } from "../../../modals/actions";

export function* callRequestRevisionWorker ({ payload: { project_id, job_type }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${job_type}/${project_id}/request_revision?account_uid=${accountId}`, {
            method: 'PUT',
            headers: {
                Authorization: `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const project = yield select(getProjectDetails);
        yield put(projectActions.setProjectState('details', { ...project, status: job_type === 'design' ? 'matching' : 'revision', revision: job_type === 'design' ? true : project.revision }));
        yield put(modalsActions.setModal(null));
        yield put(uiActions.setUiState('notification', {
            msg: data,
            type: 'success',
        }));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
