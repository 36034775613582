// Core
import { useState } from "react";

// Components
import { useDropzone } from 'react-dropzone';
import CommentAttachFile from "../../components/icons/CommentAttachFile";

// Instruments
import { bytesToSize } from "../../helpers/helpers";
import { api } from "../../helpers/api";
import {includes, isEmpty, isNil} from "ramda";

export const useDropzoneEl = (attachments, files, onUpdateFiles, onRemoveFile, maxSize, invalidFields, removeField, job_type, job_group, filesType, attachmentsType, isDropzone) => {
    /* State */
    const [progress, setProgress] = useState([]);
    const [errorMsg, setErrorMsg] = useState(null);

    // const isMotion = job_type === 'motion';

    /* Actions */
    // const filesType = isMotion ? 'logo_file' : 'files';
    const onFileUploadStart = () => {
        setErrorMsg(null);
    };
    const onFileUploadProgress = (e) => {
        const percent = (e.loaded / e.total) * 100;
        const barEl = document.getElementById(`${e.target.id}`);
        const percentageEl = document.getElementById(`percentage-${e.target.id}`);
        if ( barEl ) { barEl.style.width = `${Math.round(percent)}%`; }
        if ( percentageEl ) { percentageEl.innerText = `${Math.round(percent)}%`; }
    };
    const onFileUploadComplete = (e) => {
        let data = JSON.parse(e.target.response).response;
        data = data.map(o => ({ file_id: o.file_id, filename: o.filename, size: o.size, url: o.url }));
        const id = e.target.upload.id;

        setProgress(state => {
            const arr = state.filter(item => item !== id);
            if ( isEmpty(arr) ) {
                removeField('attachment');
            }
            return arr;
        });


        onUpdateFiles(filesType, data, null);
        onUpdateFiles(attachmentsType, data, id);
    };
    const onFileUploadError = () => {
        setErrorMsg('Upload Failed');
    };
    const onFileUploadAbort = () => {
        setErrorMsg('Upload Aborted');
    };
    const onDropRejected = (rejected) => {
        if ( !isEmpty(rejected) ) {
            const { code, message } = rejected[0].errors[0];
            const error = code === 'file-too-large' ? 'File is larger than 50 Mb' : message;
            setErrorMsg(error);
        }
    };
    const uploadFile = (file, id) => {
        const formdata = new FormData();
        formdata.append('attachment[]', file, file.name);
        if ( includes(job_type, 'design/motion') ) {
            formdata.append('job_type', 'design');
        }
        const xhr = new XMLHttpRequest();
        xhr.upload.id = id;
        xhr.upload.addEventListener("loadstart", onFileUploadStart, false);
        xhr.upload.addEventListener("progress", onFileUploadProgress, false);
        xhr.addEventListener("load", onFileUploadComplete, false);
        xhr.addEventListener("error", onFileUploadError, false);
        xhr.addEventListener("abort", onFileUploadAbort, false);
        xhr.open('POST', `${api}/v1/upload_file`, true);
        xhr.send(formdata);
    };
    const onDrop = (acceptedFiles) => {
        let addedFiles = [];
        let data = acceptedFiles;

        data.forEach((file, i) => {
            if ( attachments.every(o => o.filename !== file.name) ) {
                const id = `id-${Math.random().toString(36).substr(2, 7)}`;
                data[i].id = id;
                uploadFile(file, id);
            } else {
                data = data.filter(o => o.name !== file.name);
                addedFiles.push(file.name);
            }
        });

        if ( addedFiles.length ) {
            setErrorMsg(`File${ addedFiles.length > 1 ? 's' : '' } ${addedFiles.join(', ')} ${ addedFiles.length > 1 ? 'have' : 'has' } already been added`);
        }
        setProgress(data.map(o => o.id));
        onUpdateFiles(attachmentsType, data, null);
    };
    const onFormDrop = (e) => {
        e.preventDefault();

        let file;
        let acceptedFiles = [];
        let addedFiles = [];

        setErrorMsg(null);

        if (e.dataTransfer.items) {
            for (let i = 0; i < e.dataTransfer.items.length; i++) {
                if (e.dataTransfer.items[i].kind === 'file') {
                    file = e.dataTransfer.items[i].getAsFile();
                    if (file.size > maxSize) {
                        setErrorMsg('File is larger than 50 Mb');
                    } else {
                        if ( attachments.every(o => o.filename !== file.name) ) { // eslint-disable-line no-loop-func
                            file.id = `id-${Math.random().toString(36).substr(2, 7)}`;
                            acceptedFiles.push(file);
                        } else {
                            addedFiles.push(file.name);
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                file = e.dataTransfer.files[i];

                if (file.size > maxSize) {
                    setErrorMsg('File is larger than 50 Mb');
                } else {
                    if ( attachments.every(o => o.filename !== file.name) ) { // eslint-disable-line no-loop-func
                        file.id = `id-${Math.random().toString(36).substr(2, 7)}`;
                        acceptedFiles.push(file);
                    } else {
                        addedFiles.push(file.name);
                    }
                }
            }
        }

        if ( addedFiles.length ) {
            setErrorMsg(`File${ addedFiles.length > 1 ? 's' : '' } ${addedFiles.join(', ')} ${ addedFiles.length > 1 ? 'have' : 'has' } already been added`);
        }
        acceptedFiles.forEach((file) => {
            uploadFile(file, file.id);
        });
        setProgress(acceptedFiles.map(o => o.id));
        onUpdateFiles(attachmentsType, acceptedFiles, null);
    };
    const onFormDragStart = (e) => {
        e.dataTransfer.dropEffect = "copy";
    };
    const onRemoveFileHandler = ({ currentTarget: { dataset: { name }}}) => {
        const filesData = files.filter(o => `${o.filename}` !== name);
        const attachmentsData = attachments.filter(o => `${o.filename}` !== name);

        onRemoveFile(filesType, filesData);
        onRemoveFile(attachmentsType, attachmentsData);
    };

    /* Html */
    const { getRootProps, getInputProps } = useDropzone({
        maxSize,
        multiple: true,
        noDrag: true,
        onDrop,
        onDropRejected,
        preventDropOnDocument: true,
    });
    const getDropzoneError = (isEmptyError, msg) => {
        if ( isNil(errorMsg) && isEmptyError ) return null;
        if ( isNil(errorMsg) ) return <p>{ isNil(msg) ? 'Attach files' : msg }</p>;

        return <p className = 'gac-error'>{ errorMsg }</p>
    };
    const getBriefExample = () => {
        if ( job_group !== 'writing' ) return null;

        return <a href="https://ii.epiic.com/hubfs/resources/brief-example-2024.pdf" download='brief-example' target="_blank" className="gac-brief-example">
            <span/>
            Brief example
        </a>;
    };
    const getFilesHelper = () => {
        if ( isEmpty(attachments) || isNil(attachments) ) return null;

        const data = attachments.map(({ file_id, filename, size, url }) => {
            return (
                <div key = { file_id } className = 'gac-files-item'>
                    <i className = { `gac-files-item-type ${ filename.split('.').slice(-1)[0] }` }/>
                    { isNil(url) ? <span title = { filename } className = 'gac-files-item-name'>{ filename }</span> : <a href = { url } target='_blank' rel = 'noopener noreferrer' title = { filename } className = 'gac-files-item-name'>{ filename }</a>}
                    <div className = 'gac-align-right'>
                        { progress.includes(file_id)
                            ? <div className="gac-files-item-progress-wrap">
                                <span className =  'gac-files-item-progress-bar'><span id = { file_id }/></span>
                                <span id = { `percentage-${file_id}` } className="gac-files-item-percentage"/>
                            </div>
                            : <span className = 'gac-files-item-size'>{ bytesToSize(size) }</span> }
                        <i data-name = { filename } onClick = { onRemoveFileHandler } className = 'gac-files-item-remove'/>
                    </div>
                </div>
            );
        })

        return <div className = 'gac-attachment-files'>
            { data }
        </div> ;
    };
    const getDropzoneHtml = () => {
        return <div id = 'attachment' style={{ paddingBottom: isDropzone ? 0 : 8 }} className = { `gac-attachment ${ invalidFields.includes('attachment') ? 'gac-invalid' : '' }` }>
            { getFilesHelper() }
            { isDropzone && <div className="gac-dropzone-wrap">
                <div { ...getRootProps( { className: 'gac-dropzone'} ) }>
                    <input { ...getInputProps() } />
                    <span><i/></span>
                    { getDropzoneError() }
                </div>
                { getBriefExample() }
            </div> }
        </div> ;
    };
    const getFilesHtml = () => {
        return attachments.length > 0 && <div className = 'gac-project-files'>
            { attachments.map(({ file_id, filename, size }) => {
                return <div key = { file_id } className = 'gac-project-file'>
                    <i className = { `gac-project-file-type ${ filename.split('.').slice(-1)[0] }` }/>
                    <span title = { filename } className = 'gac-project-file-name'>{ isNil(filename) || isEmpty(filename) ? '' : filename.substring(0, 40) }{ filename.length > 30 ? '...' : '' }</span>
                    <div className = 'gac-align-right'>
                        { progress.includes(file_id)
                            ? <div className="gac-files-item-progress-wrap">
                                <span className =  'gac-files-item-progress-bar'><span id = { file_id }/></span>
                                <span id = { `percentage-${file_id}` } className="gac-files-item-percentage"/>
                            </div>
                            : <span className = 'gac-project-file-size'>{ bytesToSize(size) }</span> }
                        <i data-name = { filename } onClick = { onRemoveFileHandler } className = 'gac-files-item-remove'/>
                    </div>
                </div> ;
            })}
        </div>
    };
    const getDropzoneMessageForm = () => {
        return <div { ...getRootProps( { className: 'gac-message-dropzone'} ) }>
            <input { ...getInputProps() } />
            <div className = 'gac-message-upload-file'/>
            { getDropzoneError() }
        </div>
    };
    const getDropzoneComments = () => {
        return <div { ...getRootProps( { className: 'gac-comment-dropzone'} ) }>
            <input { ...getInputProps() } />
            <CommentAttachFile/>
            { getDropzoneError(true) }
        </div>
    };
    const getDropzoneMotion = () => {
        return <div id = 'attachment' className = 'gac-attachment gac-attachment-motion'>
            { getFilesHelper() }
            <div { ...getRootProps( { className: 'gac-dropzone'} ) }>
                <input { ...getInputProps() } />
                <span><i/></span>
                { getDropzoneError(false,'Attach file') }
            </div>
            { invalidFields.includes('attachment') && <div className = 'gac-error' style={{ paddingTop: 8, fontSize: 14 }}>Please attach file (.eps or .ai file preferred)</div> }
        </div> ;
    };

    return {
        progress,
        onFormDrop,
        onFormDragStart,
        getDropzoneHtml,
        getFilesHtml,
        getDropzoneMessageForm,
        getDropzoneComments,
        getDropzoneMotion,
    };
};