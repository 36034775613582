// Core
import Cookies from 'universal-cookie';
import { domain } from '../../../../helpers/api';

// Instruments
import { isLocal, isWhiteLabel } from '../../../../helpers/helpers';

/* eslint-disable require-yield */
export function* callClearCookiesWorker () {
    let options = {
        path: '/',
    };
    if (!isLocal) {
        options = {
            ...options,
            domain: isWhiteLabel() ? window.location.hostname : `.${domain}`
        };
    }

    const cookies = new Cookies();
    cookies.remove('_gac_prk', options);
    cookies.remove('_gac_pbk', options);
    cookies.remove('_gac_acc', options);
    cookies.remove('GACSESSID', options);
}
