// Core
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { formsActions } from "../bus/forms/actions";

export const useForms = () => {
    const selector = (state) => state.forms;
    const forms = useSelector(selector);
    const dispatch = useDispatch();

    const setFormState = (prop, value) => {
        dispatch(formsActions.setFormState(prop, value));
    };
    const updateFormState = (prop, value) => {
        dispatch(formsActions.updateFormState(prop, value));
    };
    const updateFormFilesState = (form, prop, value, id) => {
        dispatch(formsActions.updateFormFilesState(form, prop, value, id));
    };
    const clearOrderForms = () => {
        dispatch(formsActions.clearOrderForms());
    };

    return {
        ...forms,
        setFormState,
        updateFormState,
        updateFormFilesState,
        clearOrderForms
    };
};
