// Core
import { useLayoutEffect } from "react";

// Instruments
import { initDefaultRedactor } from "../../../helpers/helpers";

export const WebAudit = ({ fields }) => {
    /* Hooks */
    const {
        getWebAuditLevelField,
        getWebsiteField,
        getGoogleServicesField,
        getLinkToResourcesField,
        getTextareaField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;

    useLayoutEffect(() => {
        initDefaultRedactor('#products-textarea', 'products', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#competitors-textarea', 'competitors', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#target_audience-textarea', 'target_audience', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#goals-textarea', 'goals', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#notes-textarea', 'notes', '116px', null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#products-textarea', 'destroy');
            window.$R('#competitors-textarea', 'destroy');
            window.$R('#target_audience-textarea', 'destroy');
            window.$R('#goals-textarea', 'destroy');
            window.$R('#notes-textarea', 'destroy');
        };
    }, []);


    return <>
        { getWebAuditLevelField() }
        { getWebsiteField() }
        { getGoogleServicesField() }
        { getLinkToResourcesField() }
        { getTextareaField('products', 'List up to 5 keywords (products or services your company sells)') }
        { getTextareaField('competitors', 'List your 3 main competitors') }
        { getTextareaField('target_audience', 'Describe the target audience of your website') }
        { getTextareaField('goals', 'Explain what goals you want to achieve with your website') }
        { getTextareaField('notes', 'Additional information') }
    </>;
};
