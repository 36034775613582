// Core
import { call, put, select  } from 'redux-saga/effects';

// Instruments
import { api, authStr, getKeys, getUserId, getUserDetails, getUsers } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from '../../actions';
import { listsActions } from "../../../lists/actions";
import { notifications } from '../../../../helpers/notifications';
import { isNil } from "ramda";

export function* callUpdateUserWorker ({ payload: { user, isCurrentUser, action } }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const user_id = yield select(getUserId);
        const userDetails = yield select(getUserDetails);
        const response = yield call(fetch, `${api}/${publicKey}/v1/users/${user.user_id}/update`, {
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(user),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        let users = yield select(getUsers);
        users = isNil(users) ? [] : users.filter(o => o.user_id !== data.user_id);
        yield put(listsActions.setListsState('users', [ ...users, data ]));

        if ( action === 'update_user' ) {
            if ( isCurrentUser ) {
                yield put(userActions.setUserState('currentUser', data));
            }
            if ( user_id === data.user_id ) {
                yield put(userActions.setUserState('details', {
                    ...userDetails,
                    ...data,
                    avatar: data.avatar_url ? data.avatar_url : ''
                }));
            }
        } else if ( action === 'update_client' ) {

        }
        yield put(uiActions.setUiState('notification', notifications.changesSaved));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
