// Core
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { projectActions } from "../bus/project/actions";
import { projectActionsAsync } from "../bus/project/saga/asyncActions";

export const useProject = () => {
    const selector = (state) => state.project;
    const project = useSelector(selector);
    const dispatch = useDispatch();

    /* Actions */
    const setProjectState = (prop, value) => {
        dispatch(projectActions.setProjectState(prop, value));
    };
    const deleteCommentSuccess = (id) => {
        dispatch(projectActions.deleteCommentSuccess(id));
    };
    const updateProjectRevision = (html_content) => {
        dispatch(projectActions.updateProjectRevision(html_content));
    };
    const updateIdeaRevisionField = (prop, data, idea_number, revision_id) => {
        dispatch(projectActions.updateIdeaRevisionField(prop, data, idea_number, revision_id));
    };
    const deleteIdeaFile = (file_id, idea_number, revision_id) => {
        dispatch(projectActions.deleteIdeaFile(file_id, idea_number, revision_id));
    };

    /* Async */
    const fetchProjectDetailsAsync = (projectId, action, navigate) => {
        dispatch(projectActionsAsync.fetchProjectDetailsAsync(projectId, action, navigate));
    };
    const fetchProjectDetailsByCodeAsync = (projectId, shareCode, action) => {
        dispatch(projectActionsAsync.fetchProjectDetailsByCodeAsync(projectId, shareCode, action));
    };
    const fetchProjectTagsAsync = () => {
        dispatch(projectActionsAsync.fetchProjectTagsAsync());
    };
    const changeProjectQueueAsync = (projectId, queue) => {
        dispatch(projectActionsAsync.changeProjectQueueAsync(projectId, queue));
    };
    const deleteProjectAsync = (projectId, action, idea_number, navigate) => {
        dispatch(projectActionsAsync.deleteProjectAsync(projectId, action, idea_number, navigate));
    };
    const approveProjectAsync = (data) => {
        dispatch(projectActionsAsync.approveProjectAsync(data));
    };
    const awardProjectAsync = (pitchId) => {
        dispatch(projectActionsAsync.awardProjectAsync(pitchId));
    };
    const extendProjectDeadlineAsync = (project_id) => {
        dispatch(projectActionsAsync.extendProjectDeadlineAsync(project_id));
    };
    const updateProjectAsync = (project, action, idea_number, navigate) => {
        dispatch(projectActionsAsync.updateProjectAsync(project, action, idea_number, navigate));
    };
    const createTagAsync = (tag) => {
        dispatch(projectActionsAsync.createTagAsync(tag));
    };
    const cancelProjectAsync = (projectId) => {
        dispatch(projectActionsAsync.cancelProjectAsync(projectId));
    };
    const fetchProjectPitchesAsync = (data) => {
        dispatch(projectActionsAsync.fetchProjectPitchesAsync(data));
    };
    const moveProjectAsync = (project_id, status, account_uid, account_name, navigate) => {
        dispatch(projectActionsAsync.moveProjectAsync(project_id, status, account_uid, account_name, navigate));
    };
    const fetchProjectLogsAsync = (data) => {
        dispatch(projectActionsAsync.fetchProjectLogsAsync(data));
    };
    const checkTopicAsync = (topic) => {
        dispatch(projectActionsAsync.checkTopicAsync(topic));
    };
    const fetchProjectMessagesAsync = (data) => {
        dispatch(projectActionsAsync.fetchProjectMessagesAsync(data));
    };
    const createMessageAsync = (message) => {
        dispatch(projectActionsAsync.createMessageAsync(message));
    };
    const fetchProjectRevisionsAsync = (data) => {
        dispatch(projectActionsAsync.fetchProjectRevisionsAsync(data));
    };
    const fetchProjectCommentsAsync = (data) => {
        dispatch(projectActionsAsync.fetchProjectCommentsAsync(data));
    };
    const deleteCommentAsync = (comment_id, project_id, job_type, idea_number, field) => {
        dispatch(projectActionsAsync.deleteCommentAsync(comment_id, project_id, job_type, idea_number, field));
    };
    const createCommentAsync = (project_id, job_type, commentHtml, commentText, field, idea_number, revision_id, fileId, horizontal, vertical, preview_width, attachment) => {
        dispatch(projectActionsAsync.createCommentAsync(project_id, job_type, commentHtml, commentText, field, idea_number, revision_id, fileId, horizontal, vertical, preview_width, attachment));
    };
    const reviewProjectAsync = (data) => {
        dispatch(projectActionsAsync.reviewProjectAsync(data));
    };
    const requestRevisionAsync = (data) => {
        dispatch(projectActionsAsync.requestRevisionAsync(data));
    };
    const updateCommentAsync = (comment, comment_id, project_id, job_type, idea_number, attachment) => {
        dispatch(projectActionsAsync.updateCommentAsync(comment, comment_id, project_id, job_type, idea_number, attachment));
    };
    const downloadProjectContentAsync = (project_id, topic, type) => {
        dispatch(projectActionsAsync.downloadProjectContentAsync(project_id, topic, type));
    };
    const updateRevisionAsync = (data) => {
        dispatch(projectActionsAsync.updateRevisionAsync(data));
    };
    const setEditingProjectAsync = (project_id, editing) => {
        dispatch(projectActionsAsync.setEditingProjectAsync(project_id, editing));
    };
    const fetchRevisionDiffAsync = (project_id, job_type, revision) => {
        dispatch(projectActionsAsync.fetchRevisionDiffAsync(project_id, job_type, revision));
    };
    const createProjectAsync = (project, action, navigate) => {
        dispatch(projectActionsAsync.createProjectAsync(project, action, navigate));
    };
    const confirmProjectAsync = (project, action, navigate) => {
        dispatch(projectActionsAsync.confirmProjectAsync(project, action, navigate));
    };
    const setIdeaStatusAsync = (project_id, idea_id, value) => {
        dispatch(projectActionsAsync.setIdeaStatusAsync(project_id, idea_id, value));
    };
    const updateIdeasRevisionAsync = (data) => {
        dispatch(projectActionsAsync.updateIdeasRevisionAsync(data));
    };
    const getProjectsDesignCountAsync = () => {
        dispatch(projectActionsAsync.getProjectsDesignCountAsync());
    };
    const getOriginalityRevisionResultsAsync = (revision_id) => {
        dispatch(projectActionsAsync.getOriginalityRevisionResultsAsync(revision_id));
    };

    //     fetchProjectRevisionAsync: (data) => ({
    //     type: asyncTypes.FETCH_PROJECT_REVISION_ASYNC,
    //     payload: data,
    // }),


    return {
        ...project,
        setProjectState,
        deleteCommentSuccess,
        updateProjectRevision,
        updateIdeaRevisionField,
        deleteIdeaFile,
        fetchProjectDetailsAsync,
        fetchProjectDetailsByCodeAsync,
        fetchProjectTagsAsync,
        fetchProjectMessagesAsync,
        fetchRevisionDiffAsync,
        changeProjectQueueAsync,
        deleteProjectAsync,
        approveProjectAsync,
        awardProjectAsync,
        extendProjectDeadlineAsync,
        updateProjectAsync,
        createTagAsync,
        cancelProjectAsync,
        fetchProjectPitchesAsync,
        moveProjectAsync,
        fetchProjectLogsAsync,
        checkTopicAsync,
        createMessageAsync,
        fetchProjectRevisionsAsync,
        fetchProjectCommentsAsync,
        createCommentAsync,
        updateCommentAsync,
        deleteCommentAsync,
        reviewProjectAsync,
        requestRevisionAsync,
        downloadProjectContentAsync,
        updateRevisionAsync,
        setEditingProjectAsync,
        createProjectAsync,
        confirmProjectAsync,
        setIdeaStatusAsync,
        updateIdeasRevisionAsync,
        getProjectsDesignCountAsync,
        getOriginalityRevisionResultsAsync
    };
};
