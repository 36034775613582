// Components
import { BloggingOneOff } from "./BloggingOneOff";
import { BloggingSubscription } from "./BloggingSubscription";

// Hooks
import { useOrder } from "../../../hooks/useOrder";

export const Blogging = ({ page, fields }) => {
    /* Hooks */
    const { job_pay_type } = useOrder();

    /* Html */
    const getContent = () => {
        const config = {
            'one-off': <BloggingOneOff fields = { fields } />,
            'subscription': <BloggingSubscription fields = { fields } page = { page } />
        }

        return config[job_pay_type];
    };

    return <>
        { getContent() }
    </>;
};
