//import { getUrl } from './helpers';
//const url = getUrl();

export const routes = {
    order: [
        {
            isPath: true,
            isPrivate: false,
            path:   '/order',
            altPath:   '/order/new',
            text:   'Writing',
        },
        {
            isPath: true,
            isPrivate: true,
            path:   '/images',
            text:   'Free images',
        },
    ],
    plan: [
        {
            isPath: true,
            isPrivate: true,
            path:   '/calendar',
            text:   'Editorial calendar',
        },
    ],
    manage: [
        {
            isPath: true,
            isPrivate: true,
            path:   '/draft-projects',
            text:   'Draft projects',
        },
        {
            isPath: true,
            isPrivate: true,
            path:   '/current-projects',
            text:   'Current projects',
        },
        {
            isPath: true,
            isPrivate: true,
            path:   '/completed-projects',
            text:   'Completed projects',
        },
    ]
};