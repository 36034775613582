// Core
import { call } from 'redux-saga/effects';

// Instruments
import { api } from '../../../../helpers/api';

export function* callSendProspectWorker({ payload: { email, phone } }) {
    try {
        const formData = new FormData();
        formData.append('email', email);

        if (phone && phone !== 'undefined') {
            formData.append('phone', phone);
        }

        const response = yield call(fetch, `${api}/v1/prospect`, {
            method: 'POST',
            body: formData,
        });
        const { error: { message } } = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
    }
}
