// Instrument
import { types } from './types';
import { types as authTypes } from '../auth/types';
import update from 'immutability-helper';

const initialState = {
    details:       null,
    messages:      null,
    revisions:     null,
    pitches:       null,
    logs:          null,
    comments:      null,
    tags:          null,
    client_logs:   null,
    revision_diff: null,
    ai_results:    null,
};

export const projectReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_PROJECT_STATE:
            return update(state, {
                [payload.prop]: {$set: payload.value},
            });

        case types.CREATE_MESSAGE_SUCCESS:
            const isMessage = state.messages.some((o) => `${o.message_id}` === `${payload.message_id}`);
            return isMessage ? state : update(state, {
                messages: {$push: [ payload ]},
            });

        case types.APPROVE_PITCH_SUCCESS:
            const pitcheIndex = state.pitches.findIndex(o => `${o.pitch_id}` === `${payload}`);

            return update(state, { pitches: { [pitcheIndex]: { pitch_status: { $set: 'ACCEPTED' }}}});

        case types.CREATE_COMMENT_SUCCESS:
            const isComment = state.comments.some((o) => `${o.id}` === `${payload.id}`);
            return isComment ? state : update(state, {
                comments: {$push: [ payload ]},
            });

        case types.UPDATE_COMMENT_SUCCESS:
            const commentIndex = state.comments.findIndex(o => `${o.id}` === `${payload.id}`);

            return update(state, {
                comments: {
                    [commentIndex]: {
                        comment: {$set: payload.comment},
                        attachments: {$set: payload.attachments},
                    }
                },
            });

        case types.DELETE_COMMENT_SUCCESS:
            const updatedComments = state.comments.filter(o => `${o.id}` !== `${payload}`);

            return update(state, {
                comments: {$set: updatedComments},
            });


        /* OLD */
        case types.UPDATE_PROJECT_REVISION:
            const arr = state.revisions.map(o => o.revision_number);
            let revisionIndex = arr.indexOf(Math.max(...arr));

            return update(state, {
                revisions: {
                    [revisionIndex]: {
                        html_content: {$set: payload}
                    }
                }
            });

        case types.FETCH_PROJECT_REVISION_SUCCESS:
            const revisionsIndex = state.revisions.findIndex(o => `${o.revision_number}` === `${payload.revision_number}`);

            return update(state, {
                revisions: {
                    [revisionsIndex]: {$set: payload}
                },
            });

        case types.UPDATE_PROJECT_DETAILS:
            return update(state, {
                details: {$set: { ...state.details, ...payload.data }},
            });

        case types.UPDATE_IDEA_FIELD:
            const ideaIndex = state.revisions.findIndex(o => `${o.idea_number}` === `${payload.idea_number}`);

            return update(state, {
                revisions: {
                    [ideaIndex]: {
                        [payload.prop]: {$set: payload.data}
                    }
                }
            });

        case types.UPDATE_IDEA_REVISION_FIELD:
            const ideaIndex2 = state.revisions.findIndex(o => `${o.idea_number}` === `${payload.idea_number}`);
            const revisionIndex2 = state.revisions[ideaIndex2].revisions.findIndex(o => `${o.revision_id}` === `${payload.revision_id}`);

            return update(state, {
                revisions: {
                    [ideaIndex2]: {
                        revisions: {
                            [revisionIndex2]: {
                                [payload.prop]: {$set: payload.data}
                            }
                        },
                    }
                }
            });

        case types.UPDATE_IDEA_REVISION:
            const ideaIndex4 = state.revisions.findIndex(o => `${o.idea_number}` === `${payload.idea_number}`);
            const revisionIndex4 = state.revisions[ideaIndex4].revisions.findIndex(o => `${o.revision_id}` === `${payload.revision_id}`);

            return update(state, {
                revisions: {
                    [ideaIndex4]: {
                        revisions: {
                            [revisionIndex4]: {$set: payload.data}
                        },
                    }
                }
            });

        case types.DELETE_IDEA_FILE:
            const ideaIndex3 = state.revisions.findIndex(o => `${o.idea_number}` === `${payload.idea_number}`);
            const revisionIndex3 = state.revisions[ideaIndex3].revisions.findIndex(o => `${o.revision_id}` === `${payload.revision_id}`);
            const files = state.revisions[ideaIndex3].revisions[revisionIndex3].files.filter(o => `${o.file_id}` !== `${payload.file_id}`);

            return update(state, {
                revisions: {
                    [ideaIndex3]: {
                        revisions: {
                            [revisionIndex3]: {
                                files: {$set: files}
                            }
                        },
                    }
                }
            });

        case authTypes.LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};
