// Core
import { takeEvery } from 'redux-saga/effects';

// Instruments
import { asyncTypes } from './asyncTypes';
import { callFetchProjectTagsWorker } from './workers/fetchProjectTags';
import { callFetchProjectRevisionsWorker } from './workers/fetchProjectRevisions';
import { callFetchProjectRevisionWorker } from './workers/fetchProjectRevision';
import { callFetchProjectPitchesWorker } from './workers/fetchProjectPitches';
import { callFetchProjectLogsWorker } from './workers/fetchProjectLogs';
import { callFetchProjectCommentsWorker } from './workers/fetchProjectComments';
import { callFetchProjectMessagesWorker } from './workers/fetchProjectMessages';
import { callFetchProjectDetailsWorker } from './workers/fetchProjectDetails';
import { callFetchProjectDetailsByCodeWorker } from './workers/fetchProjectDetailsByCode';
import { callCreateTagWorker } from './workers/createTag';
import { callCreateMessageWorker } from './workers/createMessage';
import { callCreateCommentWorker } from './workers/createComment';
import { callUpdateCommentWorker } from './workers/updateComment';
import { callDeleteCommentWorker } from './workers/deleteComment';
import { callRequestRevisionWorker } from './workers/requestRevision';
import { callUpdateRevisionWorker } from './workers/updateRevision';
import { callFetchRevisionDiffWorker } from './workers/fetchRevisionDiff';
import { callDownloadProjectContentWorker } from './workers/downloadProjectContent';
import { callCheckTopicUniqueWorker } from './workers/checkTopicUnique';
import { callUpdateIdeasRevisionWorker } from './workers/updateIdeasRevision';
import { callCancelProjectWorker } from './workers/cancelProject';
import { callChangeProjectQueueWorker } from './workers/changeProjectQueue';
import { callDeleteProjectWorker } from './workers/deleteProject';
import { callApproveProjectWorker } from './workers/approveProject';
import { callExtendProjectDeadlineWorker } from './workers/extendProject';
import { callUpdateProjectWorker } from './workers/updateProject';
import { callMoveProjectWorker } from './workers/moveProject';
import { callAwardProjectWorker } from './workers/awardProject';
import { callReviewProjectWorker } from './workers/reviewProject';
import { callPublishProjectWorker } from './workers/publishProject';
import { callSetEditingProjectWorker } from './workers/setEditingProject';
import { callCreateProjectWorker } from './workers/createProject';
import { callConfirmProjectWorker } from './workers/confirmProject';
import { callSetIdeaStatusWorker } from './workers/setIdeaStatus';
import { callGetProjectsDesignCountWorker } from './workers/getProjectsDesignCount';
import { callGetOriginalityRevisionResultsWorker } from "./workers/getOriginalityRevisionResults";

export const projectWatchers = Object.freeze({
    * watchFetchProjectTags () {
        yield takeEvery(asyncTypes.FETCH_PROJECT_TAGS_ASYNC, callFetchProjectTagsWorker);
    },
    * watchFetchProjectDetails () {
        yield takeEvery(asyncTypes.FETCH_PROJECT_DETAILS_ASYNC, callFetchProjectDetailsWorker);
    },
    * watchFetchProjectDetailsByCode () {
        yield takeEvery(asyncTypes.FETCH_PROJECT_DETAILS_BY_CODE_ASYNC, callFetchProjectDetailsByCodeWorker);
    },
    * watchFetchProjectRevisions () {
        yield takeEvery(asyncTypes.FETCH_PROJECT_REVISIONS_ASYNC, callFetchProjectRevisionsWorker);
    },
    * watchFetchProjectRevision () {
        yield takeEvery(asyncTypes.FETCH_PROJECT_REVISION_ASYNC, callFetchProjectRevisionWorker);
    },
    * watchFetchProjectPitches () {
        yield takeEvery(asyncTypes.FETCH_PROJECT_PITCHES_ASYNC, callFetchProjectPitchesWorker);
    },
    * watchFetchProjectLogs () {
        yield takeEvery(asyncTypes.FETCH_PROJECT_LOGS_ASYNC, callFetchProjectLogsWorker);
    },
    * watchFetchProjectMessages () {
        yield takeEvery(asyncTypes.FETCH_PROJECT_MESSAGES_ASYNC, callFetchProjectMessagesWorker);
    },
    * watchFetchProjectComments () {
        yield takeEvery(asyncTypes.FETCH_PROJECT_COMMENTS_ASYNC, callFetchProjectCommentsWorker);
    },
    * watchCreateTag () {
        yield takeEvery(asyncTypes.CREATE_TAG_ASYNC, callCreateTagWorker);
    },
    * watchCreateMessage () {
        yield takeEvery(asyncTypes.CREATE_MESSAGE_ASYNC, callCreateMessageWorker);
    },
    * watchCreateComment () {
        yield takeEvery(asyncTypes.CREATE_COMMENT_ASYNC, callCreateCommentWorker);
    },
    * watchUpdateComment () {
        yield takeEvery(asyncTypes.UPDATE_COMMENT_ASYNC, callUpdateCommentWorker);
    },
    * watchDeleteComment () {
        yield takeEvery(asyncTypes.DELETE_COMMENT_ASYNC, callDeleteCommentWorker);
    },
    * watchRequestRevision () {
        yield takeEvery(asyncTypes.PUT_REQUEST_REVISION_ASYNC, callRequestRevisionWorker);
    },
    * watchUpdateRevision () {
        yield takeEvery(asyncTypes.UPDATE_REVISION_ASYNC, callUpdateRevisionWorker);
    },
    * watchFetchRevisionDiff () {
        yield takeEvery(asyncTypes.FETCH_REVISION_DIFF_ASYNC, callFetchRevisionDiffWorker);
    },
    * watchDownloadProjectContent () {
        yield takeEvery(asyncTypes.DOWNLOAD_PROJECT_CONTENT_ASYNC, callDownloadProjectContentWorker);
    },
    * watchCheckTopicUnique () {
        yield takeEvery(asyncTypes.CHECK_TOPIC_UNIQUE_ASYNC, callCheckTopicUniqueWorker);
    },
    * watchUpdateIdeasRevision () {
        yield takeEvery(asyncTypes.UPDATE_IDEAS_REVISION_ASYNC, callUpdateIdeasRevisionWorker);
    },
    * watchCancelProject () {
        yield takeEvery(asyncTypes.CANCEL_PROJECT_ASYNC, callCancelProjectWorker);
    },
    * watchChangeProjectQueue () {
        yield takeEvery(asyncTypes.CHANGE_PROJECT_QUEUE_ASYNC, callChangeProjectQueueWorker);
    },
    * watchDeleteProject () {
        yield takeEvery(asyncTypes.DELETE_PROJECT_ASYNC, callDeleteProjectWorker);
    },
    * watchApproveProject () {
        yield takeEvery(asyncTypes.APPROVE_PROJECT_ASYNC, callApproveProjectWorker);
    },
    * watchExtendProjectDeadline () {
        yield takeEvery(asyncTypes.EXTEND_PROJECT_DEADLINE_ASYNC, callExtendProjectDeadlineWorker);
    },
    * watchUpdateProject () {
        yield takeEvery(asyncTypes.UPDATE_PROJECT_ASYNC, callUpdateProjectWorker);
    },
    * watchMoveProject () {
        yield takeEvery(asyncTypes.MOVE_PROJECT_ASYNC, callMoveProjectWorker);
    },
    * watchAwardProject () {
        yield takeEvery(asyncTypes.AWARD_PROJECT_ASYNC, callAwardProjectWorker);
    },
    * watchReviewProject () {
        yield takeEvery(asyncTypes.REVIEW_PROJECT_ASYNC, callReviewProjectWorker);
    },
    * watchPublishProject () {
        yield takeEvery(asyncTypes.PUBLISH_PROJECT_ASYNC, callPublishProjectWorker);
    },
    * watchSetEditingProject () {
        yield takeEvery(asyncTypes.SET_EDITING_PROJECT_ASYNC, callSetEditingProjectWorker);
    },
    * watchCreateProject () {
        yield takeEvery(asyncTypes.CREATE_PROJECT_ASYNC, callCreateProjectWorker);
    },
    * watchConfirmProject () {
        yield takeEvery(asyncTypes.CONFIRM_PROJECT_ASYNC, callConfirmProjectWorker);
    },
    * watchSetIdeaStatus () {
        yield takeEvery(asyncTypes.SET_IDEA_STATUS_ASYNC, callSetIdeaStatusWorker);
    },
    * watchGetProjectsDesignCount () {
        yield takeEvery(asyncTypes.GET_PROJECTS_DESIGN_COUNT_ASYNC, callGetProjectsDesignCountWorker);
    },
    * watchGetOriginalityRevisionResults () {
        yield takeEvery(asyncTypes.GET_ORIGINALITY_REVISION_RESULTS_ASYNC, callGetOriginalityRevisionResultsWorker);
    },
});
