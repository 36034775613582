// Async types
export const asyncTypes = Object.freeze({
    GET_EXISTING_IDEAS_ASYNC:           'GET_EXISTING_IDEAS_ASYNC',
    GET_ACCOUNTS_WITHOUT_SUBS_ASYNC:    'GET_ACCOUNTS_WITHOUT_SUBS_ASYNC',
    GET_ACCOUNTS_NO_WRITING_SUBS_ASYNC: 'GET_ACCOUNTS_NO_WRITING_SUBS_ASYNC',
    GET_QUEUED_PROJECTS_ASYNC:          'GET_QUEUED_PROJECTS_ASYNC',
    GET_STATIC_DATA_ASYNC:              'GET_STATIC_DATA_ASYNC',
    GET_MESSAGES_ASYNC:                 'GET_MESSAGES_ASYNC',
    GET_TRANSACTIONS_ASYNC:             'GET_TRANSACTIONS_ASYNC',
    GET_IMAGES_ASYNC:                   'GET_IMAGES_ASYNC',
    GET_IMAGE_CATEGORIES_ASYNC:         'GET_IMAGE_CATEGORIES_ASYNC',
    GET_IMAGES_CAROUSEL_ASYNC:          'GET_IMAGES_CAROUSEL_ASYNC',
    GET_PROJECTS_ASYNC:                 'GET_PROJECTS_ASYNC',
    GET_UNREAD_MESSAGES_ASYNC:          'GET_UNREAD_MESSAGES_ASYNC',
    GET_ORDER_LIST_ASYNC:               'GET_ORDER_LIST_ASYNC',
});
