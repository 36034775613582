// Core
import { useEffect } from "react";

// Hooks
import { useLogin } from "../useLogin";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { getAppTitle } from "../../../helpers/helpers";
import { includes, isNil } from "ramda";

export const PasswordCreate = () => {
    /* Hooks */
    const { getTitle, getPassword, getSubmitBtn } = useLogin();
    const { isPasswordUpdated, passwordUpdatingError } = useUi();
    const { company } = useUser();

    /* Did mount */
    useEffect(() => {
        document.title = getAppTitle('Create password', company);
    }, []);

    if ( !isNil(isPasswordUpdated) && !isPasswordUpdated ) {
        return(
            <div className = 'gac-link-error' >
                {`${passwordUpdatingError} `}
            </div>
        );
    }

    return(
        <div className = 'gac-wrap'>
            { getTitle() }
            { getPassword() }
            { getSubmitBtn() }
        </div>
    );
};