// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getUserDetails, getProjectDetails, authStr } from '../../../../helpers/api';
import { projectActions } from '../../actions';
import { modalsActions } from "../../../modals/actions";
import { uiActions } from '../../../ui/actions';
import { includes } from "ramda";

export function* callApproveProjectWorker ({ payload: project}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${project.project_id}/approve`,{
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                ...project,
                account_uid: accountId
            }),
        });
        const { response: { show_user_nps },  error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const details = yield select(getProjectDetails);
        yield put(projectActions.setProjectState('details', { ...details, status: 'approved' }));
        yield put(modalsActions.setModal(null));
        const { user_role } = yield select(getUserDetails);
        if ( show_user_nps && !includes(user_role, 'basicclient/selfclient') ) {
            yield put(modalsActions.setModal('npsModal'));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
