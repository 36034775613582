// Core
import { useEffect, useRef } from "react";

// Components
import Hurray from '../../icons/Hurray';

// Hooks
import { useUser } from "../../../hooks/useUser";

// Instruments
import { domainGAC, epiic } from '../../../helpers/api';
import { isEpiic } from "../../../helpers/helpers";

export const ModalClientFeedback = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);

    /* Hooks */
    const { company: { branding: { host }}} = useUser();

    /* Did mount */
    useEffect(() => {
        const onResize = () => {
            setPos( modalRef );
        };

        setPos( modalRef );
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return(
        <div ref = { modalRef } className = 'gac-modal gac-client-feedback-modal'>
            <span className = 'gac-close-modal' onClick = { closeModal }/>
            <h1 style={{ marginBottom: 0 }} className='gac-client-feedback-title'>
                <i><Hurray/></i>
                We LOVE client feedback!
                <br/> Make our day with a review on
                <a target = '_blank' rel = 'noopener noreferrer' href = {`https://www.trustpilot.com/review/${ isEpiic(host) ? epiic : domainGAC}.com`}>trustpilot.com</a>
            </h1>
        </div>
    );
};