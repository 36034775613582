// Core
import { useLayoutEffect } from "react";

// Hooks
import { useUser } from "../../../hooks/useUser";
import { useForms } from "../../../hooks/useForms";
import { useOrder } from "../../../hooks/useOrder";

// Instruments
import { initDefaultRedactor, getOrderForm } from "../../../helpers/helpers";
import { isEmpty, isNil } from "ramda";

export const Keywords = ({ fields }) => {
    /* Hooks */
    const {
        getKeywordsLevelField,
        getTopicField,
        getBriefField,
        getTargetAudienceField,
        getTextareaField,
        getTargetCountryField,
        getTurnaroundField,
        getOrderOnField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;
    const { details: { country }} = useUser();
    const forms = useForms();
    const { updateFormState } = forms;
    const { job_type, job_pay_type, topic_ideas_type, seoaudit_type } = useOrder();

    const { target_country_code } = getOrderForm(job_type, topic_ideas_type, seoaudit_type, job_pay_type, forms);

    useLayoutEffect(() => {
        if ( isEmpty(target_country_code) ) {
            updateFormState('keywordsForm', { target_country_code: isEmpty(country) || isNil(country) ? 'US' : country });
        }

        initDefaultRedactor('#keywords-brief', 'brief', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#competitors-textarea', 'competitors', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#additional_details-textarea', 'additional_details', '116px', null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#keywords-brief', 'destroy');
            window.$R('#competitors-textarea', 'destroy');
            window.$R('#additional_details-textarea', 'destroy');
        };
    }, []);

    return <>
        { getKeywordsLevelField() }
        { getTopicField() }
        { getBriefField(false) }
        { getTargetAudienceField(false) }
        { getTextareaField('competitors', 'Main competitor websites') }
        { getTargetCountryField() }
        { getTextareaField('additional_details', 'Additional details', true) }
        { getTurnaroundField() }
        { getOrderOnField() }
    </>;
};