// Instruments
import { types } from './types';

export const socketActions = Object.freeze({
    initPusher: () => ({
        type: types.INIT_PUSHER,
    }),
    initPusherSuccess: (pusher) => ({
        type: types.INIT_PUSHER_SUCCESS,
        payload: pusher,
    }),
    setChannel: (name,channel) => ({
        type: types.SET_CHANNEL,
        payload: {
            name,
            channel,
        },
    }),
});
