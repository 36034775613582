import { authActions } from '../bus/auth/actions';
import { formsActions } from "../bus/forms/actions";
import { listsActions } from "../bus/lists/actions";
import { projectActions } from '../bus/project/actions';
import { projectActionsAsync } from '../bus/project/saga/asyncActions';
import { socketActions } from '../bus/socket/actions';
import { uiActions } from '../bus/ui/actions';
import { uiActionsAsync } from "../bus/ui/saga/asyncActions";
import { userActions } from "../bus/user/actions";
import { userActionsAsync } from "../bus/user/saga/asyncActions";
import { notifications } from '../helpers/notifications';
import { store } from './store';
import { isNil, isEmpty, includes } from 'ramda';
import { isStage } from "../helpers/api";
import {
    addUtcOffset,
    isClientFn,
    isSamePusherSession,
    sortMessages,
    getOrderForm,
    upperCaseFirstLetter,
    purchaseTrack,
    getAmountByCurrency
} from '../helpers/helpers';
import { clientsActions } from '../helpers/constants';
import update from 'immutability-helper';

/* Public channel */
export const subPublicChannel = (pusher) => {
    const channel = pusher.subscribe(`public-general`);
    store.dispatch(socketActions.setChannel('publicChannel',channel));
};
export const unsubPublicChannel = (pusher) => {
    pusher.unsubscribe(`public-general`);
    store.dispatch(socketActions.setChannel('publicChannel',null));
};
export const bindClientEvent = (channel) => {
    channel.bind('client-event', ({ event, data }) => {
        if ( event === 'deploy' ) {
            store.dispatch(uiActions.setUiState('isMaintenance', data.deploying));
        }
    });
};

/* User channel */
export const subUserChannel = (pusher, userId) => {
    const channel = pusher.subscribe(`presence-user-${userId}`);
    store.dispatch(socketActions.setChannel('userChannel',channel));
};
export const unsubUserChannel = (pusher, userId) => {
    pusher.unsubscribe(`presence-user-${userId}`);
    store.dispatch(socketActions.setChannel('userChannel',null));
};
export const bindLogoutEvent = (channel) => {
    channel.bind('logout', (data) => {
        const session_id = data.session_id;
        const { sessid } = store.getState().auth.keys;
        const { sessionID } = store.getState().socket.pusher;
        if ( !isSamePusherSession(data,sessionID) && (isNil(session_id) || session_id === sessid) ) {
            store.dispatch(authActions.clearCookies());
            window.location.href = '/login';
        }
    });
};
export const bindConfirmOrderEvent = (channel) => {
    channel.bind('confirmOrder', (data) => {
        const path = window.location.pathname;
        const { sessionID } = store.getState().socket.pusher;
        if ( includes('/order/confirm', path) ) {
            if ( !isSamePusherSession(data,sessionID) ) {
                window.location.reload();
            }
        }
    });
};
export const bindAddToCartEvent = (channel) => {
    channel.bind('addToCart', (data) => {
        const { sessionID } = store.getState().socket.pusher;
        const { accountId } = store.getState().auth.keys;
        if ( !isSamePusherSession(data,sessionID) && accountId === data.account_uid ) {
            let orderList = store.getState().lists.orderList;
            orderList = isNil(orderList) || isEmpty(orderList) ? [] : orderList;
            const newList = [ ...orderList, data ];
            store.dispatch(listsActions.setListsState('orderList',newList));
            store.dispatch(userActions.updateOrderCount(data.account_uid, `${newList.length}`));
        }
    });
};
export const bindRemoveFromCartEvent = (channel) => {
    channel.bind('removeFromCart', (data) => {
        const path = window.location.pathname;
        const { sessionID } = store.getState().socket.pusher;
        const { accountId } = store.getState().auth.keys;
        if ( !isSamePusherSession(data,sessionID) && accountId === data.account_uid ) {
            const orderList = store.getState().lists.orderList;
            const newList = orderList.filter(o => `${o.project_id}` !== `${data.project_id}` );
            store.dispatch(listsActions.setListsState('orderList',newList));
            store.dispatch(userActions.updateOrderCount(data.account_uid, `${newList.length}`));
            if ( includes('/order/confirm', path) && newList.length === 0 ) {
                window.location.href = '/order';
            }
        }
    });
};
export const bindRemoveAllFromCartEvent = (channel) => {
    channel.bind('removeAllFromCart', (data) => {
        const path = window.location.pathname;
        const { sessionID } = store.getState().socket.pusher;
        const { accountId } = store.getState().auth.keys;
        if ( !isSamePusherSession(data,sessionID) && accountId === data.account_uid ) {
            store.dispatch(listsActions.setListsState('orderList',[]));
            store.dispatch(userActions.updateOrderCount(data.account_uid, null));
            if ( includes('/order/confirm', path) ) {
                window.location.href = '/order';
            }
        }
    });
};
export const bindProjectUpdatedEvent = (channel) => {
    channel.bind('projectUpdated', (data) => {
        const { sessionID } = store.getState().socket.pusher;
        const orderList = store.getState().lists.orderList;
        if ( !isSamePusherSession(data,sessionID) && orderList.some(o => `${o.project_id}` === `${data.project_id}` ) ) {
            const index = orderList.findIndex(o => `${o.project_id}` === `${data.project_id}`);
            const newList = update(orderList, { [index]: {$set: data } });
            store.dispatch(listsActions.setListsState('orderList',newList));
        }
    });
};
export const bindBundlePurchasedEvent = (channel) => {
    channel.bind('bundlePurchased', (data) => {
        const { sessionID } = store.getState().socket.pusher;
        const { accountId } = store.getState().auth.keys;
        if ( !isSamePusherSession(data,sessionID) ) {
            store.dispatch(userActionsAsync.fetchUserDetailsAsync());
            store.dispatch(userActionsAsync.fetchBalanceAsync(accountId));
        }
    });
};
export const bindUnreadMessages = (channel) => {
    channel.bind('unreadMessages', (data) => {
        const path = window.location.pathname;
        if ( data.message_count > 0 && !includes('messages', path) ) {
            const { unreadMessages: messages } = store.getState().lists;
            const arr = isNil(messages) || isEmpty(messages) ? [] : messages.filter(o => `${o.project_id}` !== `${data.project_id}`);
            store.dispatch(listsActions.setListsState('unreadMessages', [...arr, { project_id: `${data.project_id}`, message_count: `${data.message_count}` }]));
        }
    });
};
export const bindChangedQueueEvent = (channel) => {
    channel.bind('changedQueue', (data) => {
        const path = window.location.pathname;
        const { sessionID } = store.getState().socket.pusher;
        const projects = store.getState().lists.projects;
        if ( !isSamePusherSession(data,sessionID) ) {
            if ( includes('/current-projects', path) && !isEmpty(projects) && !isNil(projects) ) {
                const updatedProjects = projects.map(o => {
                    const isQueued = data.data.some(a => `${a.project_id}` === `${o.id}`);
                    return isQueued ? { ...o, due: data.data.filter(b => `${b.project_id}` === `${o.id}`)[0].queue } : o;
                });
                store.dispatch(listsActions.setListsState('projects', updatedProjects));
            }
        }
    });
};
export const bindMessagesCenterNewMessageEvent = (channel) => {
    channel.bind('newMessage', (data) => {
        let messages = store.getState().lists.messages;
        messages = isNil( messages ) ? [] : messages;

        store.dispatch(listsActions.setListsState('messages',sortMessages( [ ...messages, data ])));
    });
};
export const bindChangedBalanceEvent = (channel) => {
    channel.bind('balance', (data) => {
        const { accountId } = store.getState().auth.keys;
        if ( accountId === data.account_uid ) {
            const { balance } = store.getState().user;
            const { topic_ideas_type, job_type, job_pay_type, seoaudit_type } = store.getState().order;
            const forms = store.getState().forms;
            const newBalance = {
                ...balance,
                balance: data.amount,
                words_balance: data.words,
            }
            store.dispatch(userActions.setUserState('balance', newBalance));
            if ( job_type === 'blogging' && includes(topic_ideas_type, 'existing/own') ) {
                const { word_count } = getOrderForm(job_type, topic_ideas_type, seoaudit_type, job_pay_type, forms);
                if ( Number(word_count) > Number(data.words) ) {
                    store.dispatch(formsActions.updateFormState(`blogging${upperCaseFirstLetter(topic_ideas_type)}Form`, { word_count: data.words }));
                }
            }
        }
    });
};
export const bindNewSubscriptionPaymentEvent = (channel, navigate) => {
    channel.bind('newSubscriptionPayment', ({ action, subscription, message }) => {
        if ( action === 'payment-failed' ) {
            store.dispatch(uiActions.setUiState('notification', { msg: message, type: 'error', fn: null }));
        } else {
            const path = window.location.pathname;
            const { subscriptions, branding: { host }} = store.getState().user.company;
            const { currencies } = store.getState().lists.staticData;
            const subs = subscriptions.filter(o => o.subscription_uid !== subscription.subscription_uid);
            store.dispatch(userActions.setUserCompanyState('subscriptions', [...subs, subscription]));
            store.dispatch(uiActions.setUiState('notification', { msg: null, type: null, fn: null }));
            if ( includes('/subscriptions', path )) {
                navigate('/subscriptions');
            } else if ( includes('/payment/confirm', path ) ) {
                navigate('/payment/success');
            }
            const { amount, currency, status, plan_name } = subscription;
            if ( status !== 'pending' && !isStage ) {
                const { email } = store.getState().user.details;
                const { accountId } = store.getState().auth.keys;
                const { amount_usd, rate } = getAmountByCurrency(amount, currency, currencies);

                purchaseTrack(amount_usd, host, email);
                store.dispatch(uiActionsAsync.gaTrackingDebugAsync({
                    amount: `${amount}`,
                    currency,
                    email,
                    items: `subscription: ${plan_name}`,
                    notes: `host: ${host}, amount_usd: ${amount_usd}, rate: ${rate}, pusher: newSubscriptionPayment, gtag: ${window.gtag}, account_uid: ${accountId}, url: ${window.location.href}, params: ${window.location.search}`
                }));
            }
        }
    });
};

/* Project channel */
export const subProjectChannel = (pusher, projectId) => {
    const channel = pusher.subscribe(`presence-project-${projectId}`);
    store.dispatch(socketActions.setChannel('projectChannel',channel));
};
export const unsubProjectChannel = (pusher, projectId) => {
    pusher.unsubscribe(`presence-project-${projectId}`);
    store.dispatch(socketActions.setChannel('projectChannel',null));
};
export const bindNewMessageEvent = (channel) => {
    channel.bind('newMessage', (data) => {
        const path = window.location.pathname;
        if ( includes('messages', path) ) {
            const timezone = store.getState().user.details.timezone;
            const date_added = isNil(timezone) ? data.date_added : addUtcOffset(data.date_added, timezone);
            const message = {
                ...data,
                date_added,
            };
            store.dispatch(projectActions.createMessageSuccess(message));
        }
    });
};
export const bindAwardPitchEvent = (channel) => {
    channel.bind('awardPitch', (data) => {
        const path = window.location.pathname;
        const { details } = store.getState().project;
        if ( includes('messages', path) && !isNil(data.pitch_id) ) {
            store.dispatch(projectActions.approvePitchSuccess(data.pitch_id));
        }
        store.dispatch(projectActions.setProjectState('details', { ...details, status: 'writing' }));
    });
};
export const bindAddLogEvent = (channel) => {
    channel.bind('addedLog', (data) => {
        const path = window.location.pathname;
        const { user_role } = store.getState().user.details;
        const timezone = store.getState().user.details.timezone;
        const { details } = store.getState().project;
        if ( includes(data.action, clientsActions) ) {
            if ( includes('sharing', path) ) {
                let logs = store.getState().project.client_logs;
                const create_date = isNil(timezone) ? data.create_date : addUtcOffset(data.create_date, timezone);
                logs = isEmpty(logs) || isNil(logs) ? [] : logs;
                const log = {
                    ...data,
                    create_date,
                };
                store.dispatch(projectActions.setProjectState('client_logs', [ ...logs, log ]));
            }
        } else {
            const { project_id, job_type, revision } = details;
            const isFetchingDisabled = true;
            if ( includes('messages', path) && !isClientFn(user_role) ) {
                store.dispatch(projectActionsAsync.fetchProjectLogsAsync({ project_id, isFetchingDisabled }));
                if ( includes(data.action, 'designing/writing') ) {
                    store.dispatch(projectActionsAsync.fetchProjectPitchesAsync({ project_id, isFetchingDisabled }));
                    store.dispatch(projectActionsAsync.fetchProjectMessagesAsync({ project_id, isFetchingDisabled }));
                }
            }
            if ( data.action === 'extended' ) {
                store.dispatch(projectActions.setProjectState('details', { ...details, extended: 'yes'}));
            } else {
                store.dispatch(projectActions.setProjectState('details', { ...details, status: data.action === 'review' ? 'revision' : data.action, revision: includes(job_type, 'design/motion') && data.action === 'matching' ? true : revision }));
                if ( ('approval/editing'.includes(data.action) && includes('content', path)) || ( includes(job_type, 'design/motion') && includes(data.action, 'revision,matching')) ) {
                    store.dispatch(projectActionsAsync.fetchProjectRevisionsAsync({ project: { project_id, job_type }, isFetchingDisabled }));
                    store.dispatch(projectActionsAsync.fetchProjectCommentsAsync({ project: { project_id, job_type }, isFetchingDisabled }));
                }
                if ( 'writing'.includes(data.action) ) {
                    store.dispatch(projectActionsAsync.fetchProjectDetailsAsync(project_id, null));
                }
            }
        }
    });
};
export const bindEditProjectEvent = (channel) => {
    channel.bind('editProject', (data) => {
        const { details } = store.getState().project;
        const { editing } = details;
        const { user_role } = store.getState().user.details;
        if ( !isNil(editing) && editing !== data.editing && !isClientFn(user_role) ) {
            const msg = data.editing ? notifications.editingProject : { msg: null, type: null, fn: null };
            store.dispatch(uiActions.setUiState('notification', msg));
            store.dispatch(projectActions.setProjectState('details', { ...details, editing: data.editing}));
        }
    });
};
export const bindUpdateProjectEvent = (channel) => {
    channel.bind('updateProjectContent', (data) => {
        const { project_id, job_type } = store.getState().project.details;
        const { sessionID } = store.getState().socket.pusher;
        if ( `${sessionID}` !== `${data.pusherData.sessionID}` ) {
            store.dispatch(projectActionsAsync.fetchProjectRevisionAsync({ project_id, job_type }));
        }
    });
};
export const bindUpdateCommentNumbersEvent = (channel) => {
    channel.bind('updateCommentNumbers', (data) => {
        if ( isEmpty(data) ) return false;
        const { comments } = store.getState().project;
        if ( isNil(comments) || isEmpty(comments) ) return false;

        const updatedComments = comments.map(o => ({ ...o, order: data.some(el => !isNil(el[o.id])) ? data.filter(a => !isNil(a[o.id]))[0][o.id] : o.order }));

        store.dispatch(projectActions.setProjectState('comments', updatedComments));
    });
};

/* Project comments channel */
export const subCommentsChannel = (pusher, projectId) => {
    const channel = pusher.subscribe(`presence-project-${projectId}-comments`);
    store.dispatch(socketActions.setChannel('commentsChannel',channel));
};
export const unsubCommentsChannel = (pusher, projectId) => {
    pusher.unsubscribe(`presence-project-${projectId}-comments`);
    store.dispatch(socketActions.setChannel('commentsChannel',null));
};
export const bindAddCommentEvent = (channel) => {
    channel.bind('updatedCommentHtml', (data) => {
        const timezone = store.getState().user.details.timezone;
        const create_date = isNil(timezone) ? data.create_date : addUtcOffset(data.create_date, timezone);
        const comment = {
            ...data,
            create_date,
        };
        const { project_id, job_type } = store.getState().project.details;
        const { idea_number } = data;
        store.dispatch(projectActions.createCommentSuccess(comment));
        if ( job_type !== 'design' ) {
            store.dispatch(projectActionsAsync.fetchProjectRevisionAsync({ project_id, job_type, idea_number }));
        }
    });
};
export const bindUpdateCommentEvent = (channel) => {
    channel.bind('updatedComment', (data) => {
        store.dispatch(projectActions.updateCommentSuccess(data));
    });
};
export const bindDeleteCommentEvent = (channel) => {
    channel.bind('deletedComment', (data) => {
        const { project_id, job_type } = store.getState().project.details;
        const { idea_number } = data;
        store.dispatch(projectActionsAsync.fetchProjectRevisionAsync({ project_id, job_type, idea_number }));
        store.dispatch(projectActions.deleteCommentSuccess(data.id));
    });
};