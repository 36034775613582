// Prop-types
import { array, bool, func, object, oneOfType } from 'prop-types';

const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

ConditionalWrapper.propTypes = {
    condition: bool,
    wrapper: func,
    children: oneOfType([array, object]),
};
export default ConditionalWrapper;