// Core
import { useLayoutEffect } from "react";

// Instruments
import { initDefaultRedactor } from "../../../helpers/helpers";

export const Ideas = ({ fields }) => {
    /* Hooks */
    const {
        getFormatField,
        getIndustryField,
        getQuantityField,
        getLevelField,
        getTopicField,
        getTextareaField,
        getTargetAudienceField,
        getContentGoalField,
        getBriefField,
        getPlannedWordCountField,
        getWritersField,
        getTurnaroundField,
        getOrderOnField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;

    useLayoutEffect(() => {
        initDefaultRedactor('#product_service-textarea', 'product_service', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#ideas-brief', 'brief', null, null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#product_service-textarea', 'destroy');
            window.$R('#ideas-brief', 'destroy');
        };
    }, []);

    return <>
        { getFormatField() }
        { getIndustryField() }
        { getQuantityField() }
        { getLevelField() }
        { getTopicField() }
        { getTextareaField('product_service', 'Target product/service or keywords') }
        { getTargetAudienceField() }
        { getContentGoalField() }
        { getBriefField(true) }
        { getPlannedWordCountField() }
        { getWritersField() }
        { getTurnaroundField() }
        { getOrderOnField() }
    </>;
};