// Core
import { call, put, select } from 'redux-saga/effects';
import moment from "moment/moment";
import update from "immutability-helper";

// Instruments
import { api, getKeys, getIsMobile, getProjects, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { modalsActions } from '../../actions';
import { listsActions } from "../../../lists/actions";

export function* callCreateProjectWorker ({ payload: { project, action }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${project.job_type}/calendar?account_uid=${accountId}`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(project),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const isMobile = yield select(getIsMobile);
        let projects = yield select(getProjects);

        if (isMobile) {
            const index = projects.map((item) => item.date).indexOf(moment(data[0].order_on).format('YYYY-MM-DD'));
            if ( index > 0 ) {
                projects = update(projects, {[index]: {data: {$push: [data[0]]}}});
            }
        } else {
            projects = [ ...projects, data[0] ];
        }
        yield put(listsActions.setListsState('projects', projects));
        if ( action === 'order' ) {
            yield put(modalsActions.setModalState('createdProjectId', data[0].project_id));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
