// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getAuth } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { authActions } from '../../../auth/actions';

export function* callUpdatePasswordWorker ({ payload: { password, secret, navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(fetch, `${api}/v1/set_password`,{
            method: 'PUT',
            headers: {
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({ password, secret }),
        });
        const { response: { email }, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const isAuth = yield select(getAuth);
        if ( isAuth ) {
            navigate('/calendar');
            yield put(uiActions.setUiState('notification', {
                msg: 'Password updated',
                type: 'success',
                fn: null,
            }));
            yield put(uiActions.setUiState('initPath', ''));
        } else {
            yield put(authActions.login({ password, email }, navigate));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('isPasswordUpdated', false));
        yield put(uiActions.setUiState('passwordUpdatingError', error.message));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
