// Core
import { useState, useEffect, useRef } from "react";

// Components
import Recaptcha from 'react-recaptcha';

// Hooks
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import { useUi } from "../../hooks/useUi";
import { useUser } from "../../hooks/useUser";

// Instruments
import cx from 'classnames';
import { includes, isNil } from "ramda";
import { captchaSiteKey } from "../../helpers/api";
import { EMAIL_REGEX, isWhiteLabel } from "../../helpers/helpers";

export const useLogin = () => {
    /* Ref */
    const captchaRef = useRef(null);

    /* State */
    const [invalidFields, setInvalidFields] = useState([]);
    const [isPassVisible, setIsPassVisible] = useState(false);
    const [isInvalidCaptcha, setIsInvalidCaptcha] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [secret, setSecret] = useState('');
    const [fields, setFields] = useState({ email: '', password: '' });

    /* Hooks */
    const { login } = useAuth();
    const { isLoginFail, loginAttempt, loginError, loginEmail, isPasswordRestored, passwordRestoreError, setUiState } = useUi();
    const { restorePasswordAsync, updatePasswordAsync } = useUser();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const secret = search.substring(1);

        setSecret(secret);
        setFields({
            ...fields,
            email: loginEmail,
        });

        if ( search && includes('ref', search)) {
            const ref = search.substring(1).split('=')[1];
            setUiState('reference', ref);
        }
    }, []);
    useEffect(() => {
        if ( loginEmail ) {
            setFields({
                ...fields,
                email: loginEmail,
            });
        }
    }, [loginEmail]);
    useEffect(() => {
        const secret = search.substring(1);

        if ( secret ) {
            setSecret(secret);
        }
    }, [pathname]);

    /* Actions */
    const onKeyPress = ({ key }) => {
        if(key === 'Enter'){
            onSubmit();
        }
    };
    const onInputChange = ({ currentTarget: { value, dataset: { field }}}) => {
        let data = value;

        if ( field === 'email' ) {
            data = data.trim().replace(/\s\s+/g, '');
        }

        setFields({
            ...fields,
            [field]: data,
        });
        setInvalidFields(invalidFields.filter((item) => item !== field));

        if ( pathname === '/login' ) {
            if ( isLoginFail ) {
                setUiState('isLoginFail', false);
            }
        } else if ( pathname === '/password' ) {
            if ( field === 'email' && !isNil(isPasswordRestored) && !isPasswordRestored ) {
                setUiState('isPasswordRestored', null);
            }
        }
    };
    const validateField = ({ currentTarget: { dataset: { field }}}) => {
        let fieldsData = invalidFields;

        if ( field === 'email' ) {
            if (!(EMAIL_REGEX.test(fields.email.toLowerCase()))) {
                fieldsData = [ ...invalidFields, field ];
            } else {
                fieldsData = invalidFields.filter((item) => item !== field);
            }
        } else {
            if ( fields.password.length < 8 ) {
                fieldsData = [ ...invalidFields, field ];
            } else {
                fieldsData = invalidFields.filter((item) => item !== field);
            }
        }

        setInvalidFields(fieldsData);
    };
    const togglePassVisible = () => {
        setIsPassVisible(!isPassVisible);
    };
    const verifyRecaptcha = (response) => {
        if (response) {
            setIsInvalidCaptcha(false);
            setIsVerified(true);
        }
    };
    const onSubmit = () => {
        const { email, password } = fields;
        let invalidFields = [];

        if ( pathname === '/login' || pathname === '/password' ) {
            if (!(EMAIL_REGEX.test(email.toLowerCase()))) {
                invalidFields.push('email');
            }
        }
        if ( pathname === '/login' || pathname === '/password-update' || pathname === '/password-create' ) {
            if ( password.length < 8 ) {
                invalidFields.push('password');
            }
        }

        if ( !invalidFields.length ) {
            if ( pathname === '/login' && !isLoginFail ) {
                if ( loginAttempt > 2 ) {
                    if ( isVerified || isWhiteLabel() ) {
                        login({ email, password }, navigate);
                        setIsVerified(false);
                        if ( !isNil( captchaRef.current ) ) {
                            captchaRef.current.reset();
                        }
                    } else {
                        setIsInvalidCaptcha(true);
                    }
                } else {
                    login({ email, password }, navigate);
                }
            } else if ( pathname === '/password' ) {
                if ( isVerified || isWhiteLabel() ) {
                    restorePasswordAsync(email);
                    setIsVerified(false);
                    if ( !isNil( captchaRef.current ) ) {
                        captchaRef.current.reset();
                    }
                } else {
                    setIsInvalidCaptcha(true);
                }
            } else if ( pathname === '/password-update' || pathname === '/password-create' ) {
                updatePasswordAsync(password, secret, navigate);
            }
        } else {
            setInvalidFields(invalidFields);
            setIsInvalidCaptcha(!isVerified && !isWhiteLabel());
        }
    };

    /* Html */
    const getTitle = () => {
        if ( pathname === '/login' ) {
            return <div className='gac-title'>Let's <span>log in</span></div>;
        }
        return <div className='gac-title'>{ pathname === '/password-update' ? 'New password' : 'Enter a new password to confirm the account' }</div>;
    };
    const getEmail = () => {
        const classNames = cx( 'gac-input', {
            'gac-invalid': includes('email', invalidFields) || ( pathname === '/login' && isLoginFail ) || ( pathname === '/password' && !isNil(isPasswordRestored) && !isPasswordRestored ),
        });

        return(
            <>
                <label>Your email</label>
                <div className = 'gac-input-wrap'>
                    <input
                        data-field = 'email'
                        onKeyPress = { onKeyPress }
                        className = { classNames }
                        type = 'text'
                        value = { fields.email }
                        data-hj-whitelist
                        onBlur = { validateField }
                        onChange = { onInputChange }/>
                    { includes('email', invalidFields) && <div className = 'gac-error'>Please enter a valid email</div> }
                    { isLoginFail && <div className = 'gac-error'>{ loginError ? loginError : 'Incorrect email and/or password' }</div> }
                    { !isNil(isPasswordRestored) && !isPasswordRestored && <div className = 'gac-error'>{ passwordRestoreError ? passwordRestoreError : 'Email not found' }</div> }
                </div>
            </>
        );
    };
    const getPassword = () => {
        const errorArr = ['No client accounts assigned to this user. Ask your manager.', 'Account is inactive, contact your manager'];
        const classNames = cx( 'gac-input', {
            'gac-invalid': includes('password', invalidFields) || (isLoginFail && !includes(loginError, errorArr)),
        });

        return(
            <>
                { !includes(pathname, '/password-update/password-create') && <label>Password</label> }
                <div className = 'gac-input-wrap'>
                    <input
                        data-field = 'password'
                        className = { classNames }
                        type = { isPassVisible ? 'text' : 'password' }
                        value = { fields.password }
                        onBlur = { validateField }
                        onKeyPress = { onKeyPress }
                        onChange = { onInputChange }/>
                    <span onClick = { togglePassVisible } className = { `gac-login-icon-1 ${isPassVisible ? 'gac-visible' : ''}` }/>
                    { includes('password', invalidFields) && <div className = 'gac-error'>Should be 8 characters or longer</div> }
                    { (isLoginFail && !includes(loginError, errorArr)) && <div className = 'gac-error'>Incorrect email and/or password</div> }
                </div>
            </>
        );
    };
    const getRecaptcha = () => {
        return <>
            <Recaptcha ref = { captchaRef } render = 'explicit' sitekey = { captchaSiteKey } verifyCallback = { verifyRecaptcha } onloadCallback = { () => {} } />
            { isInvalidCaptcha && <div className = 'gac-error'>Please verify that you are a human!</div> }
        </> ;
    };
    const getSubmitBtn = () => {
        const btn = {
            '/login': 'Log in',
            '/password': 'Email instructions',
            '/password-update': 'Update password',
            '/password-create': 'Confirm account',
        };
        return(
            <span onClick = { onSubmit } className = 'gac-btn gac-btn-m'>
                { btn[pathname] }
            </span>
        );
    };
    const getSignUpLink = () => {
        if ( isWhiteLabel() ) return null;

        return(
            <div className = 'gac-login-sign-up'>
                Don’t have an account? <Link to = '/company/new'>Sign up</Link>
            </div>
        );
    };

    return {
        getTitle,
        getEmail,
        getPassword,
        getRecaptcha,
        getSubmitBtn,
        getSignUpLink,
    };
};