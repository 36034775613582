// Components
import { ImagesLibrary } from "../../ImagesLibrary";

// Hooks
import { useUi } from "../../../hooks/useUi";

// Instruments
import animateScrollTo from "animated-scroll-to";

export const ModalImages = ({ closeModal }) => {
    /* Hooks */
    const { uploadFileFromUrlAsync } = useUi();

    /* Actions */
    const onImageClick = (url) => {
        uploadFileFromUrlAsync({ url });
        animateScrollTo(document.getElementById('image_option'), {
            verticalOffset: -100,
            speed: 300,
        });
        closeModal();
    };

    return <div className = 'gac-modal-images'>
        <span className = 'gac-close-modal' onClick = { closeModal }/>
        <ImagesLibrary type = 'modal' uploadFileFromUrl = { onImageClick }/>
    </div> ;
};