// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { projectActions } from '../../actions';
import { isNil } from 'ramda';

export function* callFetchProjectPitchesWorker ({ payload: { project_id, isFetchingDisabled }}) {
    try {
        if (isNil(isFetchingDisabled)) { yield put(uiActions.enableFetching()); }
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/pitches/${project_id}?account_uid=${accountId}`, {
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: pitches, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(projectActions.setProjectState('pitches', pitches));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        if (isNil(isFetchingDisabled)) { yield put(uiActions.disableFetching()); }
    }
}
