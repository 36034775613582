// Core
import { call, put } from 'redux-saga/effects';

// Instruments
import { api } from '../../../../helpers/api';
import { uiActions } from "../../actions";

export function* callCheckBriefLinksSharedWorker ({ payload: text }) {
    try {
        const response = yield call(fetch, `${api}/v1/brief_links_shared`,{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                text,
            }),
        });
        const { response: value, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( value === 'no' || value === 'shared no' ) {
            yield put(uiActions.setUiState('brief_links_shared', 'no'));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    }
}
