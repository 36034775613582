// Components
import { WritingOneOff } from "./WritingOneOff";
import { WritingSubscription } from "./WritingSubscription";

// Hooks
import { useOrder } from "../../../hooks/useOrder";

export const Writing = ({ page, fields }) => {
    /* Hooks */
    const { job_pay_type } = useOrder();

    /* Html */
    const getContent = () => {
        const config = {
            'one-off': <WritingOneOff fields = { fields } />,
            'subscription': <WritingSubscription fields = { fields } page = { page } />
        }

        return config[job_pay_type];
    };

    return <>
        { getContent() }
    </>;
};
