// Core
import { put, call, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { listsActions } from '../../actions';
import { uiActions } from '../../../ui/actions';

export function* callGetAccountsNoWritingSubsWorker ({ payload: { account_uid, currency, talent, platform } }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/accounts/subscriptions/no-writing?account_uid=${account_uid}&currency=${currency}&talent=${talent}&platform=${platform}`,{
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: accounts, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(listsActions.setListsState('accountsNoWritingSubs',accounts));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
