// Core
import { takeEvery } from 'redux-saga/effects';

// Instruments
import { asyncTypes } from './asyncTypes';
import { callCreateSupportMessageWorker } from './workers/createSupportMessage';
import { callCreateProjectWorker } from './workers/createProject';

export const modalsWatchers = Object.freeze({
    * watchCreateSupportMessage () {
        yield takeEvery(asyncTypes.CREATE_SUPPORT_MESSAGE_ASYNC, callCreateSupportMessageWorker);
    },
    * watchCreateProjectInModal () {
        yield takeEvery(asyncTypes.CREATE_PROJECT_IN_MODAL_ASYNC, callCreateProjectWorker);
    },
});
