// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getAuth, getTalents, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from "../../actions";
import { modalsActions } from "../../../modals/actions";
import { isEmpty } from "ramda";
import { updateTalentsObj } from "../../../../helpers/helpers";

export function* callFetchTalentsWorker ({ payload: data }) {
    try {
        yield put(uiActions.enableFetching());
        const isAuth = yield select(getAuth);

        if ( isAuth ) {
            const { privateKey, publicKey, accountId } = yield select(getKeys);
            const { type, page } = data;
            let url = `?account_uid=${ data && data.account_uid ? data.account_uid : accountId}`;
            if ( data && data.auid_list ) {
                url = `${url}&auid_list=${data.auid_list}`;
            }
            const response = yield call(fetch, `${api}/${publicKey}/v1/${type}${url}`, {
                method: 'GET',
                headers: {
                    'Authorization': `${authStr} ${privateKey}`,
                }
            });
            const { response: { list, suggested }, error: { message }} = yield call([response, response.json]);

            if (response.status !== 200) {
                throw new Error(message);
            }

            const talents = yield select(getTalents);
            const talentsData = updateTalentsObj(talents, list, type);
            yield put(userActions.setUserState('talents', talentsData));
            if ( page === 'brief' || page === 'order' ) {
                if ( isEmpty(list) ) {
                    yield put(uiActions.setUiState('isEmptyWriters', true));
                } else {
                    yield put(modalsActions.setModal('modalTalents'));
                }
            } else if ( page === 'team' && data.account_uid ) {
                if ( !isEmpty(suggested) ) {
                    yield put(userActions.setUserState('talents', updateTalentsObj(talentsData, suggested, type, 'suggested')));
                }
            }
        } else {
            yield put(uiActions.setUiState('isEmptyWriters', true));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    } finally {
        yield put(uiActions.disableFetching());
    }
}
