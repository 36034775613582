// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getProjectDetails, authStr } from '../../../../helpers/api';
import { projectActions } from "../../actions";
import { uiActions } from '../../../ui/actions';

export function* callPublishProjectWorker ({ payload: { data, action }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${data.project_id}/publish?account_uid=${accountId}`,{
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                ...data,
            }),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const project = yield select(getProjectDetails);
        yield put(projectActions.setProjectState('details', { ...project, 'publish_url': data.publish_url }));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
