// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import {api, getKeys, authStr, getPusherData} from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { authActions } from "../../actions";

export function* callLogoutWorker ({ payload: { navigate }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, sessid } = yield select(getKeys);
        const { sessionID } = yield select(getPusherData);
        const response = yield call(fetch, `${api}/${publicKey}/v1/logout`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                _gac_prk: privateKey,
                session_id: sessid,
                pusherData: {
                    sessionID
                },
            }),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(authActions.clearCookies());
        yield put(authActions.logoutSuccess());
        navigate('/login');
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    } finally {
        yield put(uiActions.disableFetching());
    }
}
