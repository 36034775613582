// Core
import { takeEvery } from 'redux-saga/effects';

// Instruments
import { asyncTypes } from './asyncTypes';
import { callGetExistingIdeasWorker } from './workers/getExistingIdeas';
import { callGetAccountsWithoutSubsWorker } from './workers/getAccountsWithoutSubs';
import { callGetAccountsNoWritingSubsWorker } from './workers/getAccountsNoWritingSubs';
import { callGetQueuedProjectsWorker } from './workers/getQueuedProjects';
import { callGetStaticDataWorker } from './workers/getStaticData';
import { callGetMessagesWorker } from './workers/getMessages';
import { callGetTransactionsWorker } from "./workers/getTransactions";
import { callGetImagesWorker } from './workers/getImages';
import { callGetImageCategoriesWorker } from './workers/getImageCategories';
import { callGetImagesCarouselWorker } from './workers/getImagesCarousel';
import { callGetProjectsWorker } from "./workers/getProjects";
import { callGetUnreadMessagesWorker } from "./workers/getUnreadMessages";
import { callGetOrderListWorker } from './workers/getOrderList';

export const listsWatchers = Object.freeze({
    * watchGetExistingIdeas () {
        yield takeEvery(asyncTypes.GET_EXISTING_IDEAS_ASYNC, callGetExistingIdeasWorker);
    },
    * watchGetAccountsWithoutSubs () {
        yield takeEvery(asyncTypes.GET_ACCOUNTS_WITHOUT_SUBS_ASYNC, callGetAccountsWithoutSubsWorker);
    },
    * watchGetAccountsNoWritingSubs () {
        yield takeEvery(asyncTypes.GET_ACCOUNTS_NO_WRITING_SUBS_ASYNC, callGetAccountsNoWritingSubsWorker);
    },
    * watchGetQueuedProjects () {
        yield takeEvery(asyncTypes.GET_QUEUED_PROJECTS_ASYNC, callGetQueuedProjectsWorker);
    },
    * watchGetStaticData () {
        yield takeEvery(asyncTypes.GET_STATIC_DATA_ASYNC, callGetStaticDataWorker);
    },
    * watchGetMessages () {
        yield takeEvery(asyncTypes.GET_MESSAGES_ASYNC, callGetMessagesWorker);
    },
    * watchGetTransactions () {
        yield takeEvery(asyncTypes.GET_TRANSACTIONS_ASYNC, callGetTransactionsWorker);
    },
    * watchGetImages () {
        yield takeEvery(asyncTypes.GET_IMAGES_ASYNC, callGetImagesWorker);
    },
    * watchGetImageCategories () {
        yield takeEvery(asyncTypes.GET_IMAGE_CATEGORIES_ASYNC, callGetImageCategoriesWorker);
    },
    * watchGetImagesCarousel  () {
        yield takeEvery(asyncTypes.GET_IMAGES_CAROUSEL_ASYNC, callGetImagesCarouselWorker);
    },
    * watchGetProjects  () {
        yield takeEvery(asyncTypes.GET_PROJECTS_ASYNC, callGetProjectsWorker);
    },
    * watchGetUnreadMessages  () {
        yield takeEvery(asyncTypes.GET_UNREAD_MESSAGES_ASYNC, callGetUnreadMessagesWorker);
    },
    * watchGetOrderList () {
        yield takeEvery(asyncTypes.GET_ORDER_LIST_ASYNC, callGetOrderListWorker);
    },
});