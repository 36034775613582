// Core
import { useState, useRef, useLayoutEffect } from "react";
import { useNavigate } from 'react-router-dom';

// Components
import { ProjectType } from "../../../Manage/ProjectType";

// Hooks
import { useOrder } from "../../../../hooks/useOrder";
import { useUi } from "../../../../hooks/useUi";
import { useProject } from "../../../../hooks/useProject";

// Instruments
import { toLocaleString, isFetching, onOutsideElClick } from '../../../../helpers/helpers';
import { isEmpty } from 'ramda';

export const CartItem = ({ isActive, format:formatList, project }) => {
    /* Ref */
    const actionsListRef = useRef(null);
    const btnOverlayRef = useRef(null);
    const deleteBtnRef = useRef(null);
    // const timer = useRef(null);
    // const interval = useRef(null);

    /* State */
    const [isActionsList, setIsActionsList] = useState(false);
    const [isDeleting] = useState(false);

    /* Hooks */
    const { amount, currency, full_format, topic, format = '', job_type, talent, word_count = 0, project_id } = project;
    const { removeProjectFromOrderAsync } = useOrder();
    const { fetching } = useUi();
    const { fetchProjectDetailsAsync, deleteProjectAsync } = useProject();
    const navigate = useNavigate();

    /* Actions */
    // const clearTimer = () => {
    //     clearTimeout(timer.current);
    //     clearInterval(interval.current);
    //     setIsDeleting(false);
    // };
    const toggleActionsList = () => {
        setIsActionsList(state => !state);
    };
    const onRemoveProject = () => {
        if (!isFetching(fetching)) {
            removeProjectFromOrderAsync(project_id, navigate);
        }
    };
    const onBtnClick = ({ currentTarget: { dataset: { action }}}) => {
        setIsActionsList(false);
        if ( action === 'remove' ) {
            onRemoveProject();
        } else if ( action === 'duplicate' ) {
            fetchProjectDetailsAsync(project_id, 'duplicating', navigate);
        } else if ( action === 'edit' ) {
            fetchProjectDetailsAsync(project_id, 'editing', navigate);
        }
    };
    const onEditProject = (e) => {
        if (!isActive) {
            let target = e.target;

            do {
                if (target === actionsListRef.current) {
                    return;
                }
                target = target.parentNode;
            } while (target);

            fetchProjectDetailsAsync(project_id, 'editing', navigate);
        }
    };
    const onDeleteClick = () => {
        setIsActionsList(false);
        deleteProjectAsync(project_id, 'delete_from_order', null, navigate);
        // if ( isDeleting ) {
        //     clearTimer();
        // } else {
        //     setIsDeleting(true);
        // }
    };

    useLayoutEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,actionsListRef.current,() => { setIsActionsList(false) });
        };

        if ( isActionsList ) {
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isActionsList]);
    // useLayoutEffect(() => {
    //     const onOutsideClick = (e) => {
    //         onOutsideElClick(e,actionsListRef.current,() => { setIsActionsList(false) });
    //     };
    //     const onOutsideDeleteClick = (e) => {
    //         onOutsideElClick(e,deleteBtnRef.current,() => {
    //             setIsActionsList(false)
    //             clearTimer();
    //             deleteProjectAsync(project_id, 'delete_from_order', null, navigate);
    //         });
    //     };
    //
    //     if ( isDeleting ) {
    //         document.addEventListener('click', onOutsideClick, true);
    //         document.addEventListener('touchstart', onOutsideClick, true);
    //
    //         const isBtnStyle = !!btnOverlayRef.current.style;
    //         if ( isBtnStyle ) {
    //             document.addEventListener('click', onOutsideDeleteClick, true);
    //             document.addEventListener('touchstart', onOutsideDeleteClick, true);
    //
    //             deleteBtnRef.current.classList.add('gac-hover');
    //             let start = Date.now();
    //             interval.current = setInterval(() => {
    //                 if ( isNil(btnOverlayRef.current) ) return;
    //                 btnOverlayRef.current.style.width = (100 - ((Date.now()-start)/30)) + '%';
    //             }, 10);
    //             timer.current = setTimeout(
    //                 () => {
    //                     setIsActionsList(false);
    //                     clearTimer();
    //                     deleteProjectAsync(project_id, 'delete_from_order', null, navigate);
    //                 }, 3000
    //             );
    //         } else {
    //             setIsActionsList(false);
    //             deleteProjectAsync(project_id, 'delete_from_order', null, navigate);
    //         }
    //     } else {
    //         document.removeEventListener('click', onOutsideDeleteClick, true);
    //         document.removeEventListener('touchstart', onOutsideDeleteClick, true);
    //         deleteBtnRef.current.classList.remove('gac-hover');
    //         if ( btnOverlayRef.current.style ) {
    //             btnOverlayRef.current.style.width = '100%';
    //         }
    //     }
    //
    //     return () => {
    //         document.removeEventListener('click', onOutsideClick, true);
    //         document.removeEventListener('touchstart', onOutsideClick, true);
    //     };
    // }, [isDeleting]);

    const formatData = formatList.filter((item) => item.value === format);
    const formatTitle = !full_format && !isEmpty(formatData) ? formatData[0].title : '';

    return <div onClick = { onEditProject } className = { `gac-cart-project ${ isActive ? 'gac-active' : '' }` }>
        <div className = 'gac-cart-project-row'>
        <span className = 'gac-cart-project-type'>
            <ProjectType type = { talent === 'bloggers' && job_type === 'writing' ? 'blogging' : job_type } />
            { job_type === 'seoaudit' ? 'SEO audit' : job_type === 'webaudit' ? 'Website audit' : full_format ? full_format : `${formatTitle} ${word_count}w` }
        </span>
            { !!amount && <span className = 'gac-cart-project-amount'>{ `${currency}${toLocaleString(amount)}` }</span> }
        </div>
        <div className = 'gac-cart-project-row'>
            <span className = 'gac-cart-project-topic'>{ topic }</span>
            <span ref = { actionsListRef } className = 'gac-cart-project-more'>
                <i onClick = { toggleActionsList }/>
                <ul style = {{ display: isActionsList ? 'block' : 'none' }}>
                    { !isActive && <li data-action = 'edit' onClick = { onBtnClick }>Edit</li> }
                    <li data-action = 'duplicate' onClick = { onBtnClick }>Duplicate</li>
                    <li data-action = 'remove' onClick = { onBtnClick }>Remove from order</li>
                    <li className = 'gac-more-delete' ref = { deleteBtnRef } onClick = { onDeleteClick }>
                        <div ref = { btnOverlayRef } className = 'gac-btn-overlay'/>
                        <span>{ isDeleting ? 'Undo' : 'Delete' }</span>
                    </li>
                </ul>
            </span>
        </div>
    </div> ;
};