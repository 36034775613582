// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getProjectDetails, authStr } from '../../../../helpers/api';
import { projectActions } from "../../actions";
import { uiActions } from '../../../ui/actions';
import { isNil } from 'ramda';

export function* callReviewProjectWorker ({ payload: { project_id, code }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${isNil(code) ? `${publicKey}/` : '' }v1/projects/${project_id}/review${ isNil(code) ? `?account_uid=${accountId}` : `/${code}` }`,{
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
            }
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const project = yield select(getProjectDetails);
        yield put(projectActions.setProjectState('details', { ...project, 'client_reviewed': true }));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
