// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { includes } from 'ramda';

export function* callMoveProjectWorker ({ payload: { project_id, status, account_uid, account_name, navigate}}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${project_id}/move`,{
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                account_uid
            }),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( includes(status, 'approved/published/cancelled') ) {
            navigate('/completed-projects');
        } else {
            navigate('/current-projects');
        }
        yield put(uiActions.setUiState('notification', {
            msg: `Project moved to ${account_name}`,
            type: 'success',
            fn: null,
        }));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
