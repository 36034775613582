// Core
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';

// Components
import Avatar from '../../common/Avatar/index';
import EmptyStatev2 from '../../common/EmptyStatev2/index';
import { Pitch } from "./Pitch";
import { MessageForm } from "../../MessageForm";

// Icons
import Feather from '../../icons/Feather';
import Case from '../../icons/Case';
import Note from '../../icons/Note';

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { usePrevious } from "../../../hooks/custom/usePrevious";
import { useProject } from "../../../hooks/useProject";
import { useLists } from "../../../hooks/useLists";
import { useUser } from "../../../hooks/useUser";
import { useUi } from "../../../hooks/useUi";

// Instruments
import { getAppTitle, isClientFn, isSelfClientFn, sortMessages, splitBySpace } from "../../../helpers/helpers";
import { isNil, isEmpty, includes } from "ramda";
import moment from 'moment/moment';
import animateScrollTo from 'animated-scroll-to';

export const Messages = ({ company }) => {
    /* Ref */
    const timelineRef = useRef(null);

    /* State */
    const [isForm, setIsForm] = useState(false);
    const [isOpenMsgs, setIsOpenMsgs] = useState(false);
    const [formType, setFormType] = useState('');

    /* Hooks */
    const { keys: { accountId }} = useAuth();
    const { details, pitches, messages, logs, setProjectState, fetchProjectMessagesAsync, fetchProjectPitchesAsync, fetchProjectLogsAsync, awardProjectAsync, createMessageAsync } = useProject();
    const { project_id, creator_id, status, job_type, revision, topic } = details;
    const { messages: msgCenterData, users, setListsState } = useLists();
    const { details: { avatar: user_avatar, user_id, user_role }, fetchUsersAsync } = useUser();
    const { isMobile, shouldOpenMessageForm, scrollToMessage, scrollToMessageId, setUiState } = useUi();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const prevProjectId = usePrevious(projectId);

    let clients = isNil(users) || isEmpty(users) ? [] : users.filter(({user_role: role}) => includes(role, 'basicclient/selfclient'));
    clients = clients.filter(({ accounts = [] }) => accounts && accounts.some(o => o.account_id === accountId));

    /* Actions */
    const callRequests = (id) => {
        const isFetchingDisabled = true;

        fetchUsersAsync();
        fetchProjectMessagesAsync({ project_id: id, isFetchingDisabled });

        if ( !isClientFn(user_role) && !isSelfClientFn(user_role) ) {
            fetchProjectPitchesAsync({ project_id: id, isFetchingDisabled });
            fetchProjectLogsAsync({ project_id: id, isFetchingDisabled });
        }
    };
    const hideDuplicatedLabels = () => {
        if ( !isNil(timelineRef.current) ) {
            const labels = timelineRef.current.querySelectorAll('.gac-time');

            if ( labels.length ) {
                labels.forEach((item, i) => {
                    const time = item.dataset.time;
                    if ( !isNil(labels[i-1]) && labels[i-1].dataset.time === time ) {
                        item.style.display = 'none';
                    }
                });
            }
        }
    };
    const onMessageFormClose = () => {
        setIsForm(false);
        setFormType('');
    };
    const onMessageFormOpen = ({ currentTarget: { dataset: { form }}}) => {
        setIsForm(true);
        setFormType(form);
    };
    const onShowFullAdminMsgs = () => {
        setIsOpenMsgs(true);
    };
    const onPitchApprove = (pitchId) => {
        awardProjectAsync(pitchId);
    };
    const onMessageFormSubmit = (data) => {
        createMessageAsync({ ...data, project_topic: topic });
    };
    const onRequestRevision = () => {
        setUiState('shouldRequestRevision',true);
        navigate(`/project/${project_id}/content`);
    };

    useEffect(() => {
        document.title = getAppTitle('Project messages', company);
        fetchUsersAsync();
        fetchProjectMessagesAsync({ project_id });

        if ( !isClientFn(user_role) && !isSelfClientFn(user_role) ) {
            if ( status !== 'matching' || (job_type === 'design' && status === 'matching' && revision ) ) {
                fetchProjectPitchesAsync({ project_id });
                fetchProjectLogsAsync({ project_id });
            }
        }

        return () => {
            const currentMsgs = msgCenterData.filter(o => `${o.project_id}` === `${project_id}` ).map(o => ({ ...o, status: 'viewed' }));
            const otherMsgs = msgCenterData.filter(o => `${o.project_id}` !== `${project_id}` );

            setProjectState('messages', null);
            setProjectState('pitches', null);
            setProjectState('logs', null);
            setListsState('unreadMessages', null);
            setListsState('messages',sortMessages([...currentMsgs, ...otherMsgs ]));
        };
    }, []);
    useEffect(() => {
        if ( !isNil(pitches) && !isEmpty(pitches) && shouldOpenMessageForm ) {
            setIsForm(true);
            setFormType('message');
        }
    }, [pitches, messages, shouldOpenMessageForm]);
    useEffect(() => {
        if ( !isNil(pitches) && !isEmpty(pitches) && !isNil(messages) && !isEmpty(messages) && scrollToMessage ) {
            const target = document.getElementById(`message-${scrollToMessageId}`);
            if ( !isNil(target) ) {
                animateScrollTo(target, {
                    verticalOffset: -66,
                }).then(() => {
                    setUiState('scrollToMessage',false);
                    setUiState('scrollToMessageId',null);
                });
            }
        }
    }, [pitches, messages, scrollToMessage]);
    useEffect(() => {
        if ( isForm && shouldOpenMessageForm ) {
            animateScrollTo(document.body.offsetHeight, {
                verticalOffset: -300,
            }).then(() =>{
                const textarea = document.getElementById('gac-message-form-textarea');
                if ( !isNil(textarea) ) {
                    textarea.focus();
                }
            });
            setUiState('shouldOpenMessageForm', false);
        }
    }, [isForm]);
    useEffect(() => {
        if ( status === 'writing' && isEmpty(messages) && isEmpty(pitches) ) {
            callRequests(project_id);
        }
    }, [status]);
    useEffect(() => {
        if ( !isNil(prevProjectId) && prevProjectId !== projectId ) {
            callRequests(projectId);

            const currentMsgs = msgCenterData.filter(o => `${o.project_id}` === `${projectId}` ).map(o => ({ ...o, status: 'viewed' }));
            const otherMsgs = msgCenterData.filter(o => `${o.project_id}` !== `${projectId}` );
            setListsState('unreadMessages', null);
            setListsState('messages',sortMessages([...currentMsgs, ...otherMsgs ]));
        }
    }, [projectId]);

    hideDuplicatedLabels();

    const getClientTimeline = () => {
        let dataMessages = isNil(messages) ? [] : messages.filter(o => o.recipient_type === 'client' || o.sender_type === 'client');
        return dataMessages
            .map(({date_added, message_id, files, text, recipient_name, recipient_avatar, sender_uid, sender_name, sender_avatar, notify_user_uid }) => {
                let filesData = [];
                let usersList = [];
                const isSenderUser = user_id === sender_uid;
                if ( files ) {
                    filesData = files.map(({id, filename, url}) => {
                        return(
                            <div key = { id } className = 'gac-message-file'>
                                <i className = { `${ filename.split('.').slice(-1)[0] }` }/>
                                <a target = '_blank' rel = 'noopener noreferrer' className = 'gac-message-file-name' href = { url }>{ filename }</a>
                            </div>
                        );
                    })
                }

                if ( !isNil(notify_user_uid) && !isEmpty(users) && !isClientFn(user_role) && !isSelfClientFn(user_role) ) {
                    usersList = notify_user_uid.map(id => {
                        let avatar_url, first_name, last_name = '';
                        const user = users.filter(o => o.user_id === id)[0];
                        if ( isNil(user) ) {
                            first_name = 'User';
                        } else {
                            ({ avatar_url, first_name, last_name } = user);
                        }

                        return(
                            <div key = { id } className = 'gac-notify-item'>
                                <Avatar url = { avatar_url } name = {[ first_name, last_name ]}/>
                            </div>
                        );
                    });
                }

                return(
                    <div key = { message_id }>
                        <div>
                            <div className = 'gac-time' data-time = { moment(date_added).format('MMM-D') }>
                                { moment(date_added).format('MMM D') }
                            </div>
                            <div id={`message-${message_id}`} className = { `gac-message-block ${ isSenderUser ? 'gac-message-right' : ''}` }>
                                <div className = 'gac-message-wrap'>
                                    <div className = 'gac-message-head'>
                                        { isSenderUser
                                            ? <>
                                                <div className = 'gac-message-head-from'>
                                                    <Avatar url = { sender_avatar } name = {[ splitBySpace(sender_name)[0], splitBySpace(sender_name)[1] ]}/>
                                                </div>
                                                <i className="gac-message-head-arrow"/>
                                                <div className = 'gac-user-meta'>
                                                    <i className = { `gac-user-meta-icon gac-client ` }/>
                                                    { recipient_avatar ? <img className="gac-user-meta-img" src={ recipient_avatar } alt="Avatar"/> : <div className = 'gac-no-user-img'/> }
                                                    <div className = 'gac-user-meta-name'>{ recipient_name }.</div>
                                                </div>
                                            </>
                                            : <div className = 'gac-user-meta'>
                                                { sender_avatar ? <img className="gac-user-meta-img" src={ sender_avatar } alt="Avatar"/> : <div className = 'gac-no-user-img'/> }
                                                <div className = 'gac-user-meta-name'>{ sender_name }.</div>
                                            </div> }
                                    </div>
                                    <div className='gac-message-wrap-2'>
                                        <div className = 'gac-message-content'>
                                            <div className = 'gac-message-content-wrap'>{ text }</div>
                                            <div className = 'gac-message-time'>{ moment(date_added).format('h:mm A') }</div>
                                        </div>
                                        { filesData.length ? <div className = 'gac-message-files'>{ filesData }</div> : null }
                                        { usersList.length ? <div className = 'gac-notify-list'>{ usersList }</div> : null }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    };
    const getClientMsgsHtml = (type) => {
        let notifyList = [];
        let sender_type = 'client';
        let recipient_type = 'user';
        let recipient_uid = creator_id;
        let recipient_clients = null;
        let dataForm = 'client-user';
        let btnLabel = 'Message';

        if ( !isNil(users) && !isEmpty(users) ) {
            notifyList = users
                .filter(({user_role: role}) => !includes(role, 'basicclient/selfclient'))
                .filter(({ accounts = [], user_id: id }) => id !== user_id && accounts && accounts.some(o => o.account_id === accountId))
                .map(({ avatar_url, user_id, first_name, last_name }) => ({ avatar_url, user_id, first_name, last_name }));
        }
        if ( type === 'user' ) {
            recipient_clients = users.filter(({user_role: role}) => includes(role, 'basicclient/selfclient'))
                .filter(({ accounts = [] }) => accounts && accounts.some(o => o.account_id === accountId))
                .map(({ avatar_url, user_id, first_name, last_name }) => ({ avatar_url, user_id, first_name, last_name }));
            sender_type = 'user';
            recipient_type = 'client';
            recipient_uid = recipient_clients.map(({ user_id }) => user_id)[0];
            dataForm = 'user-client';
            btnLabel = 'Message client';
        }

        /* Html */
        const getContent = () => {
            const msgs = getClientTimeline();
            if ( isEmpty(msgs) ) return <EmptyStatev2 type = 'type-4'/> ;

            return <div ref = { timelineRef } className = 'gac-timeline'>
                { msgs }
            </div> ;
        };
        const getForm = () => {
            if ( !isForm ) return null;

            return <MessageForm
                data = {{ project_id, job_type, sender_type, sender_uid: user_id, recipient_type, recipient_uid, recipient_clients, notify_user_uid: notifyList }}
                type = { formType }
                onClose = { onMessageFormClose }
                onSubmit = { onMessageFormSubmit } />;
        };
        const getBtn = () => {
            if ( isForm ) return null;

            return <div className = 'gac-project-btns'>
                <div onClick = { onMessageFormOpen } data-form = { dataForm } className = 'gac-btn-v3'><i className="gac-icon-2"/><span>{ btnLabel }</span></div>
            </div> ;
        };

        return <div className = { `gac-project-content gac-messages-content ${ job_type === 'design' ? 'gac-job-type-design' : '' }`}>
            <div className = 'gac-message-client'>
                { !isMobile && <div className = 'gac-project-title'>{ topic }</div> }
                { getContent() }
                { getForm() }
                { getBtn() }
            </div>
        </div> ;
    };

    if ( isClientFn(user_role) || isSelfClientFn(user_role) ) {
        return getClientMsgsHtml('client');
    }

    if ( (status === 'matching' || status === 'pitching') && isEmpty(pitches) && !isEmpty(clients) ) {
        return getClientMsgsHtml('user');
    }

    const getAdminMsgsHtml = () => {
        let notifyList = [];

        if ( !isNil(users) && !isEmpty(users) ) {
            notifyList = users.filter(({ accounts = [], user_id: id }) => id !== user_id && accounts && accounts.some(o => o.account_id === accountId))
                .map(({ avatar_url, user_id, first_name, last_name }) => ({ avatar_url, user_id, first_name, last_name }));
        }

        let dataMessages = isNil(messages) ? [] : messages.filter(o => o.recipient_type === 'admin' || o.sender_type === 'admin');
        if ( !isOpenMsgs ) { dataMessages = dataMessages.filter((o,i) => i >= dataMessages.length - 10); }
        return dataMessages
            .map(({date_added, message_id, files, recipient_type, sender_type, text, notify_user_uid}, i) => {
                let filesData = [];
                let usersList = [];
                let admin_avatar = '';
                let admin_first_name = 'Support';
                let admin_last_name = '';
                const isSenderUser = sender_type === 'user';
                const isRecipientAdmin = recipient_type === 'admin';
                if ( files ) {
                    filesData = files.map(({id, filename, url}) => {
                        return(
                            <div key = { id } className = 'gac-message-file'>
                                <i className = { `${ filename.split('.').slice(-1)[0] }` }/>
                                <a target = '_blank' rel = 'noopener noreferrer' className = 'gac-message-file-name' href = { url }>{ filename }</a>
                            </div>
                        );
                    })
                }
                if ( notify_user_uid ) {
                    usersList = notify_user_uid.map(id => {
                        const { avatar_url, first_name, last_name } = notifyList.filter(o => o.user_id === id)[0];
                        return(
                            <div key = { id } className = 'gac-notify-item'>
                                <Avatar url = { avatar_url } name = {[ first_name, last_name ]}/>
                            </div>
                        );
                    });
                }

                return(
                    <div key = { message_id }>
                        <div>
                            <div className = 'gac-time' data-time = { moment(date_added).format('MMM-D') }>
                                { moment(date_added).format('MMM D') }
                            </div>
                            <div id = {`message-${message_id}`} className = { `gac-message-block ${ isRecipientAdmin ? 'gac-message-right' : ''}` }>
                                <div className = 'gac-message-wrap'>
                                    <div className = 'gac-message-head'>
                                        { isSenderUser && <>
                                            <div className = 'gac-message-head-from'>
                                                { user_avatar ? <img src={ user_avatar } alt = 'User avatar'/> : <div className = 'gac-no-user-img'/> }
                                            </div>
                                            <i className="gac-message-head-arrow"/></> }
                                        <div className = 'gac-user-meta'>
                                            <i className = { `gac-user-meta-icon ${ isRecipientAdmin ? 'gac-writer' : 'gac-client' }` }/>
                                            { admin_avatar ? <img className="gac-user-meta-img" src={ admin_avatar } alt="Admin avatar"/> : <div className = 'gac-no-user-img'/> }
                                            <div className = 'gac-user-meta-name'>{`${admin_first_name} ${admin_last_name}`}</div>
                                        </div>
                                    </div>
                                    <div className='gac-message-wrap-2'>
                                        <div className = 'gac-message-content'>
                                            <div className = 'gac-message-content-wrap'>{ text }</div>
                                            <div className = 'gac-message-time'>{ moment(date_added).format('h:mm A') }</div>
                                        </div>
                                        { filesData.length ? <div className = 'gac-message-files'>{ filesData }</div> : null }
                                        { usersList.length ? <div className = 'gac-notify-list'>{ usersList }</div> : null }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
    };
    const getPitches = (pitches, isPitchAccepted, id) => {
        let notifyList = [];

        if ( !isNil(users) && !isEmpty(users) ) {
            notifyList = users.filter(({user_role: role}) => !includes(role, 'basicclient/selfclient'))
                .filter(({ accounts = [] }) => accounts && accounts.some(o => o.account_id === accountId))
                .map(({ avatar_url, user_id, first_name, last_name }) => ({ avatar_url, user_id, first_name, last_name }));
        }

        return pitches.map(pitch => {
            const { copywriter_id, writer_avatar_url, first_name, last_name_initial, pitch_id, pitch_status } = pitch;
            const messagesData = isNil(messages) ? [] : messages.filter(o =>
                (!isNil(id) && isNil(o.recipient_type) && isNil(o.recipient_uid))
                || o.recipient_uid === copywriter_id
                || o.sender_uid === copywriter_id
                || o.sender_type === 'client'
                || o.recipient_type === 'client'
                || ( !isNil(id) && pitch_status === 'ACCEPTED' && o.sender_type === 'admin'));

            return <Pitch
                key = { pitch_id }
                data = {{
                    project_id,
                    job_type,
                    user_id,
                    user_avatar,
                    copywriter_id,
                    writer_avatar_url,
                    writer_first_name: first_name,
                    writer_last_name: last_name_initial,
                    users: isNil(users) ? [] : users,
                    isPitchAccepted,
                    notifyList,
                    messages: messagesData,
                    logs,
                    pitch
                }}
                approvePitch = { onPitchApprove }
                onMessageFormSubmit = { onMessageFormSubmit }/> ;
        });
    };

    let pitchesData = [];
    let acceptedPitches = [];
    let activePitch;
    let writer_avatar_url = '';
    let first_name = '';
    let last_name_initial = '';
    let isPitchAccepted = false;
    let notifyList = [];
    let notifyClientsList = [];
    const adminMsgs = getAdminMsgsHtml();

    const isTypeMessage = formType === 'message';
    const isTypeUserClient = formType === 'user-client';


    if ( !isNil(users) && !isEmpty(users) ) {
        notifyList = users.filter(({user_role: role}) => !includes(role, 'basicclient/selfclient'))
            .filter(({ accounts = [], user_id: id }) => id !== user_id && accounts && accounts.some(o => o.account_id === accountId))
            .map(({ avatar_url, user_id, first_name, last_name }) => ({ avatar_url, user_id, first_name, last_name }));
        if ( isTypeUserClient ) {
            notifyClientsList = clients
                .map(({ avatar_url, user_id, first_name, last_name }) => ({ avatar_url, user_id, first_name, last_name }));
        }
    }
    if ( !isEmpty(pitches) && !isNil(pitches) && pitches.some(o => o.pitch_status === 'ACCEPTED') && status !== 'pitching' ) { isPitchAccepted = true; }
    if ( !isEmpty(pitches) && !isNil(pitches) ) {
        if ( isPitchAccepted ) {
            pitchesData = pitches.filter(o => o.pitch_status !== 'ACCEPTED');
            activePitch = pitches.filter(o => o.pitch_status === 'ACCEPTED');
            ({ writer_avatar_url, first_name, last_name_initial } = activePitch[0]);
            acceptedPitches = getPitches(activePitch, true, activePitch[0].pitch_id);
        } else {
            pitchesData = pitches;
        }
        pitchesData = getPitches(pitchesData, isPitchAccepted);
    }

    return <div className = {`gac-project-content gac-messages-content ${ job_type === 'design' ? 'gac-job-type-design' : '' }`}>
        { status === 'matching' && ( job_type !== 'design' || !revision )
            ? <div className = 'gac-messages-empty'>
                { !isMobile && <div className = 'gac-project-title'>{ topic }</div> }
                <EmptyStatev2 status = {status} type = 'type-1'/>
            </div>
            : <>{ !isNil(pitches)
                ? <>{ status === 'pitching' && !pitches.length
                    ? <div className = 'gac-messages-empty'>
                        { !isMobile && <div className = 'gac-project-title'>{ topic }</div> }
                        <EmptyStatev2 type = 'type-2'/>
                    </div>
                    :<>
                        <div className = 'gac-message-client'>
                            { !isMobile && <div className = 'gac-project-title'>{ topic }</div> }
                            { !isEmpty(adminMsgs) && !acceptedPitches.length
                                ? <div ref = { timelineRef } className = 'gac-timeline'>
                                    { !isOpenMsgs && isNil(messages) ? [] : messages.filter(o => o.recipient_type === 'admin' || o.sender_type === 'admin').length > 10 && <div className = 'gac-showmore' onClick = { onShowFullAdminMsgs }/> }
                                    { adminMsgs }
                                </div> : null }
                            { acceptedPitches.length
                                ? <>
                                    <div className = 'gac-pitches'>{ acceptedPitches }</div>
                                    { isForm
                                        ? <MessageForm
                                            data = {{
                                                project_id,
                                                job_type,
                                                sender_type: 'user',
                                                sender_uid: user_id,
                                                recipient_type: isTypeMessage ? 'writer' : isTypeUserClient ? 'client' : null,
                                                recipient_clients: isTypeUserClient ? notifyClientsList : null,
                                                recipient_uid: isTypeMessage ? activePitch[0].copywriter_id : isTypeUserClient ? notifyClientsList.map(({ user_id }) => user_id)[0] : null,
                                                recipient_ava: isTypeMessage ? writer_avatar_url : null,
                                                recipient_name: isTypeMessage ? `${first_name} ${last_name_initial}` : null,
                                                notify_user_uid: notifyList,
                                            }}
                                            type = { formType }
                                            onClose = { onMessageFormClose }
                                            onSubmit = { onMessageFormSubmit } />
                                        :  <div className = 'gac-project-btns'>
                                            { status === 'approval' && <div className = 'gac-btn gac-btn-s' onClick = { onRequestRevision }><span>Request revision</span></div> }
                                            <div onClick = { onMessageFormOpen } data-form = 'message' className = 'gac-btn-v3'><i className='gac-svg'><Feather/></i><span>Message { includes(job_type, 'design/motion') ? 'designer' : 'writer' }</span></div>
                                            { !isEmpty(clients) && <div onClick = { onMessageFormOpen } data-form = 'user-client' className = 'gac-btn-v3'><i className='gac-svg'><Case/></i><span>Message client</span></div> }
                                            <div onClick = { onMessageFormOpen } data-form = 'note' className = 'gac-btn-v3'><i className='gac-svg'><Note/></i><span>Add internal note</span></div>
                                        </div> }
                                </>
                                : null }
                        </div>
                        { pitchesData.length ? <div className = 'gac-pitches'>{ pitchesData }</div> : null }
                    </> }
                </>
                : null }
            </> }
    </div> ;
};