// Instruments
import { isNil } from "ramda";

export const ProjectType = ({ type = 'writing', id }) => {
    const config = {
        'motion': { color: '#8e44ad', label: 'Motion', char: 'M' },
        'aih': { color: '#3a76db', label: 'AI+Human', char: 'A' },
        'design': { color: '#6F6F7A', label: 'Design', char: 'D' },
        'proofreading': { color: '#43B993', label: 'Proofreading', char: 'P' },
        'ideas': { color: '#f4d288', label: 'Topic ideas', char: 'I' },
        'keywords': { color: '#f78b8a', label: 'Keyword research', char: 'K' },
        'blogging': { color: '#8c91cc', label: 'Blogging', char: 'B' },
        'writing': { color: '#8c91cc', label: 'Writing', char: 'W' },
        'webaudit': { color: '#0998c1', label: 'Website audit', char: 'A' },
        'seoaudit': { color: '#FF9F00', label: 'SEO audit', char: 'S' },
    };

    return <div className = 'gac-project-type' style = {{ backgroundColor: config[type].color }}>
        { !isNil(id) && <i>{ id }</i> }
        { config[type].char }
        <div className = 'gac-type-name'>{ config[type].label }</div>
    </div> ;
};