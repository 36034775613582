// Core
import { call, put } from 'redux-saga/effects';

// Instruments
import { api, favicon as gacFavicon, epiicFavicon, isAppDev, isAppQa, isAppRa, isAppVi, isAppYv } from '../../../../helpers/api';
import { changeFavicon, isProd, isWhiteLabel, isEpiic, loadThemeCss } from '../../../../helpers/helpers';
import { authActions } from '../../../auth/actions';
import { projectActions } from '../../actions';
import { userActions } from '../../../user/actions';
import { uiActions } from '../../../ui/actions';
import { isNil } from 'ramda';

export function* callFetchProjectDetailsByCodeWorker ({ payload: { id, code, /*action*/ }}) {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(fetch, `${api}/v1/projects/${id}/${code}`, { method: 'GET' });
        const { response: data, error: { message }} = yield call([response, response.json]);
        const { logged_user, /*account_uid*/ } = data;
        const { favicon, header_logo, brand_color } = logged_user;

        if (response.status !== 200) {
            throw new Error(message);
        }
        yield put(authActions.loginFakeUserSuccess({
            privateKey: logged_user._gac_prk,
            publicKey: logged_user._gac_pbk,
            accountId: logged_user._gac_acc,
            sessid: logged_user._gac_sessid,
        }));
        yield put(userActions.setUserCompanyState('branding', { favicon, header_logo, brand_color }));
        changeFavicon(
            isProd || isAppDev || isAppQa || isAppYv || isAppVi || isAppRa
                ? isEpiic(null)
                    ? epiicFavicon
                    : gacFavicon
                : isWhiteLabel()
                    && !isNil(favicon)
                    && !isNil(favicon.url)
                    ? favicon.url
                    : null );
        if ( !isNil(brand_color) ) {
            loadThemeCss(process.env.PUBLIC_URL, brand_color);
        }
        yield put(projectActions.setProjectState('details', data));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
