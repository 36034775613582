export const types = Object.freeze({
    // auth
    AUTHENTICATE:         'AUTHENTICATE',
    AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',
    AUTHENTICATE_FAIL:    'AUTHENTICATE_FAIL',

    // login
    LOGIN:         'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAKE_USER_SUCCESS: 'LOGIN_FAKE_USER_SUCCESS',
    LOGIN_FAIL:    'LOGIN_FAIL',

    // logout
    LOGOUT:         'LOGOUT',
    CLEAR_COOKIES:  'CLEAR_COOKIES',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAIL:    'LOGOUT_FAIL',

    // account
    SET_ACCOUNT: 'SET_ACCOUNT',
});
