// Core
import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import { func, string } from 'prop-types';
import cx from 'classnames';

export default class FilterItemSize extends PureComponent {
    static propTypes = {
        onBlur:    func.isRequired,
        onChange:  func.isRequired,
        minWidth:  string.isRequired,
        minHeight: string.isRequired,
    };

    state = {
        isOpened: false,
    };

    componentWillUnmount() {
        document.removeEventListener('click', this._onOutsideListClick);
        document.removeEventListener('touchstart', this._onOutsideListClick);
    }

    _onOutsideListClick = (e) => {
        let target = e.target;

        do {
            if (target === this.item) {
                return;
            }
            target = target.parentNode;
        } while (target);

        this.setState({
            isOpened: false,
        });
    };
    _toggleList = () => {
        const { isOpened } = this.state;

        this.setState({
            isOpened: !isOpened,
        }, () => {
            if ( isOpened ) {
                document.removeEventListener('click', this._onOutsideListClick);
                document.removeEventListener('touchstart', this._onOutsideListClick);
            } else {
                document.addEventListener('click', this._onOutsideListClick);
                document.addEventListener('touchstart', this._onOutsideListClick);
            }
        });
    };

    _onBlur = ({ target: { name }}) => {
        const { onBlur, minWidth, minHeight } = this.props;
        switch (name) {
            case 'min_width':
                onBlur('min_width', minWidth, '', true);
                break;
            default:
                onBlur('min_height', minHeight, '', true);
                break;
        }
    };

    _onChange = ({ target: { name, value }}) => {
        const { onChange } = this.props;
        onChange(name, value);
    };

    render () {
        const { direction, minWidth, minHeight } = this.props;
        const { isOpened } = this.state;
        const styles = cx('gac-filter-item', {
            'gac-clicked': isOpened,
        });

        return (
            <div className = { styles } ref = {ref => this.item = ref }>
                <i className = 'gac-filter-item__arrow'/>
                <div className = 'gac-filter-item__value' onClick = { this._toggleList }>
                    { minWidth !== '' && minHeight !== ''
                        ? `${minWidth} X ${minHeight}`
                        : minWidth !== '' && minHeight === ''
                            ? `${minWidth} X *`
                            : minWidth === '' && minHeight !== ''
                                ? `* X ${minHeight}`
                                : 'Any size' }
                </div>
                <CSSTransition
                    in = { isOpened }
                    timeout = { 200 }
                    unmountOnExit
                    classNames = 'gac-opacity-200'>
                    <div
                        style = {{
                            top: direction ? 'auto' : 55,
                            bottom: direction ? 46 : 'auto',
                        }}
                        className = 'gac-filter-item__menu gac-filter-item__menu-inputs'>
                        <p>Larger than</p>
                        <div className = 'gac-filter-item__inputs'>
                            <input
                                name = 'min_width'
                                placeholder = 'Width'
                                type = 'number'
                                data-hj-whitelist
                                value = { minWidth }
                                onBlur = { this._onBlur }
                                onChange = { this._onChange }/>
                            <span className = 'gac-filter-item__x'>X</span>
                            <input
                                name = 'min_height'
                                placeholder = 'Height'
                                type = 'number'
                                data-hj-whitelist
                                value = { minHeight }
                                onBlur = { this._onBlur }
                                onChange = { this._onChange }/>
                            <span className = 'gac-filter-item__px'>px</span>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}
