// Core
// import React from 'react';

// Instruments
// import { history } from "../../../init/store";
// import blackFridayImg from "../../../theme/images/black_friday_discount_header.png";

export const BlackFridayImage = () => {
    // const onBlackFridayImgClick = () => {
    //     history.push('/designers/pricing');
    // };

    return null;

    // return <div className="gac-black-friday-img">
    //     <img src = { blackFridayImg } alt="Black friday" onClick = { onBlackFridayImgClick }/>
    // </div> ;
};