// Core
import { call, put } from 'redux-saga/effects';

// Instruments
import { uiActions } from '../../../ui/actions';
import { isEmpty } from "ramda";

export function* callValidateCnameWorker ({ payload: { domain }}) {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(
            fetch,
            `https://www.whoisxmlapi.com/whoisserver/DNSService?apiKey=at_5AEzVBud9PNQM5pDMXwm1JawQf0oe&domainName=${domain}&type=1&outputFormat=JSON`,{
            method: 'GET',
        });
        let { DNSData: { dnsRecords }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error('');
        }
        yield put( uiActions.setUiState('isCnameValid', !isEmpty(dnsRecords) && dnsRecords.some(o => o.name === 'whitelabel.qwriter.com.' || o.name === 'whitelabel-dev.qwriter.com.')));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
