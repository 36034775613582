import 'whatwg-fetch';
// Core
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

// Containers
import { App } from './containers/App';

// Instruments
import * as Sentry from "@sentry/react";
import { isStage } from "./helpers/api";
import { store, history } from './init/store';

if(!isStage) {
    Sentry.init({
        dsn: "https://2d00f5afc4824beb95daf75b4dca06b5@sentry.io/1386233"
    });
}
ReactDOM.render(
    <Provider store = { store }>
        <BrowserRouter history = { history }>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
