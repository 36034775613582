// Core
import { call, put, select  } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { projectActions } from '../../actions';
import { getErrorMsg } from '../../../../helpers/helpers';
import { isNil, includes } from 'ramda';

export function* callCreateCommentWorker ({ payload: { project_id, job_type, commentHtml, commentText, field, idea_number, revision_id, fileId, horizontal, vertical, preview_width, attachment }}) {
    try {
        yield put(uiActions.enableFetching());
        const url = isNil(idea_number) ? '' : `/${idea_number}`;
        const { privateKey, publicKey } = yield select(getKeys);
        let obj = {
            commentText,
        };
        if ( includes(job_type, 'design/motion') ) {
            obj = {
                ...obj,
                fileId,
                horizontal,
                vertical,
                preview_width,
                attachment
            };
        } else {
            obj = {
                ...obj,
                commentHtml,
                field,
            };
        }
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${job_type}/${project_id}/comments${url}`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(obj),
        });
        const { response: commentData, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(getErrorMsg(message));
        }

        yield put(projectActions.createCommentSuccess(commentData));
        if ( job_type === 'ideas' ) {
            const { html_content } = commentData;
            yield put(projectActions.updateIdeaRevisionField(field, html_content, idea_number, revision_id));
        } else if ( job_type === 'design' ) {} else {
            const { html_content } = commentData;
            yield put(projectActions.updateProjectRevision(html_content));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
