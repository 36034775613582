// Core
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Instruments
import animateScrollTo from "animated-scroll-to";
import { includes, isNil } from "ramda";

export const ScrollUpBtn = () => {
    const { pathname } = useLocation();
    const btnEl = useRef(null);
    const onScroll = () => {
        if ( !isNil(btnEl.current) ) {
            const y =  window.scrollY;
            const h =  window.innerHeight;
            if ( y > h ) {
                btnEl.current.style.visibility = 'visible';
                btnEl.current.style.opacity = 1;
            } else {
                btnEl.current.style.visibility = 'hidden';
                btnEl.current.style.opacity = 0;
            }
        }
    };
    const onClick = () => {
        animateScrollTo(0,{
            speed: 400,
        });
    };

    /* Did mount */
    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        /* Will unmount */
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    if ( includes('/project', pathname) && ( includes('/messages', pathname) || includes('/content', pathname))) {
        return <div className="gac-scroll-up-btn" onClick = { onClick } ref={ btnEl } style={{ visibility: 'hidden', opacity: 0, transition: 'opacity .3s ease-in-out' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" xmlSpace="preserve"><circle fill="#59C69F" cx="24" cy="24" r="24"/><path fill="#FFF" d="m23 21.9-2.4 2.8c-.4.4-1 .4-1.3 0-.4-.4-.4-1.2 0-1.6l3.9-4.6c.2-.3.5-.5.9-.5s.7.2.9.5l3.9 4.6c.4.4.4 1.2 0 1.6-.4.4-1 .4-1.3 0L25 21.9v13c0 .6-.4 1.1-1 1.1s-1-.5-1-1.1v-13zM17 12h14c.6 0 1 .4 1 1s-.4 1-1 1H17c-.6 0-1-.4-1-1s.4-1 1-1z"/></svg>
        </div>;
    }

    return null;
};