// Core
import update from 'immutability-helper';

// Instruments
import { types } from './types';
import { types as authTypes } from '../auth/types';
import { isNil } from "ramda";

const initialState = {
    isInit:                   false,
    isAuth:                   false,
    fetching:                 0,
    isBlocking:               false,
    isWhiteLabel:             false,
    isFakeUser:               false,
    imagesTotal:              0,
    projectsTotal:            0,
    projectsDesignTotal:      null,
    isEmailExist:             null,
    isLoginFail:              false,
    isPasswordRestored:       null,
    isPasswordUpdated:        null,
    passwordRestoreError:     '',
    passwordUpdatingError:    '',
    loginError:               '',
    loginEmail:               '',
    createAccountPhone:       '',
    createAccountFullName:    '',
    loginAttempt:             0,
    isTopicUnique:            true,
    isUserLoaded:             false,
    isCompanyLoaded:          false,
    isMobile:                 false,
    isMobileLogo:             false, // black friday banner in header = 1200
    isAccountCreated:         false,
    isUserCreated:            false,
    isProposalCompanyUpdated: false,
    isCheckedEmailProposal:   {},
    isCnameValid:             null,
    isReferralCodeValid:      null,
    scrollToMessage:          false,
    scrollToMessageId:        null,
    shouldOpenMessageForm:    false,
    shouldEditBrief:          false,
    shouldRequestRevision:    false,
    showLoginDialog:          false,
    isEmptyWriters:           false,
    isBraintreePaymentFailed: false,
    isMaintenance:            false,
    isMsgCenter:              false,
    isMoreMessages:           false,
    isBundlesBraintreePaymentFailed: false,
    isWritingSubBraintreePaymentFailed: false,
    reference:                '',
    clientLogo:               null,
    bundlesRef:               null,
    initPath:                 '',
    initSearch:               '',
    brief_links_shared:       'yes',
    notification:             {
        msg:  null,
        type: null,
        fn:   null
    },
};

export const uiReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.INITIALIZE_SUCCESS:
            return update(state, {
                isInit: {$set: true},
            });

        case types.ENABLE_FETCHING:
            return update(state, {
                fetching: {$set: state.fetching + 1},
            });

        case types.DISABLE_FETCHING:
            return update(state, {
                fetching: {$set: state.fetching < 0 ? 0 : state.fetching - 1},
            });

        case types.SET_UI_STATE:
            return update(state, {
                [payload.prop]: {$set: payload.value},
            });

        case types.LOCATION_CHANGE:
            return isNil(state.notification.type)
                ? state
                : update(state, {
                    notification: {$set: { msg: null, type: null, fn: null }},
                });

        case authTypes.LOGIN_SUCCESS:
        case authTypes.AUTHENTICATE_SUCCESS:
            return update(state, {
                isAuth: {$set: true},
            });

        case authTypes.LOGIN_FAKE_USER_SUCCESS:
            return update(state, {
                isFakeUser: {$set: true},
            });

        case authTypes.LOGOUT_SUCCESS:
            return update(state, {
                isUserLoaded: {$set: false},
                isAuth: {$set: false},
                isFakeUser: {$set: false},
            });

        default:
            return state;
    }
};
