// Core
import { call, put, select } from 'redux-saga/effects';
import update from "immutability-helper";

// Instruments
import { projectActions } from "../../actions";
import { listsActions } from "../../../lists/actions";
import { listsActionsAsync } from "../../../lists/saga/asyncActions";
import { uiActions } from '../../../ui/actions';
import { api, authStr, getKeys, getProjects, getIsMobile } from '../../../../helpers/api';
import moment from "moment/moment";

export function* callDeleteProjectWorker ({ payload: { project_id, action, idea_number, navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects?account_uid=${accountId}`,{
            method: 'DELETE',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                project_id
            }),
        });
        const { error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( action === 'delete_from_order' ) {
            yield put(listsActionsAsync.getOrderListAsync(navigate));
        } else if ( action === 'delete_from_idea' ) {
            yield put(projectActions.updateIdeaField('ordered_project_id', null, idea_number));
            yield put(projectActions.updateIdeaField('ordered_project_date', null, idea_number));
            yield put(projectActions.updateIdeaField('ordered_project_status', null, idea_number));
            yield put(listsActionsAsync.getOrderListAsync(navigate));
        } else {
            const projects = yield select(getProjects);
            const isMobile = yield select(getIsMobile);
            let data;
            if ( isMobile ) {
                let orderOn = '';
                 projects.forEach(o => {
                     if ( o.data.map(a => a.id).includes(`${project_id}`) ) {
                         orderOn = o.date;
                     }
                 });
                const index = projects.map(o => o.date).indexOf(moment(orderOn).format('YYYY-MM-DD'));

                data = update(projects, {[index]: {data: {$set: projects[index].data.filter(o => `${o.id}` !== `${project_id}`)}}});
            } else {
                data = projects.filter(o => `${o.project_id}` !== `${project_id}`);
            }
            yield put(listsActions.setListsState('projects', data));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    } finally {
        yield put(uiActions.disableFetching());
    }
}
