// Core
import { useEffect } from "react";

// Components
import { ImagesLibrary } from "../ImagesLibrary";

// Hooks
import { useUser } from "../../hooks/useUser";

// Instruments
import { getAppTitle } from "../../helpers/helpers";

export const ImagesPage = () => {
    /* Hooks */
    const { company } = useUser();

    /* Did mount */
    useEffect(() => {
        document.title = getAppTitle('Free images', company);
    }, []);

    return <ImagesLibrary type = 'page' />;
};

//  { isMobile ? <HeaderMobile page = 'Free images'/> }