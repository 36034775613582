// Core
import { all } from 'redux-saga/effects';

//Instruments
import { modalsWatchers } from '../bus/modals/saga';
import { projectWatchers } from '../bus/project/saga';
import { userWatchers } from '../bus/user/saga';
import { orderWatchers } from '../bus/order/saga';
import { uiWatchers } from '../bus/ui/saga';
import { paymentsWatchers } from '../bus/payments/saga';
import { authWatchers } from '../bus/auth/saga';
import { socketWatchers } from '../bus/socket/saga';
import { listsWatchers } from '../bus/lists/saga';

export function* rootSaga () {
    yield all([
        uiWatchers.watchInitialize(),
        uiWatchers.watchPing(),
        uiWatchers.watchGetVersion(),
        uiWatchers.watchUploadBase64(),
        uiWatchers.watchUploadFile(),
        uiWatchers.watchUploadFileFromUrl(),
        uiWatchers.watchErrorReport(),
        uiWatchers.watchCheckBriefLinksShared(),
        uiWatchers.watchValidateReferralCode(),
        uiWatchers.watchGaTrackingDebug(),

        authWatchers.watchAuthenticate(),
        authWatchers.watchLogin(),
        authWatchers.watchLogout(),
        authWatchers.watchClearCookies(),

        modalsWatchers.watchCreateSupportMessage(),
        modalsWatchers.watchCreateProjectInModal(),

        projectWatchers.watchFetchProjectTags(),
        projectWatchers.watchFetchProjectRevisions(),
        projectWatchers.watchFetchProjectRevision(),
        projectWatchers.watchFetchProjectPitches(),
        projectWatchers.watchFetchProjectLogs(),
        projectWatchers.watchFetchProjectMessages(),
        projectWatchers.watchFetchProjectComments(),
        projectWatchers.watchFetchProjectDetails(),
        projectWatchers.watchFetchProjectDetailsByCode(),
        projectWatchers.watchCreateTag(),
        projectWatchers.watchCreateMessage(),
        projectWatchers.watchCreateComment(),
        projectWatchers.watchUpdateComment(),
        projectWatchers.watchDeleteComment(),
        projectWatchers.watchRequestRevision(),
        projectWatchers.watchUpdateRevision(),
        projectWatchers.watchFetchRevisionDiff(),
        projectWatchers.watchDownloadProjectContent(),
        projectWatchers.watchCheckTopicUnique(),
        projectWatchers.watchUpdateIdeasRevision(),
        projectWatchers.watchCancelProject(),
        projectWatchers.watchChangeProjectQueue(),
        projectWatchers.watchDeleteProject(),
        projectWatchers.watchApproveProject(),
        projectWatchers.watchExtendProjectDeadline(),
        projectWatchers.watchUpdateProject(),
        projectWatchers.watchMoveProject(),
        projectWatchers.watchAwardProject(),
        projectWatchers.watchReviewProject(),
        projectWatchers.watchPublishProject(),
        projectWatchers.watchSetEditingProject(),
        projectWatchers.watchCreateProject(),
        projectWatchers.watchConfirmProject(),
        projectWatchers.watchSetIdeaStatus(),
        projectWatchers.watchGetProjectsDesignCount(),
        projectWatchers.watchGetOriginalityRevisionResults(),

        listsWatchers.watchGetExistingIdeas(),
        listsWatchers.watchGetAccountsWithoutSubs(),
        listsWatchers.watchGetAccountsNoWritingSubs(),
        listsWatchers.watchGetQueuedProjects(),
        listsWatchers.watchGetStaticData(),
        listsWatchers.watchGetMessages(),
        listsWatchers.watchGetTransactions(),
        listsWatchers.watchGetImages(),
        listsWatchers.watchGetImageCategories(),
        listsWatchers.watchGetImagesCarousel(),
        listsWatchers.watchGetProjects(),
        listsWatchers.watchGetUnreadMessages(),
        listsWatchers.watchGetOrderList(),

        userWatchers.watchFetchUserDetails(),
        userWatchers.watchFetchTalents(),
        userWatchers.watchFetchTalentsBlacklist(),
        userWatchers.watchFetchCopywritersList(),
        userWatchers.watchUpdateTalent(),
        userWatchers.watchFetchBalance(),
        userWatchers.watchCreateCompany(),
        userWatchers.watchFetchCompany(),
        userWatchers.watchCreateAccount(),
        userWatchers.watchDeleteAccount(),
        userWatchers.watchCheckEmailExist(),
        userWatchers.watchRestorePassword(),
        userWatchers.watchUpdatePassword(),
        userWatchers.watchSendProspect(),
        userWatchers.watchFetchDataByIp(),
        userWatchers.watchFetchAccount(),
        userWatchers.watchFetchAccounts(),
        userWatchers.watchUpdateAccount(),
        userWatchers.watchFetchUsers(),
        userWatchers.watchAddUserToAccount(),
        userWatchers.watchRemoveUserFromAccount(),
        userWatchers.watchCreateUser(),
        userWatchers.watchUpdateUser(),
        userWatchers.watchDeleteUser(),
        userWatchers.watchUpdateOwnPassword(),
        userWatchers.watchUpdateCompany(),
        userWatchers.watchUpdateProposalCompany(),
        userWatchers.watchFetchProposal(),
        userWatchers.watchGetReferralId(),
        userWatchers.watchGetReferralInfo(),
        userWatchers.watchSendNps(),
        userWatchers.watchCreateSubscription(),
        userWatchers.watchUpdateSubscription(),
        userWatchers.watchUpdateSubscriptionCustom(),
        userWatchers.watchCancelSubscription(),
        userWatchers.watchPauseSubscription(),
        userWatchers.watchCancelPauseSubscription(),
        userWatchers.watchCreatePublication(),
        userWatchers.watchUpdatePublication(),
        userWatchers.watchChangeCard(),
        userWatchers.watchValidateCname(),
        userWatchers.watchCreateDesignSubscription(),
        userWatchers.watchUpdateDesignSubscription(),
        userWatchers.watchFetchBrandProfiles(),
        userWatchers.watchCreateBrandProfile(),
        userWatchers.watchUpdateBrandProfile(),
        userWatchers.watchFetchCompanySubscriptions(),
        userWatchers.watchCreateDesigner(),
        userWatchers.watchAssignAccountsToSub(),
        userWatchers.watchGetHubspotToken(),
        userWatchers.watchDownloadTransactions(),

        orderWatchers.watchAddProjectToOrder(),
        orderWatchers.watchRemoveProjectFromOrder(),
        orderWatchers.watchOrderPay(),
        orderWatchers.watchBundlesPay(),

        paymentsWatchers.watchFetchBraintreeToken(),
        paymentsWatchers.watchFetchStripeCards(),
        paymentsWatchers.watchDeleteStripeCard(),
        paymentsWatchers.watchCheckRefundDesign(),
        paymentsWatchers.watchPutRefundDesign(),
        paymentsWatchers.watchRetryCharge(),
        paymentsWatchers.watchStripeComplete(),

        socketWatchers.watchInitPusher(),
    ]);
}
