// Hooks
import { useUi } from "../../hooks/useUi";

// Instruments
import { isNil } from "ramda";

// Icons
import success from '../../theme/images/icon-success.gif';
import info from '../../theme/images/icon-info.gif';
import error from '../../theme/images/icon-error.gif';
import processing from '../../theme/images/icon-processing.gif';
const images = { success, info, error, processing };

export const Notification = () => {
    /* Hooks */
    const { notification: { type, fn, msg }, setUiState } = useUi();

    /* Actions */
    const reload = () => {
        window.location.reload();
    };
    const onClose = () => {
        if ( type === 'processing' ) return false;

        setUiState('notification', {
            msg: null,
            type: null,
            fn: null,
        });
    };

    return (
        <div className = 'gac-notification' onClick = { onClose }>
            <div className="gac-notification__wrap">
                <div className="gac-notification__icon">
                    <img src={ images[type] } alt = 'Icon'/>
                </div>
                <div className="gac-notification__text">
                    { isNil(fn) ? msg : fn(reload) }
                </div>
            </div>
        </div>
    );
};