// Instruments
import { types } from './types';

export const authActions = Object.freeze({
    authenticate: (data) => ({
        type: types.AUTHENTICATE,
        payload: data,
    }),
    authenticateSuccess: (data) => ({
        type: types.AUTHENTICATE_SUCCESS,
        payload: data,
    }),
    authenticateFail: () => ({
        type: types.AUTHENTICATE_FAIL,
    }),
    login: (credentials, navigate) => ({
        type:    types.LOGIN,
        payload: {
            credentials,
            navigate
        },
    }),
    loginSuccess: (data) => ({
        type: types.LOGIN_SUCCESS,
        payload: data,
    }),
    loginFakeUserSuccess: (data) => ({
        type: types.LOGIN_FAKE_USER_SUCCESS,
        payload: data,
    }),
    logout: (navigate) => ({
        type: types.LOGOUT,
        payload: {
            navigate
        },
    }),
    logoutSuccess: () => ({
        type: types.LOGOUT_SUCCESS,
    }),
    clearCookies: () => ({
        type: types.CLEAR_COOKIES,
    }),
    setAccount: (account) => ({
        type:    types.SET_ACCOUNT,
        payload: account,
    }),
});
