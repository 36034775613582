// Core
import { useRef, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

// Components
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CartItem } from '../Cart/CartItem';
import Radio from '../../icons/Radio';
import CheckedRadio from '../../icons/CheckedRadio';
import StripeForm from "../../StripeForm";
import { PayPalForm } from "../../PayPalForm";
import { TransactionFeeText } from "../../common/TransactionFeeText";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useLists } from "../../../hooks/useLists";
import { usePayments } from "../../../hooks/usePayments";
import { usePrevious } from "../../../hooks/custom/usePrevious";
import { useOrder } from "../../../hooks/useOrder";
import { useSocket } from "../../../hooks/useSocket";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import {
    getAppTitle, getCurrencyCode,
    getRole,
    getStripe,
    isFetching,
    toLocaleString,
} from '../../../helpers/helpers';
import { bindConfirmOrderEvent } from "../../../init/pusher";
import { stripeKey } from '../../../helpers/api';
import animateScrollTo from 'animated-scroll-to';
import { isNil, isEmpty, includes } from 'ramda';

let stripePromise;
const dropin = require('braintree-web-drop-in');
const promocodeTypes = {
    'design': 'design_one_off',
    'motion': 'motion_one_off',
    'writing': 'writing_one_off',
    'aih': 'writing_one_off',
    'ideas': 'writing_one_off',
    'proofreading': 'writing_one_off',
    'seoaudit': 'seo_one_off',
    'keywords': 'keywords_one_off',
    'webaudit': 'webaudit_one_off'
};

export const Confirm = () => {
    /* Ref */
    const timer = useRef(null);
    const isDelay = useRef(false);
    const dropinInstance = useRef(null);
    const dropinErr = useRef(null);
    const methodsRef = useRef(null);

    /* State */
    const [payment_method, setPaymentMethod] = useState('');
    const [isMethodEmpty, setIsMethodEmpty] = useState(false);

    /* Hooks  */
    const { keys: { accountId }} = useAuth();
    const { orderList, staticData: { formats }, getOrderListAsync } = useLists();
    const { braintree: { token }, stripe: { client_secret }, fetchStripeCardsAsync, stripeCompleteAsync, fetchBraintreeTokenAsync, deleteStripeCardAsync } = usePayments();
    const { orderPayAsync } = useOrder();
    const { balance: { balance, bundles, payment_processor }, company, details: { currency, user_role }, paymentCards, fetchBalanceAsync } = useUser();
    const prevBalance = usePrevious(balance);
    const { isAuth, isMobile, isBraintreePaymentFailed, fetching, disableFetching, setUiState } = useUi();
    const { channels: { userChannel }} = useSocket();
    const navigate = useNavigate();

    const isSaveWithBundlesBtn = orderList.some(o => includes(o.job_type, 'writing/aih/ideas/proofreading'));

    /* Actions */
    const getTotal = () => {
        const subtotal = isEmpty(orderList) ? 0 : orderList.reduce((acc, o) => { return acc + Number(o.amount);}, 0);
        const discount =
            isEmpty(orderList) || isNil(bundles) || isEmpty(bundles)
                ? 0
                : orderList.reduce((acc, o) => {
                    const discountArr  = bundles.filter(a => a[promocodeTypes[o.job_type]]).map(b => b.discount);
                    const maxDiscount = isEmpty(discountArr) || isNil(discountArr) ? 0 : Math.max(...discountArr);
                    const num = Number(o.amount) * (maxDiscount/100);
                    return acc + num;
                }, 0);

        const total = subtotal - discount;
        const isFunds = (balance - total) < 0;
        let funds = total - balance;
        funds = Number(funds.toFixed(2));

        return { total, subtotal, discount,  funds, isFunds };
    };
    const goToPath = () => {
        navigate('/order');
    };
    const goToBundles = () => {
        navigate('/bundles');
    };
    const clearTimer = () => {
        clearTimeout(timer.current);
        isDelay.current = false;
    };
    const getPaypalButton = () => {
        if ( isMobile || payment_method !== 'paypal' ) return null;

        return <PayPalForm type = 'project' />
    };
    const getBtn = () => {
        const { isFunds } = getTotal();
        const isDiabled = isFunds && getRole(user_role) > 2;

        return <>
            <span onClick = { isDiabled ? null : onSubmit } className = { `gac-btn gac-btn-m gac-cart-confirm-order ${ isDiabled ? 'gac-disabled' : '' }` } >
                Confirm & Pay
            </span>
            { isDiabled && <div className = "gac-error gac-confirm-error-msg">Order placement for Strategists is limited to pre-paid balance. Ask your Manager to purchase a pre-paid bundle.</div> }
        </> ;
    };
    const getSubmitBtn = () => {
        const { isFunds } = getTotal();
        if ( isMobile || isNil(payment_processor) || payment_method === 'paypal' || (payment_processor === 'stripe' && isFunds) || (payment_processor === 'stripe,paypal' && payment_method === 'credit_card')) return null;

        return getBtn();
    };
    const getSubmitMobileBtn = () => {
        const { isFunds } = getTotal();

        return isMobile && <div className = 'gac-confirm-mobile-btn'>
            { payment_method === 'paypal' && getPaypalButton() }
            { payment_method === 'paypal' || isNil(payment_processor) || (payment_processor === 'stripe' && isFunds) || (payment_processor === 'stripe,paypal' && payment_method === 'credit_card') ? null : getBtn() }
        </div>;
    };

    /* Submit */
    const initBraintree = (token) => {
        let { funds } = getTotal();
        dropin.create({
            authorization: token,
            container: '#dropin-container',
            vaultManager: true,
            card: {
                vault: {
                    allowVaultCardOverride: true,
                },
                overrides: {
                    styles: {
                        input: {
                            color: '#484b58',
                        }
                    }
                }
            },
            threeDSecure: { amount: funds < 10 ? 10 : funds },
        }, onBraintreeCreate);
    };
    const onBraintreeCreate = (createErr, instance) => {
        dropinInstance.current = instance;
        dropinErr.current = createErr;
        disableFetching();
    };
    const onStripeComplete = (data) => {
        stripeCompleteAsync({ ...data, account_uid: accountId }, navigate);
    };
    const onMethodChange = ({ target: { value }}) => {
        if ( !isDelay.current ) {
            if ( value === 'credit_card' ) {
                if ( payment_processor === 'braintree,paypal') {
                    fetchBraintreeTokenAsync();
                }
            } else {
                document.getElementById("dropin-container").innerHTML = '';
            }
            setIsMethodEmpty(false);
            setPaymentMethod(value);
            isDelay.current = value === 'paypal';
            if ( value === 'paypal' ) {
                timer.current = setTimeout( clearTimer, 4000);
            }
        }
    };
    const onSubmit = (data) => {
        const { isFunds, funds } = getTotal();

        if ( !isFetching(fetching) ) {
            if ( payment_method === 'credit_card' || (payment_processor === 'stripe' && isFunds) ) {
                setUiState('isBlocking', true);
                if ( payment_processor === 'braintree,paypal' ) {
                    dropinInstance.current.requestPaymentMethod(function (err, payload) {
                        if ( err ) {
                            setUiState('isBlocking', false);
                        }
                        if ( !isNil(payload) && !err ) {
                            orderPayAsync({
                                payment_method: 'braintree',
                                threeDSecure: { amount: funds < 10 ? 10 : funds },
                                payment_method_nonce: payload.nonce
                            }, navigate);
                        }
                    });
                } else {
                    orderPayAsync({ payment_method: 'stripe', type: 'project', ...data }, navigate);
                }
            } else if ( payment_method === '' ) {
                if ( isFunds ) {
                    setIsMethodEmpty(true);
                    animateScrollTo(methodsRef.current, {
                        verticalOffset: 40,
                    });
                } else {
                    setUiState('isBlocking', true);
                    orderPayAsync({}, navigate);
                }
            }
        }
    };

    useEffect(() => {
        document.title = getAppTitle('Confirm order', company);
        animateScrollTo(0);
        if ( isAuth ) {
            fetchBalanceAsync(accountId);
            getOrderListAsync(navigate);
            fetchStripeCardsAsync(accountId);
        }
        if ( includes('?error', window.location.href) ) {
            setUiState('notification', { msg: 'Payment failed. Please try again or contact support.', type: 'error', fn: null });
        }
    }, []);
    useEffect(() => {
        if ( !isNil(userChannel) ) {
            bindConfirmOrderEvent(userChannel);
        }
    }, [userChannel]);
    useEffect(() => {
        if ( !isNil(token) && !isEmpty(token) ) {
            initBraintree(token);
        }
    }, [token]);
    useEffect(() => {
        if ( isBraintreePaymentFailed ) {
            setUiState('isBraintreePaymentFailed', false);
            setPaymentMethod('');
            setIsMethodEmpty(false);
            document.getElementById("dropin-container").innerHTML = '';
        }
    }, [isBraintreePaymentFailed]);
    useEffect(() => {
        if ( !isNil(prevBalance) && prevBalance !== balance ) {
            setPaymentMethod('');
            setIsMethodEmpty(false);
            const el = document.getElementById("dropin-container");
            if ( !isNil(el) ) {
                el.innerHTML = '';
            }
        }
    }, [balance]);

    /* Html */
    const getTitle = () => {
        if ( isMobile ) {
            return <div className = 'gac-title'>My order: { orderList.length === 0 ? ' 0 projects' : orderList.length === 1 ? ' 1 project' : ` ${orderList.length} projects` }</div>;
        }

        return <h1>Confirm order</h1> ;
    };
    const getProjects = () => {
        if ( !isMobile ) return null;

        const projects = orderList.map((o,i) => {
            return <CartItem format = { formats['writing'] } active = { false } project = { o } key = { i }/>;
        });
        if ( isEmpty(projects) ) return null;

        return <div className = 'gac-cart-projects'>{ projects }</div>;
    };
    const getAddProjectBtn = () => {
        if ( !isMobile ) return null;

        return <div onClick = { goToPath } className = { 'gac-add-project gac-green' }><i/>Add a project</div>;
    };
    const getSubtotal = () => {
        const { subtotal, discount } = getTotal();

        return <div className = 'gac-cart-subtotal'>
            <p><span className = 'gac-cart-subtotal-label'>Projects:</span><span className = 'gac-cart-subtotal-value'>{ currency }{ subtotal ? toLocaleString(subtotal.toFixed(2)) : '0' }</span></p>
            <p><span className = 'gac-cart-subtotal-label'>Discount:</span><span className = 'gac-cart-subtotal-value'>{ currency }{ discount ? toLocaleString(discount.toFixed(2)) : '0' }</span></p>
            { getRole(user_role) < 3 && isSaveWithBundlesBtn
                ? <span onClick = { goToBundles } className = 'gac-cart-with-bundles'>Save with content bundles</span>
                : null }
        </div> ;
    };
    const getPaymentMethods = () => {
        const { total, isFunds, funds } = getTotal();

        return <>
            <div className = 'gac-cart-total'><p><span className = 'gac-cart-total-label'>Total:</span><span className = 'gac-cart-total-value'>{ currency }{ total ? toLocaleString(total.toFixed(2)) : '0' }</span></p></div>
            { (!!balance || total < 10 ) && <div className = 'gac-cart-balance'>
                { !!balance && <p>
                    <span className = 'gac-cart-subtotal-label'>Balance:</span>
                    <span className = 'gac-cart-subtotal-value'>{ currency }{ balance ? toLocaleString(balance.toFixed(2)) : '0' }</span>
                </p> }
                { isFunds && (
                    <p>
                        <span className = 'gac-cart-subtotal-label'>{ `${total - balance < 10 ? `Add funds (min ${ currency }10):` : 'Add funds:'}` }</span>
                        <span className = 'gac-cart-subtotal-value'>{ currency }{ total - balance < 10 ? 10 : toLocaleString(funds.toFixed(2)) }</span>
                    </p>
                ) }
            </div> }
            { (isFunds && payment_processor !== 'stripe' && getRole(user_role) < 3 ) && <div ref = { methodsRef } className = { `gac-cart-payment-methods ${ isMethodEmpty ? 'gac-invalid' : '' } ${ payment_processor === 'braintree,paypal' ? 'gac-method-braintree' : ''}` }>
                <label htmlFor = 'credit_card' className = 'gac-cart-methods-item'>
                    <input type = 'radio' id = 'credit_card' name = 'payment_method' value = 'credit_card' checked = { payment_method === 'credit_card'} onChange = { onMethodChange }/>
                    <span>{ payment_method === 'credit_card' ? <CheckedRadio/> : <Radio/> }</span>
                    <div className = 'gac-cart-methods-item-card' />
                </label>
                <label htmlFor = 'paypal_method' className = 'gac-cart-methods-item'>
                    <input type = 'radio' id = 'paypal_method' name = 'payment_method' value = 'paypal' checked = { payment_method === 'paypal'} onChange = { onMethodChange }/>
                    <span>{ payment_method === 'paypal' ? <CheckedRadio/> : <Radio/> }</span>
                    <div className = 'gac-cart-methods-item-paypal'/>
                </label>
            </div> }
        </> ;
    };
    const getStripeForm = () => {
        const { isFunds } = getTotal();
        stripePromise = getStripe(stripePromise, stripeKey, loadStripe);

        return stripePromise && !isNil(payment_processor) && isFunds && (payment_processor === 'stripe' || (payment_processor === 'stripe,paypal' && payment_method === 'credit_card' )) &&
            <Elements stripe = { stripePromise }>
                <StripeForm
                    type = 'project'
                    cards = { paymentCards['stripe'] }
                    secret = { client_secret }
                    isMobile = { isMobile }
                    setUiState = { setUiState }
                    deleteCard = { deleteStripeCardAsync }
                    onComplete = { onStripeComplete }
                    onSubmit = { onSubmit } />
            </Elements> ;
    };
    const getMoneyBack = () => <div className = "gac-cart-money-back"><i/><p>100% Money Back Guarantee <br/> Content you love or your money back</p></div>;
    const getTransactionFeeText = () => <TransactionFeeText lineHeight = { 24 } fontSize = { 12 } currency = { getCurrencyCode(currency) } />;
    const getDropinContainer = () => <div id = 'dropin-container'/>;

    return <>
        <div className = 'gac-confirm'>
            <div className = 'gac-confirm-wrap'>
                { getTitle() }
                { getProjects() }
                { getAddProjectBtn() }
                { getSubtotal() }
                { getPaymentMethods() }
                { getStripeForm() }
                { getDropinContainer() }
                { getPaypalButton() }
                { getSubmitBtn() }
                { getTransactionFeeText() }
                { getMoneyBack() }
            </div>
        </div>
        { getSubmitMobileBtn() }
    </> ;
};