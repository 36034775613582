// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, authStr, getKeys, getPusherData, getOrderList } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { orderActionsAsync } from '../../../order/saga/asyncActions';
import { projectActions } from "../../actions";
import { listsActions } from "../../../lists/actions";

export function* callUpdateProjectWorker ({ payload: { project, action, idea_number, navigate }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const { sessionID } = yield select(getPusherData);
        const response = yield call(fetch,`${api}/${publicKey}/v1/projects/${action.job_type}/${project.project_id}/update?account_uid=${accountId}`,{
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                ...project,
                pusherData: {
                    sessionID
                },
            }),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( action.name === 'update_add_to_order' ) {
            yield put(orderActionsAsync.addProjectToOrderAsync(data.project_id, action.name, navigate));
        } else if ( action.name === 'update_draft_order' ) {
            let orderList = yield select(getOrderList);
            orderList = orderList.filter(o => `${o.project_id}` !== `${data.project_id}`);

            const { client_amount: amount, currency, format, word_count, topic, project_id } = data;
            yield put(listsActions.setListsState('orderList', [{ amount: Number(amount.replace(',','')), currency, format, word_count, topic, project_id }, ...orderList ]));

            navigate(`/order/confirm`);
        } else if ( action.name === 'update_draft' ) {
            navigate(`/order`);
        } else if ( action.name === 'update_project' ) {
            yield put(projectActions.setProjectState('details', data));
        } else if ( action.name === 'update_order_on_from_idea' ) {
            yield put(projectActions.updateIdeaField('ordered_project_date', data.order_on, idea_number));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
