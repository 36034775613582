// Core
import { useState, useRef, useEffect } from "react";

// Components
import { useDropzone } from 'react-dropzone';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

// Hooks
import { useUi } from "../../../hooks/useUi";

// Instruments
import { base64MimeType } from "../../../helpers/helpers";
import { isEmpty, isNil } from "ramda";

/* Helpers */
const centerAspectCrop = ( mediaWidth, mediaHeight, aspect, crop ) => {
    return centerCrop( makeAspectCrop( crop, aspect, mediaWidth, mediaHeight ), mediaWidth, mediaHeight )
}
const getBase64ImgFromCanvas = ( image, crop ) => {
    const mimeType = base64MimeType(image.src);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();
    // 3) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 2) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    );

    return canvas.toDataURL(mimeType);
}

export const ModalUploadImage = (props) => {
    const { accept, aspect, crop: cropProp, settings, parameter, size, closeModal, setPos } = props;

    /* Ref */
    const modalRef = useRef(null);
    const overlayRef = useRef(null);
    const imgRef = useRef(null);

    /* State */
    const [src, setSrc] = useState(null);
    const [file, setFile] = useState(null);
    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null); // croppedUrl: null,
    const [isCropError, setIsCropError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    /* Hooks */
    const { isMobile, uploadFileAsync, uploadBase64Async } = useUi();

    const onResize = () => {
        setPos(modalRef);
    };

    /* ReactCrop */
    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, aspect, cropProp));
        setPos(modalRef);
    }
    const onOverlayClick = ({ target }) => {
        if ( target === overlayRef.current ) {
            closeModal();
        }
    };
    const onDropRejected = (rejected) => {
        if ( !isEmpty(rejected) ) {
            const { code, message } = rejected[0].errors[0];
            const error = code === 'file-too-large' ? 'File is larger than 10 Mb' : message;
            setErrorMsg(error);
        }
    };
    const onDrop = (file) => {
        if (file && file.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setSrc(reader.result);
                setFile(file[0]);

            });
            reader.readAsDataURL(file[0]);
        }
    };
    const onSubmit = () => {
        if ( src ) {
            if ( parameter === 'favicon' ) {
                const data = new FormData();
                data.append('attachment', file, file.name);
                uploadFileAsync(data, 'upload_favicon');
            } else {
                let width, height;
                if ( !size.width && !size.height && size.maxWidth && size.maxHeight ) {
                    const maxAspect = size.maxWidth/size.maxHeight;
                    const aspect = crop.width/crop.height;
                    if ( aspect < maxAspect ) {
                        if ( crop.height > size.maxHeight ) {
                            width = Math.round(size.maxHeight*aspect);
                            height = size.maxHeight;
                        } else {
                            width = crop.width;
                            height = crop.height;
                        }
                    } else if ( aspect > maxAspect ) {
                        if ( crop.width > size.maxWidth ) {
                            width = size.maxWidth;
                            height = Math.round(size.maxWidth/aspect);
                        } else {
                            width = crop.width;
                            height = crop.height;
                        }
                    }
                } else {
                    width = size.width;
                    height = size.height;
                }
                width = Math.round(width);
                height = Math.round(height);

                const base64_string = getBase64ImgFromCanvas( imgRef.current, completedCrop );

                if ( !width || !height || !base64_string ) {
                    setIsCropError(true);
                } else {
                    uploadBase64Async({ base64_string, width, height }, parameter);
                }
            }
        }
    };

    /* Did mount */
    useEffect(() => {
        setPos(modalRef);
        window.addEventListener('resize', onResize, true);

        return () => {
            window.removeEventListener('resize', onResize, true);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept,
        maxSize: 10000000, // = 10Mb
        multiple: false,
        noDrag: false,
        onDrop,
        onDropRejected,
        preventDropOnDocument: true,
    });
    const getDropzoneError = () => {
        if ( isNil(errorMsg) ) return <><p>Drag and drop an image</p><p>or</p><span>Upload from computer</span></>;

        return <p className = 'gac-error'>{ errorMsg }</p>
    };
    const getDropzone = () => {
        if ( !isNil(src) ) return null;

        return <div { ...getRootProps( { className: 'gac-upload-image-dropzone'} ) }>
            <input { ...getInputProps() } />
            { getDropzoneError() }
        </div>
    };
    const getCrop = () => {
        if ( isNil(src) ) return null;

        return <>
            <div className = 'gac-react-crop'>
                <ReactCrop
                    { ...settings }
                    aspect = { aspect }
                    crop = { crop }
                    onChange = { (_, percentCrop) => setCrop(percentCrop) }
                    onComplete = { (c) => setCompletedCrop(c) } >
                    <img ref = { imgRef } alt = "Crop me" src = { src } onLoad = { onImageLoad } />
                </ReactCrop>
            </div>
            <div className="gac-error">{ isCropError && 'Please select correct dimensions for image' }</div>
        </>
    };
    const getBtn = () => {
        return <div className = 'gac-upload-btn-wrap'>
            <span className = { `gac-btn gac-btn-s gac-upload-btn ${!src ? 'gac-btn-disable' : '' }` } onClick = { onSubmit } >Upload</span>
        </div> ;
    };

    return <div className = 'gac-modal-overlay' ref = { overlayRef } onMouseDown = { onOverlayClick }>
        <div style = {{ height: !src && isMobile ? '100vh' : 'auto' }} ref = { modalRef } className = 'gac-modal gac-upload-image-modal'>
            <span className = 'gac-close-modal' onClick = { closeModal }/>
            <h1>Upload an image</h1>
            <p>Select the image you wish to upload. An image should not be more than 10MB in size.</p>
            { getDropzone() }
            { getCrop() }
            {/*{croppedUrl && (*/}
            {/*<img alt="Crop" src={croppedUrl} />*/}
            {/*)}*/}
            { getBtn() }
        </div>
    </div> ;
};
