// Core
import { useState, useEffect, useRef } from "react";

// Components
import Checkbox from '../../icons/Checkbox';
import CheckedCheckbox from '../../icons/CheckedCheckbox';

// Hooks
import { useUser } from "../../../hooks/useUser";

// Instruments
import { getScoreHtml, isEpiic } from '../../../helpers/helpers';
import { isNil } from 'ramda';
import { GAC, Epiic } from '../../../helpers/api';

export const ModalNps = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);

    /* Hooks */
    const { details: { user_id }, company: { branding: { host }}, sendNpsAsync } = useUser();

    /* State */
    const [score, setScore] = useState(null);
    const [feedback, setFeedback] = useState('');
    const [isShareFeedback, setIsShareFeedback] = useState(true);

    /* Actions */
    const onScoreChange = ({ currentTarget: { dataset: { score }}}) => {
        setScore(Number(score));
    };
    const onFeedbackChange = ({ currentTarget: { value }}) => {
        setFeedback(value);
    };
    const onCheckboxChange = ({ currentTarget: { dataset: { prop }}}) => {
        if ( prop === 'isShareFeedback' ) {
            setIsShareFeedback(state => !state);
        }
    };
    /* Submit */
    const onSubmit = () => {
        if ( !isNil(score) ) {
            sendNpsAsync({
                user_id,
                score: Number(score),
                feedback,
                feedback_share: isShareFeedback ? 'yes' : 'no',
            });
        }
    };

    /* Did mount */
    useEffect(() => {
        const onResize = () => {
            setPos( modalRef );
        };

        setPos( modalRef );
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    /* Html */
    const scoreData = getScoreHtml(score, onScoreChange);

    return <div ref = { modalRef } className = 'gac-modal gac-rate-writer-modal'>
        <span className = 'gac-close-modal' onClick = { closeModal }/>
        <h1 className='gac-nps-modal-title'>How likely are you to recommend { isEpiic(host) ? Epiic : GAC } to a friend or colleague?</h1>
        <div className = 'gac-score-html'>
            { scoreData }
        </div>
        <div className = 'gac-score-desc'>
            <span>Not at all likely</span>
            <span>Extremely likely</span>
        </div>
        { !isNil(score) && score < 9 &&
            <div className = 'gac-rate-desc'>
                <label htmlFor="">What is the reason for your score?</label>
                <textarea style={{ height: '92px' }} value = { feedback } onChange = { onFeedbackChange }/>
            </div> }
        { !isNil(score) && score === 8 && <label className = 'gac-share-feedback' htmlFor="share-feedback">
            <input id = 'share-feedback' data-prop = 'isShareFeedback' checked = { isShareFeedback } onChange = { onCheckboxChange } type="checkbox"/>
            <i>{ isShareFeedback ? <CheckedCheckbox/> : <Checkbox/> }</i>
            <span>I allow to use this as a testimonial</span>
        </label> }
        <div className="gac-rate-writer-btns">
            <div onClick = { onSubmit } className = { `gac-btn gac-btn-m ${ isNil(score) ? 'gac-btn-disable' : '' }` }>Submit</div>
        </div>
    </div> ;
};