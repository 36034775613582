// Core
import { useLayoutEffect } from "react";

// Hooks
import { useUser } from "../../../hooks/useUser";
import { useForms } from "../../../hooks/useForms";
import { useOrder } from "../../../hooks/useOrder";

// Instruments
import { getOrderForm, initDefaultRedactor } from "../../../helpers/helpers";
import { isEmpty, isNil } from "ramda";

export const SeoAuditFull = ({ fields }) => {
    /* Hooks */
    const {
        getSeoAuditLevelField,
        getSeoAuditWebsiteField,
        getTextareaField,
        getTargetCountryField,
        getSeoAuditCmsField,
        getLinkToResourcesField,
        getGoogleAccessField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;
    const { details: { country }} = useUser();
    const forms = useForms();
    const { updateFormState } = forms;
    const { job_type, job_pay_type, topic_ideas_type, seoaudit_type } = useOrder();

    const { target_country_code } = getOrderForm(job_type, topic_ideas_type, seoaudit_type, job_pay_type, forms);

    useLayoutEffect(() => {
        if ( isEmpty(target_country_code) ) {
            updateFormState('seoauditFullForm', { target_country_code: isEmpty(country) || isNil(country) ? 'US' : country });
        }

        initDefaultRedactor('#competitors-textarea', 'competitors', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#target_audience-textarea', 'target_audience', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#seo_audit_notes-textarea', 'seo_audit_notes', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#focus_areas-textarea', 'focus_areas', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#keywords_google-textarea', 'keywords_google', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#marketing_channels-textarea', 'marketing_channels', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#monitoring_tools-textarea', 'monitoring_tools', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#goals-textarea', 'goals', '116px', null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#competitors-textarea', 'destroy');
            window.$R('#target_audience-textarea', 'destroy');
            window.$R('#seo_audit_notes-textarea', 'destroy');
            window.$R('#focus_areas-textarea', 'destroy');
            window.$R('#keywords_google-textarea', 'destroy');
            window.$R('#marketing_channels-textarea', 'destroy');
            window.$R('#monitoring_tools-textarea', 'destroy');
            window.$R('#goals-textarea', 'destroy');
        };
    }, []);

    return <>
        { getSeoAuditLevelField() }
        { getSeoAuditWebsiteField() }
        { getTextareaField('competitors', 'List your 3 main competitors') }
        { getTextareaField('target_audience', 'Describe the target audience of your website') }
        { getTargetCountryField() }
        { getTextareaField('seo_audit_notes', 'Have you performed a SEO audit before? If so, what were the main insights?') }
        { getSeoAuditCmsField() }
        { getTextareaField('focus_areas', 'Which areas of your marketing strategy do you feel need the most improvement?') }
        { getTextareaField('keywords_google', `Name 10 keywords you want to rank in the top 10 on Google with`) }
        { getLinkToResourcesField() }
        { getTextareaField('marketing_channels', `What other marketing channels are you currently using?`) }
        { getTextareaField('monitoring_tools', `What tools do you use to monitor your site's performance?`) }
        { getTextareaField('goals', 'Main goals you want to achieve with this audit') }
        { getGoogleAccessField() }
    </>;
};