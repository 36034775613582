// Core
import { call, delay, put, select } from 'redux-saga/effects';

// Instruments
import { api, authStr, getKeys, getCompanySubs } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from '../../actions';
import { includes, isEmpty, isNil } from "ramda";
import moment from "moment/moment";

export function* callCreateDesignSubscriptionWorker({ payload: { subscription, navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const { id } = subscription;
        const isCustom = id === 'custom';
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/design/subscribe${isCustom ? '-custom' : ''}`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...subscription
            }),
        });
        const { response: data, error: { message } } = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        let subs = yield select(getCompanySubs);
        subs = isNil(subs) || isEmpty(subs) ? [] : subs;
        yield put(userActions.setUserCompanyState('subscriptions', [...subs, data]));
        yield put(uiActions.setUiState('notification', {
            msg: 'Processing payment',
            type: 'processing',
            fn: null,
        }));
        if (!moment(subscription.start_date).isSame(moment(), 'day')) {
            yield delay(3000);
            yield put(uiActions.setUiState('notification', {
                msg: null,
                type: null,
                fn: null
            }));
            navigate('/subscriptions');
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
        yield put(uiActions.setUiState('isBlocking', false));
    }
}
