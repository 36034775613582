// Core
import { useEffect } from "react";

// Hooks
import { useModals } from "../../../hooks/useModals";

export const ModalFullImage = ({ closeModal }) => {
    /* Hooks */
    const { fullImageUrl, setModalState } = useModals();

    useEffect(() => {
        return () => {
            setModalState('fullImageUrl', null);
        };
    }, []);

    return <div className = 'gac-full-image'>
        <div className = 'gac-full-image-wrap' onClick = { closeModal }>
            <img alt = 'Full image' src = { fullImageUrl }/>
        </div>
    </div> ;
};