// Core
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Hooks
import { useLogin } from "../useLogin";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { getAppTitle, isWhiteLabel } from "../../../helpers/helpers";

export const Password = () => {
    /* Hooks */
    const { getEmail, getRecaptcha, getSubmitBtn } = useLogin();
    const { isPasswordRestored } = useUi();
    const { company } = useUser();
    const navigate = useNavigate();

    /* Actions */
    const onLogin = ({currentTarget: { dataset: { path }}}) => {
        navigate(path);
    };

    /* Did mount */
    useEffect(() => {
        document.title = getAppTitle('Restore password', company);
    }, []);

    if ( isPasswordRestored ) {
        return(
            <>
                <span className = 'gac-success-icon'><i/></span>
                <h1>Check your email (and spam) for instructions</h1>
            </>
        );
    }

    return(
        <>
            <div className = 'gac-wrap'>
                { getEmail() }
                <div className = 'gac-restore-password'>
                    <span data-path = '/login' onClick = { onLogin }>Log in</span>
                </div>
                { !isWhiteLabel() ? getRecaptcha() : null }
                { getSubmitBtn() }
            </div>
        </>
    );
};