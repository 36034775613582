// Core
import { useLayoutEffect } from "react";

// Instruments
import { initDefaultRedactor } from "../../../helpers/helpers";

export const MotionOneOff = ({ fields }) => {
    /* Hooks */
    const {
        getMotionFormatField,
        getFormatOptionsField,
        getDimensionsField,
        getTopicField,
        getAttachFileField,
        getTextareaField,
        getMotionAnimationLengthField,
        getFileTypesField,
        getBrandProfilesField,
        getMotionIdeasDropzoneHtml,
        getWritersField,
        getReviewFrequencyField,

        onRedactorSynced,
        onRedactorBlur
    } = fields;

    useLayoutEffect(() => {
        initDefaultRedactor('#brand_description-textarea', 'brand_description', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#ideas-textarea', 'ideas', '116px', null, onRedactorSynced, onRedactorBlur, true);
        initDefaultRedactor('#examples-textarea', 'examples', '116px', null, onRedactorSynced, onRedactorBlur, true);

        return () => {
            window.$R('#brand_description-textarea', 'destroy');
            window.$R('#ideas-textarea', 'destroy');
            window.$R('#examples-textarea', 'destroy');
        };
    }, []);

    return <>
        { getMotionFormatField() }
        { getFormatOptionsField() }
        { getDimensionsField() }
        { getMotionAnimationLengthField() }
        { getTopicField() }
        { getAttachFileField() }
        { getTextareaField('brand_description', 'Text to be included in the motion graphic, word for word (leave blank if none)') }
        { getTextareaField('ideas', 'Your ideas/storyboard/script for the animation', true, getMotionIdeasDropzoneHtml) }
        { getTextareaField('examples', 'Links to examples of animations you like') }
        { getBrandProfilesField() }
        { getFileTypesField() }
        { getWritersField() }
        { getReviewFrequencyField() }
    </>;
};
