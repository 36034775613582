// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from "../../actions";
import { isNil } from 'ramda';

export function* callFetchBalanceWorker ({ payload: account_id }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        if ( isNil(publicKey) ) return false;
        const url = isNil(account_id) ? '' : `?account_uid=${account_id}`;
        const response = yield call(fetch, `${api}/${publicKey}/v1/balance${url}`, {
            method: 'GET',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
            }
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(userActions.setUserState('balance', data));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
    } finally {
        yield put(uiActions.disableFetching());
    }
}
