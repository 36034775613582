// Core
import { takeEvery } from 'redux-saga/effects';

// Instruments
import { types } from '../types';
import { callInitPusherWorker } from './workers/initPusher';

export const socketWatchers = Object.freeze({
    * watchInitPusher () {
        yield takeEvery(types.INIT_PUSHER, callInitPusherWorker);
    },
});
