// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { userActions } from "../../../user/actions";
import { isNil } from "ramda";
import {authActions} from "../../../auth/actions";
import { setAccountCookies } from "../../../../helpers/helpers";

export function* callFetchStripeCardsWorker ({ payload: { accountUid, type, subscriptionId, talent, currency }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const t = type === 'epiic' ? '/epiic' : '';
        const acc = `?account_uid=${accountUid}`;
        const subId = isNil(subscriptionId) ? '' : `&subscription_id=${subscriptionId}`;
        const talentType = isNil(talent) ? '' : `&talent=${talent}`;
        let currencyCode = '';

        //if(isNil(talent) && typeof currency !== 'undefined') {
        if(typeof currency !== 'undefined') {
            currencyCode = `&currency=${currency}`;
        }
        const response = yield call(fetch, `${api}/${publicKey}/v1/stripe/cards${t}${acc}${subId}${talentType}${currencyCode}`,{
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { response: { account, cards }, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(userActions.fetchCardsSuccess(type === 'epiic' ? 'epiic' : 'stripe',isNil(cards) ? [] : cards));
        if ( !isNil(account) && accountUid !== account.account_id ) {
            yield put(userActions.createAccountSuccess({
                ...account,
                account_uid: account.account_id
            }));
            yield put(authActions.setAccount(account.account_id));
            setAccountCookies(account.account_id);
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
