import React from 'react';
import {
    ElementsConsumer,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from '@stripe/react-stripe-js';
import { equals, isEmpty, isNil } from "ramda";

const ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#1E1E1E',
            fontSize: '15px',
            '::placeholder': {
                color: '#C0C0C0',
                fontSize: '15px',
            },
        },
        invalid: {
            color: '#dc5d5b',
        },
    },
};
class Form extends React.Component {

    state = {
        activeCard: null,
        isSaveCard: true,
        isCardFrom: false,
        isCardsEditing: false,
        isEmptySavedCard: false,
    };
    componentDidMount() {
        const { cards, callback } = this.props;

        if (!isNil(cards) && isEmpty(cards)) {
            this.setState({
                isCardFrom: true,
            }, () => {
                if (!isNil(callback)) {
                    callback();
                }
            });
        }
    }
    componentDidUpdate(props) {
        const { secret, cards, callback } = this.props;

        if (props.secret !== secret && secret) {
            this._onConfirmCardPayment();
        }
        if (!equals(props.cards, cards) && !isNil(cards)) {
            this.setState({
                isCardFrom: isEmpty(cards),
            }, () => {
                if (!isNil(callback)) {
                    callback();
                }
            });
        }
    }

    _onConfirmCardPayment = () => {
        const { stripe, elements, secret, type, onComplete, setUiState } = this.props;
        const { activeCard, isCardFrom } = this.state;
        if (!stripe || !elements) { return }

        if (isNil(activeCard) && !isCardFrom) {
            setUiState('isBlocking', false);
            this.setState({
                isEmptySavedCard: true
            });
        } else {
            stripe.confirmCardPayment(secret, {
                payment_method: isNil(activeCard) ? { card: elements.getElement(CardNumberElement) } : activeCard,
            }).then((result) => {
                if (result.error) {
                    setUiState('isBlocking', false);
                    setUiState('notification', {
                        msg: result.error.message,
                        type: 'error',
                        fn: null,
                    });
                } else {
                    if (result.paymentIntent.status === 'succeeded') {
                        onComplete({
                            type,
                            'transaction_id': result.paymentIntent.id,
                            'card_id': result.paymentIntent.payment_method
                        });
                    }
                }
            });
        }
    };

    _toggleCardsEditing = () => {
        this.setState({
            isCardsEditing: !this.state.isCardsEditing
        });
    };
    _onRemoveCard = ({ currentTarget: { dataset: { id } } }) => {
        const { type } = this.props;
        this.props.deleteCard(id, type === 'design' || type === 'motion' || type === 'webbuilding' || type === 'assistants' || type === 'hourly-designers' ? 'epiic' : 'stripe');
    };
    _onCardClick = ({ currentTarget: { dataset: { id } } }) => {
        this.setState({
            activeCard: id,
            isEmptySavedCard: false
        });
    };
    _showCardForm = () => {
        const { callback } = this.props;
        this.setState({
            isCardFrom: true,
            activeCard: null,
            isEmptySavedCard: false
        }, () => {
            if (!isNil(callback)) {
                callback();
            }
        });
    };
    _onSaveCardChange = () => {
        this.setState({
            isSaveCard: !this.state.isSaveCard
        });
    };

    _onSubmit = () => {
        const { stripe, elements, type, setUiState } = this.props;
        const { activeCard, isCardFrom } = this.state;
        if (!stripe || !elements) return;
        const that = this;
        const save_card = that.state.isSaveCard ? 'yes' : 'no';

        if (type === 'hourly-designers' || type === 'design' || type === 'motion' || type === 'writing' || type === 'blogging' || type === 'webbuilding' || type === 'assistants') {

            if (isNil(activeCard) && !isCardFrom) {
                setUiState('isBlocking', false);
                this.setState({
                    isEmptySavedCard: true
                });
            } else {
                if (isNil(activeCard)) {
                    stripe.createPaymentMethod({
                        type: 'card',
                        card: elements.getElement(CardNumberElement)
                    }).then(function (result) {
                        if (result.error) {
                            setUiState('isBlocking', false);
                            setUiState('notification', {
                                msg: result.error.message,
                                type: 'error',
                                fn: null,
                            });
                        } else {
                            that.props.onSubmit({
                                save_card,
                                'stripe_payment_method_id': result.paymentMethod.id,
                            });
                        }
                    });
                } else {
                    that.props.onSubmit({
                        save_card,
                        'stripe_payment_method_id': activeCard,
                    });
                }
            }
        } else {
            this.props.onSubmit({
                save_card,
            });
        }
    };

    render() {
        const { activeCard, isSaveCard, isCardFrom, isEmptySavedCard, isCardsEditing } = this.state;
        let { cards, type, parent, isMobile, isChangeCard, stripe, elements } = this.props;
        if (!stripe || !elements) return null;
        cards = isNil(cards) ? [] : cards;
        const cardsData = cards.map(({ id, in_use, default: defaultValue, card: { brand, last4 } }) => {
            return (
                <li
                    key={id}
                    data-id={id}
                    onClick={isCardsEditing ? () => { } : this._onCardClick}
                    className={`gac-stripe-card-item ${isEmptySavedCard ? 'gac-invalid' : ''}`}>
                    <input id={id} type="radio" name='saved_card' checked={activeCard === id} readOnly />
                    <label htmlFor={id} style={{ cursor: isCardsEditing ? 'default' : 'pointer' }}>
                        <div className={`gac-stripe-card-item__type-icon ${brand}`}>Card</div>
                        <p className='gac-stripe-card-item__number'>Ending on {last4}</p>
                        <p className='gac-stripe-card-item__type'>{brand}</p>
                        {isCardsEditing
                            ? in_use
                                ? null
                                : <span data-id={id} className='gac-stripe-card-item__remove' onClick={this._onRemoveCard} />
                            : <span data-default={defaultValue && isNil(activeCard) && isChangeCard} className='gac-stripe-card-item__icon' />}
                    </label>
                </li>
            );
        });
        const btnStyles = isChangeCard ? { marginTop: 20 } : {};
        const btnClass = isChangeCard ? 'gac-btn gac-btn-s' : 'gac-btn gac-btn-m gac-cart-confirm-order';
        const btnLabel = isChangeCard ? 'Change' : 'Confirm & Pay';

        return <>
            <div className='gac-stripe'>
                {!isEmpty(cardsData) && <div className="gac-stripe-cards">
                    <span style={{ top: 0 }} className="gac-stripe-cards__edit-btn" onClick={this._toggleCardsEditing}>{isCardsEditing ? 'Close' : 'Edit'}</span>
                    <div className='gac-stripe-cards__title'>{parent === 'changeCardModal' ? 'Default payment method' : 'Paying with Card'}</div>
                    <ul className="gac-stripe-cards__list">
                        {cardsData}
                    </ul>
                    <div className="gac-stripe-cards__wrap">
                        <button onClick={this._showCardForm} className="gac-stripe-cards__btn">Choose another way to pay</button>
                    </div>
                </div>}
                {isCardFrom && <div className="gac-stripe-form">
                    <div className="gac-stripe-form__title">
                        Pay with card
                    </div>
                    <div className="gac-stripe-form__item">
                        <label>Card number</label>
                        <CardNumberElement options={ELEMENT_OPTIONS} />
                    </div>
                    <div className="gac-stripe-form__item">
                        <label>Expiration date <span>(MM/YY)</span></label>
                        <CardExpiryElement options={ELEMENT_OPTIONS} />
                    </div>
                    <div className="gac-stripe-form__item">
                        <label>CVV <span>(3 digits)</span></label>
                        <CardCvcElement options={ELEMENT_OPTIONS} />
                    </div>
                    <label className='gac-stripe-form__checkbox' htmlFor="save_card">
                        <input id='save_card' type="checkbox" onChange={this._onSaveCardChange} checked={isSaveCard} />
                        <span />
                        Save card?
                    </label>
                </div>}
                {!isMobile && <span onClick={this._onSubmit} className={btnClass} style={btnStyles}>{btnLabel}</span>}
            </div>
            {isMobile && <div className='gac-confirm-mobile-btn'>
                <div className='paypal-button-container' id={`paypal-button-container${type === 'bundle' || type === 'prepay' ? '-bundles' : ''}`} />
                <span onClick={this._onSubmit} className={btnClass}>{btnLabel}</span>
            </div>}
        </>;
    }
}

const StripeForm = (props) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <Form stripe={stripe} elements={elements} {...props} />
        )}
    </ElementsConsumer>
);

export default StripeForm;