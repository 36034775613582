// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';

export function* callCheckTopicUniqueWorker ({ payload: topic }) {
    try {
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/topic_unique?account_uid=${accountId}`,{
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                topic,
            }),
        });
        const { response: value, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put( uiActions.setUiState('isTopicUnique', value === 'yes' ) );
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`)
    }
}
