// Core
import { call, put, select  } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { projectActions } from '../../actions';
import { isNil } from 'ramda';

export function* callDeleteCommentWorker ({ payload: {comment_id, project_id, job_type, idea_number, field}}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const url = isNil(idea_number) ? '' : `/${idea_number}`;
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${job_type}/${project_id}/comments${url}/${comment_id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                field,
            }),
        });
        const { response: { html_content, revision_id }, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( job_type === 'ideas' ) {
            yield put(projectActions.updateIdeaRevisionField(field, html_content, idea_number, revision_id));
        } else if ( job_type === 'design' ) {

        } else {
            yield put(projectActions.updateProjectRevision(html_content));
        }
        yield put(projectActions.deleteCommentSuccess(comment_id));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
