// Core
import { takeEvery } from 'redux-saga/effects';

// Instruments
import { types } from '../types';
import { asyncTypes } from './asyncTypes';
import { callInitializeWorker } from './workers/initialize';
import { callPingWorker } from './workers/ping';
import { callGetVersionWorker } from './workers/getVersion';
import { callUploadBase64Worker } from './workers/uploadBase64';
import { callUploadFileWorker } from './workers/uploadFile';
import { callUploadFileFromUrlWorker } from './workers/uploadFileFromUrl';
import { callErrorReportWorker } from './workers/errorReport';
import { callCheckBriefLinksSharedWorker } from './workers/checkBriefLinksShared';
import { callValidateReferralCodeWorker } from './workers/validateReferralCode';
import { callGaTrackingDebugWorker } from './workers/gaTrackingDebug';

export const uiWatchers = Object.freeze({
    * watchInitialize () {
        yield takeEvery(types.INITIALIZE, callInitializeWorker);
    },
    * watchPing () {
        yield takeEvery(types.LOCATION_CHANGE, callPingWorker);
    },
    * watchGetVersion () {
        yield takeEvery(types.LOCATION_CHANGE, callGetVersionWorker);
    },
    * watchUploadBase64 () {
        yield takeEvery(asyncTypes.UPLOAD_BASE64_ASYNC, callUploadBase64Worker);
    },
    * watchUploadFile () {
        yield takeEvery(asyncTypes.UPLOAD_FILE_ASYNC, callUploadFileWorker);
    },
    * watchUploadFileFromUrl () {
        yield takeEvery(asyncTypes.UPLOAD_FILE_FROM_URL_ASYNC, callUploadFileFromUrlWorker);
    },
    * watchErrorReport () {
        yield takeEvery(asyncTypes.ERROR_REPORT_ASYNC, callErrorReportWorker);
    },
    * watchCheckBriefLinksShared () {
        yield takeEvery(asyncTypes.CHECK_BRIEF_ASYNC, callCheckBriefLinksSharedWorker);
    },
    * watchValidateReferralCode () {
        yield takeEvery(asyncTypes.VALIDATE_REFERRAL_CODE_ASYNC, callValidateReferralCodeWorker);
    },
    * watchGaTrackingDebug () {
        yield takeEvery(asyncTypes.GA_TRACKING_DEBUG_ASYNC, callGaTrackingDebugWorker);
    },
});
