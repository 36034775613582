// Core
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

// Components
import { Logo } from "./Logo";
import { MainMenu } from './MainMenu';
import { SubMenu } from './SubMenu';
import { UserNav } from './UserNav';

// Hooks
import { useLists } from "../../../hooks/useLists";
import { useOrder } from "../../../hooks/useOrder";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { routes } from '../../../helpers/routes';
import { isClientFn, isWhiteLabel, getPathByCurrency } from '../../../helpers/helpers';
import { isEmpty } from 'ramda';
import { domainGAC } from "../../../helpers/api";

const data = {
    calendar: 'plan',
    images: 'order',
    'order': 'order',
    'draft-projects': 'manage',
    'current-projects': 'manage',
    'completed-projects': 'manage',
};

export const Desktop = () => {
    /* State */
    const [path, setPath] = useState('');

    /* Hooks */
    const { staticData: { support_phone }} = useLists();
    const { isAuth } = useUi();
    const { details: { user_role = 'basicclient' }, proposal } = useUser();
    const { orderCurrency } = useOrder();
    const { pathname } = useLocation();

    const getPath = () => {
        return data[pathname.includes('order') ? 'order' : pathname.substring(1)];
    };

    /* Actions */
    const onPhoneClick = () => {
        window.onPhoneClick = true;
    };
    const onMainMenuHover = (hoveredPath) => {
        setPath(hoveredPath);
    };
    const onHeaderMouseLeave = () => {
        setPath(getPath());
    };

    /* Did update */
    useEffect(() => {
        setPath(getPath());
    }, [pathname]);

    let admin = {};
    const phone = support_phone.phone ? support_phone.phone : '';
    const isProposal = pathname.includes('proposal');
    const isWLA = isWhiteLabel() && !isAuth;
    if ( isProposal && !isEmpty(proposal) ) {
        ({ admin } = proposal);
    }
    let submenu = routes['manage'];
    if ( isClientFn(user_role) ) {
        submenu = routes['manage'].filter(o =>  o.path !== '/draft-projects' );
    }

    /* Html */
    const getPhone = () => {
        return <div className="gac-support-phone-wrap">
            { isProposal && !isEmpty(proposal)
                ? <a onClick = { onPhoneClick } href = { `tel:${ admin.phone.replace(/-/g, '') }` } className = 'gac-support-phone'>
                    <img src = { admin.avatar } alt = 'Manager avatar'/>
                    <i/>
                    <span>{ admin.phone }</span>
                </a>
                : phone && <a onClick = { onPhoneClick } href = { `tel:${ phone.replace(/-/g, '') }` } className = 'gac-support-phone'>
                <i/>
                <span>{ phone }</span>
            </a> }
            <a href = {`https://${domainGAC}.com/${getPathByCurrency(orderCurrency)}demo`} target='_blank' rel = 'noopener noreferrer' className='gac-btn gac-btn-s'>Book a demo</a>
        </div>
    };

    return <div className = 'gac-header' onMouseLeave = { onHeaderMouseLeave }>
        <div className = 'gac-header__top'>
            <Logo/>
            { !isProposal && !isWLA && <MainMenu onHover = { onMainMenuHover } /> }
            { !isWLA && <>
                { isAuth ? <UserNav/> : getPhone() }
            </> }
        </div>
        { path === 'manage' || '/draft-projects/current-projects/completed-projects'.includes(pathname)
            ? <SubMenu submenu = { submenu }/>
            : null }
    </div> ;
};