// Core
import { call, put } from 'redux-saga/effects';

// Instruments
import { api, authStr } from '../../../../helpers/api';
import { authActions } from '../../actions';
import { uiActions } from '../../../ui/actions';

export function* callAuthenticateWorker ({ payload: { publicKey, privateKey, accountId, sessid }}) {
    try {
        const response = yield call(
            fetch,
            `${api}/${publicKey}/v1/check?account_uid=${accountId}`, {
                method: 'GET',
                headers: {
                    Authorization: `${authStr} ${privateKey}`
                }
            });
        const {error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            yield put(authActions.authenticateFail());
            throw new Error(message);
        }

        yield put(authActions.authenticateSuccess({
            privateKey,
            publicKey,
            accountId,
            sessid,
        }));
    } catch (error) {
        yield put(authActions.authenticateFail(error));
    } finally {
        yield put(uiActions.initializeSuccess());
    }
}
