// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getProjectDetails, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { projectActions } from "../../actions";

export function* callExtendProjectDeadlineWorker ({ payload: project_id}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call( fetch, `${api}/${publicKey}/v1/projects/${project_id}/extend?account_uid=${accountId}`,{
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
            },
        });
        const { response: { deadline }, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const project = yield select(getProjectDetails);
        yield put(projectActions.setProjectState('details', { ...project, extended: 'yes', deadline } ));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
