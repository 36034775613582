// Core
import { useEffect, useRef, useState } from "react";

// Components
import RefIcon1 from '../../icons/RefIcon1';
import RefIcon2 from '../../icons/RefIcon2';

// Hooks
import { useUser } from "../../../hooks/useUser";
import { useModals } from "../../../hooks/useModals";

// Instruments
import { toLocaleString, getCreditAmount, isEpiic } from '../../../helpers/helpers';
import { domainGAC, epiic } from '../../../helpers/api';
import { isNil } from 'ramda';

export const ModalReferral = ({ closeModal, setPos }) => {
    /* Ref */
    const modalRef = useRef(null);
    const inputRef = useRef(null);

    /* State */
    const [isDashboard, setIsDashboard] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    /* Hooks */
    const { details, company: { branding: { host }}, getReferralIdAsync, getReferralInfoAsync } = useUser();
    const { referral_id, customer_count, total_earned, currency, email } = details;
    const { setModal } = useModals();

    /* Actions */
    const onMessageSupport = () => {
        setModal('messageSupport');
    };
    const onCopyToClipboard = () => {
        const el = inputRef.current;
        el.select();
        el.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(el.value);
        setIsCopied(true);
    };
    const onShowDashboard = () => {
        setIsDashboard(true);
    };
    const onJoinReferralClick = () => {
        getReferralIdAsync();
    };

    useEffect(() => {
        if ( !isNil(referral_id) ) {
            getReferralInfoAsync();
        }
    }, []);
    useEffect(() => {
        setPos( modalRef );
    }, [isDashboard, details]);

    const bodyGac = `I+know+we%27re+all+looking+for+ways+to+help+grow+our+business+while+spending+less+right+now+-+which+is+why+I+thought+you%27d+be+interested+in+this.%0A%0AI%27ve+been+saving+time+and+money+on+my+content+marketing+by+having+Get+A+Copywriter+handle+all+my+writing.+Blog+posts,+press+releases,+white papers,+you+name+it,+they%27ll+write+it+quickly,+and+at+a+great+price.+Plus,+they+offer+unlimited+revisions+so+you+don%27t+have+to+worry+about+risk.+I+definitely+think+your+business+could+benefit+too,+so+I+wanted+to+make+sure+you+had+the+chance+to+take+advantage.%0A%0AGive+it+a+try.+Place+your+order+%26+get+${getCreditAmount(currency)}+off+your+second+project+`;
    const bodyEpiic = `I+know+we%27re+all+looking+for+ways+to+help+grow+our+business+while+spending+less+right+now+-+which+is+why+I+thought+you%27d+be+interested+in+this.%0A%0AI%27ve+been+saving+time+and+money+on+my+design+projects+by+having+Epiic+handle+all+my+designs.+They%27ll+make+it+quickly,+and+at+a+great+price.+Plus,+they+offer+unlimited+revisions+so+you+don%27t+have+to+worry+about+risk.+I+definitely+think+your+business+could+benefit+too,+so+I+wanted+to+make+sure+you+had+the+chance+to+take+advantage.%0A%0AGive+it+a+try.+Place+your+order+%26+get+${getCreditAmount(currency)}+off+your+second+project+`;
    const twitterTextGac = `I’ve%20been%20saving%20a%20lot%20of%20%24%24%24%20(and%20time!)%20on%20my%20content%20marketing%20by%20using%20Get%20A%20Copywriter.%20I%20think%20you%20could%20save%20your%20business%20money%20too.%20Use%20this%20link%20to%20place%20an%20order%20%26%20get%20${getCreditAmount(currency)}%20off%20your%20second%20project`;
    const twitterTextEpiic = `I've%20been%20saving%20a%20lot%20of%20%24%24%24%20(and%20time!)%20on%20my%20design%20projects%20by%20using%20Epiic.%20I%20think%20you%20could%20save%20your%20business%20money%20too.%20Use%20this%20link%20to%20place%20an%20order%20%26%20get%20${getCreditAmount(currency)}%20off%20your%20second%20project`;

    /* Html */
    const getDesc = () => {
        return <div className = 'gac-referral-desc'>
            <div><i><RefIcon1/></i><p>You earn <span>5% commission</span> for 12 months</p></div>
            { !isEpiic(host) && <div><i><RefIcon2/></i><p>Your friend gets <span>{ getCreditAmount(currency) } credit</span> for their second order</p></div> }
        </div>
    };
    const getContent = () => {
        if ( isNil(referral_id) ) {
            return <div className="gac-referral-get-link">
                <span className="gac-btn gac-btn-s" onClick = { onJoinReferralClick }>Join referral program</span>
            </div> ;
        }
        const getLink = () => {
            return <div className = 'gac-referral-link'>
                <p>Copy and share the link across any platform</p>
                <div className="gac-referral-link-input">
                    <input ref = { inputRef } value={ `https://${ isEpiic(host) ? epiic : domainGAC }.com${ isEpiic(host) ? '/designers' : '' }?ref=${referral_id}` } type="text" readOnly/>
                    <span className={ `${isCopied ? 'gac-copied' : ''}` } onClick = { onCopyToClipboard }>{ isCopied ? 'Copied' : 'Copy' }</span>
                </div>
                <div className="gac-hint gac-hint-warning" onClick = { onMessageSupport }>
                    <span>Need a link without your name</span>
                    <div className="gac-hint-wrap"><i/></div>
                </div>
            </div> ;
        };
        const getLinks = () => {
            return <div className="gac-referral-links">
                <p>or click below to share via:</p>
                <div className="gac-referral-links-wrap">
                    <a target = '_blank' rel="noopener noreferrer" href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&su=Wanted+to+share+this+with+you&body=${ isEpiic(host) ? bodyEpiic : bodyGac}https://${isEpiic(host) ? epiic : domainGAC}.com${ isEpiic(host) ? '/designers' : '' }?ref=${referral_id}`}><i className="gac-icon-1"/></a>
                    <a target = '_blank' rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=https://${isEpiic(host) ? epiic : domainGAC}.com${ isEpiic(host) ? '/designers' : '' }?ref=${referral_id}`}><i className="gac-icon-2"/></a>
                    <a target = '_blank' rel="noopener noreferrer" href={`https://twitter.com/intent/tweet?text=${ isEpiic(host) ? twitterTextEpiic : twitterTextGac }%20https%3A%2F%2F${isEpiic(host) ? epiic : domainGAC}.com%3Fref%3D${referral_id}`}><i className="gac-icon-3"/></a>
                    <a target = '_blank' rel="noopener noreferrer" href="https://www.linkedin.com"><i className="gac-icon-4"/></a>
                </div>
            </div> ;
        };
        const getData = () => {
            if ( isNil(customer_count) || isNil(total_earned) ) return null;

            return <div className="gac-referral-data">
                <p>Earned <span>{currency}{ toLocaleString(`${total_earned}`)}</span></p>
                <p>Referrals <span>{customer_count}</span></p>
            </div> ;
        };
        const getDashboard = () => {
            return <div className="gac-referral-dashboard">
                { isDashboard
                    ? <div className="gac-referral-dashboard-data">
                        <a target = '_blank' rel="noopener noreferrer" href={`https://${epiic}.tapfiliate.com/login`}>{`https://${epiic}.tapfiliate.com`}</a>
                        <p><strong>User:</strong>{ email }</p>
                        <p><strong>Password:</strong><a target = '_blank' rel="noopener noreferrer" href={`https://${epiic}.tapfiliate.com/resetting/request`}>reset link</a></p>
                    </div>
                    : <span onClick = { onShowDashboard } className="gac-show-dashboard">Referral dashboard</span> }
            </div>
        };

        return <>
            { getLink() }
            { getLinks() }
            { getData() }
            { getDashboard() }
        </>
    };

    return <div ref = { modalRef } className = 'gac-modal gac-referral-modal'>
        <span className = 'gac-close-modal' onClick = { closeModal }/>
        <h1>Refer a friend</h1>
        { getDesc() }
        { getContent() }
    </div>
};