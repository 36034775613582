// Core
import { useEffect, useState } from "react";
import { CSSTransition } from 'react-transition-group';

// Components
import { Spinner } from '../../common/Spinner';

// Hooks
import { useModals } from "../../../hooks/useModals";
import { useUi } from "../../../hooks/useUi";

// Instruments
import { isFetching } from "../../../helpers/helpers";

export const ModalFeedBack = ({ title, classStr }) => {
    /* State */
    const [message, setMessage] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);

    /* Hooks */
    const { isSupportMessageSuccess: isSuccess, isSupportMessageFail: isFail, setModal, setModalState, createSupportMessageAsync } = useModals();
    const { fetching } = useUi();

    /* Did update */
    useEffect(() => {
        if ( isSuccess ) {
            setTimeout(() => {
                setModal(null);
                setModalState('isSupportMessageSuccess',false);
            }, 3000);
        }
    }, [isSuccess]);

    useEffect(() => {
        if ( isFail ) {
            setTimeout(() => {
                setModal(null);
                setModalState('isSupportMessageFail',false);
            }, 3000);
        }
    }, [isFail]);

    /* Actions */
    const onTextareaChange = (e) => {
        const value = e.target.value.replace(/ +(?= )/g, '');

        setMessage(value);
        if ( value.length < 2 !==  isInvalid ) setIsInvalid(value.length < 2);
    };

    const onSubmit = () => {
        if (message.length < 2) {
            setIsInvalid(true)
        } else {
            createSupportMessageAsync({
                feedback_text: message,
                referral_url: window.location.href,
            });
        }
    };

    return(
        <div className = { `gac-modal ${classStr}` }>
            <span className = 'gac-close-modal' onClick = { () => { setModal(null) } }/>
            <CSSTransition
                in = { !isSuccess }
                timeout = { 200 }
                exit = { false }
                unmountOnExit
                classNames = 'gac-opacity-200'>
                <>
                    <h2>{ title }</h2>
                    <textarea
                        className = { isInvalid ? 'gac-invalid' : '' }
                        cols = '30'
                        rows = '10'
                        value = { message }
                        data-hj-whitelist
                        onChange = { onTextareaChange }/>
                    <div className = 'gac-modal-btns'>
                        <span className = 'gac-btn gac-btn-s' onClick = { onSubmit }>Send</span>
                        { isFail
                            ? <div className = 'gac-sending-failed'>Sending failed. Please try again.</div>
                            : null }
                    </div>
                </>
            </CSSTransition>
            <CSSTransition
                in = { isSuccess }
                timeout = { 200 }
                exit = { false }
                unmountOnExit
                classNames = 'gac-opacity-200'>
                <>
                    <span className = 'gac-success-icon'>
                        <i/>
                    </span>
                    <h1>Thank you!</h1>
                </>
            </CSSTransition>
            <Spinner isSpinning = { isFetching(fetching) }/>
        </div>
    );
};