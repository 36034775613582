// Types
export const types = Object.freeze({
    FETCH_USER_DETAILS_SUCCESS:          'FETCH_USER_DETAILS_SUCCESS',
    FETCH_USER_DETAILS_FAIL:             'FETCH_USER_DETAILS_FAIL',
    FETCH_DATA_SUCCESS:                  'FETCH_DATA_SUCCESS',
    UPDATE_ACCOUNT_SUCCESS:              'UPDATE_ACCOUNT_SUCCESS',
    CREATE_ACCOUNT_SUCCESS:              'CREATE_ACCOUNT_SUCCESS',
    DELETE_ACCOUNT_SUCCESS:              'DELETE_ACCOUNT_SUCCESS',
    UPDATE_COPYWRITER_SUCCESS:           'UPDATE_COPYWRITER_SUCCESS',
    UPDATE_COPYWRITER_STATUS:            'UPDATE_COPYWRITER_STATUS',
    UPDATE_COPYWRITER_NOTES:             'UPDATE_COPYWRITER_NOTES',
    ADD_COPYWRITER_TO_BLACKLIST:         'ADD_COPYWRITER_TO_BLACKLIST',
    ADD_COPYWRITER_TO_ACCOUNT:           'ADD_COPYWRITER_TO_ACCOUNT',
    ADD_SUGGESTED_COPYWRITER_TO_ACCOUNT: 'ADD_SUGGESTED_COPYWRITER_TO_ACCOUNT',
    UPDATE_ORDER_COUNT:                  'UPDATE_ORDER_COUNT',
    // SET_SETTINGS_FORM_STATE:             'SET_SETTINGS_FORM_STATE',

    FETCH_SUBSCRIPTIONS_SUCCESS:         'FETCH_SUBSCRIPTIONS_SUCCESS',
    CREATE_SUBSCRIPTION_SUCCESS:         'CREATE_SUBSCRIPTION_SUCCESS',
    UPDATE_SUBSCRIPTION_SUCCESS:         'UPDATE_SUBSCRIPTION_SUCCESS',
    CREATE_PUBLICATION_SUCCESS:          'CREATE_PUBLICATION_SUCCESS',
    UPDATE_PUBLICATION_SUCCESS:          'UPDATE_PUBLICATION_SUCCESS',
    FETCH_CARDS_SUCCESS:                 'FETCH_CARDS_SUCCESS',
    DELETE_CARD_SUCCESS:                 'DELETE_CARD_SUCCESS',
    // NEW
    SET_USER_STATE:         'SET_USER_STATE',
    SET_USER_COMPANY_STATE: 'SET_USER_COMPANY_STATE',
});