// Core
import React, { Component } from 'react';
import type1 from '../../../theme/images/in-progress.png';
import type12x from '../../../theme/images/in-progress@2x.png';
import type2 from '../../../theme/images/waiting-for-pitches.png';
import type22x from '../../../theme/images/waiting-for-pitches@2x.png';
import type3 from '../../../theme/images/matching-a-writer.png';
import type32x from '../../../theme/images/matching-a-writer@2x.png';

export default class EmptyStatev2 extends Component {

    render () {
        const { type, status } = this.props;
        return(
            <div className = 'gac-empty-state-v2'>
                <div className = 'gac-empty-state-img'>
                    { type === 'type-1'
                        ? <img src={ type1 } srcSet={`${type12x} 2x`} alt='Status in progress'/>
                        : type === 'type-2'
                            ? <img src={ type2 } srcSet={`${type22x} 2x`} alt='Waiting for pitches'/>
                            : type === 'type-3' || type === 'type-4' || type === 'type-5'
                                ? <img src={ type3 } srcSet={`${type32x} 2x`} alt='Matching a writer'/>
                                : null }
                </div>
                <div className = 'gac-empty-state-text'>
                    { type === 'type-1'
                        ? <h1>{ status === 'matching'
                            ? <>Project in queue</>
                            : <><span>{ status }</span> in progress</> }
                        </h1>
                        : type === 'type-2'
                            ? <>
                                <h1>Waiting for pitches</h1>
                                <p>Matching writers for your project can take from a couple of minutes to several hours depending on the industry, topic, and brief details.</p>
                            </>
                            : type === 'type-3'
                                ? <>
                                    <h1>Matching a writer</h1>
                                    <p>You will receive an email notification once we award the project to a writer.</p>
                                </>
                                : type === 'type-4'
                                    ? <>
                                        <h1 style={{ marginBottom: 0 }}>No messages</h1>
                                    </>
                                    : type === 'type-5'
                                        ? <>
                                            <h1>Matching a designer</h1>
                                            <p>You will receive an email notification once we award the project to a designer.</p>
                                        </>
                                        : null }
                </div>
            </div>
        );
    }
}
