// Core
import { Suspense, lazy, useEffect } from 'react';

// Components
import { Spinner } from "../common/Spinner";

// Hooks
import { useModals } from "../../hooks/useModals";
import { useUser } from "../../hooks/useUser";
import { useUi } from "../../hooks/useUi";

// Instruments
import { getAppTitle } from '../../helpers/helpers';

const DesktopView = lazy(() => import('./DesktopView').catch(e => window.location.reload()));
const MobileView = lazy(() => import('./MobileView').catch(e => window.location.reload()));

export const Calendar = () => {
    /* Hooks */
    const { setModal } = useModals();
    const { company } = useUser();
    const { isMobile } = useUi();

    useEffect(() => {
        document.title = getAppTitle('Editorial calendar', company);

        return () => {
            setModal(null);
        };
    }, []);

    return(
        <Suspense fallback = { <Spinner isSpinning = { true }/> }>
            { isMobile ? <MobileView/> : <DesktopView/> }
        </Suspense>
    );
};

//  isMobile ? 'Editorial calendar' : <HeaderDesktop refreshCalendar = { () => {} } page = 'Editorial calendar'/> }