// Core
import { useEffect } from "react";

// Hooks
import { useModals } from "../useModals";
import { useProject } from "../useProject";

// Instruments
import { isEmpty, isNil } from "ramda";

export const useOnMouseLeave = (comments, isCurrentRevision) => {
    /* Hooks */
    const { activeModal, isRequestRevisionModalDisabled, setModal } = useModals();
    const { details } = useProject();
    const { status, editing } = details;

    useEffect(() => {
        const onMouseLeave = () => {
            if ( status !== 'approval' || editing || isNil(comments) || isEmpty(comments) || !isCurrentRevision || !isNil(activeModal) || isRequestRevisionModalDisabled ) return false;
            setModal('requestRevision');
        };

        document.querySelector('body').addEventListener('mouseleave', onMouseLeave);
        return () => {
            document.querySelector('body').removeEventListener('mouseleave', onMouseLeave);
        };
    }, [status, comments, activeModal, editing]);
};