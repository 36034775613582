// Instrument
import { types } from './types';
import { types as authTypes } from '../auth/types';
import update from "immutability-helper";

export const initialState = {
    duplicatedProject:   {},
    editedProject:       {},
    formProject:         null,
    formAction:          null,
    job_group:           null,
    job_type:            null,
    job_pay_type:        null,
    seoaudit_type:       null,
    orderCurrency:       '',
    topic_ideas_type:    'request',
};

export const orderReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_ORDER_STATE:
            return update(state, {
                [payload.prop]: {$set: payload.value},
            });

        case types.UPDATE_ORDER_STATE:
            return update(state, {$merge: payload});
        
        case authTypes.LOGOUT_SUCCESS:
            return Object.assign({}, initialState );

        default:
            return state;
    }
};
