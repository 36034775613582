// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { listsActions } from "../../actions";
import { uiActions } from '../../../ui/actions';

export function* callGetProjectsWorker ({ payload: { view_by, date_from, date_to, type, status, last100 }}) {
    const t    = typeof type === 'undefined' ? '' : '/schedule';
    const l    = typeof last100 === 'undefined' ? '' : '/last100';
    const s    = typeof status === 'undefined' ? '' : `status=${status}`;
    const v    = typeof view_by === 'undefined' ? '' : `view_by=${view_by}`;
    const from = typeof date_from === 'undefined' ? '' : `&date_from=${date_from}`;
    const to   = typeof date_to === 'undefined' ? '' : `&date_to=${date_to}`;

    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const uid = `&account_uid=${accountId}`;
        const response = yield call( fetch, `${api}/${publicKey}/v1/projects${l}${t}?${s}${v}${from}${to}${uid}`,{
            method: 'GET',
            headers: {
                Authorization: `${authStr} ${privateKey}`
            }
        });
        const { extra: { total_all }, response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const projects = data.map((project) => {
            if ( project.status === 'approved' && !project.rating ) {
                return Object.assign({}, project, {
                    status: 'auto',
                });
            }
            return project;
        });

        yield put(listsActions.setListsState('projects',projects));
        yield put(uiActions.setUiState('projectsTotal',total_all));
    } catch (error) {
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
