// Async types
export const asyncTypes = Object.freeze({
    FETCH_BRAINTREE_TOKEN_ASYNC: 'FETCH_BRAINTREE_TOKEN_ASYNC',
    FETCH_STRIPE_CARDS_ASYNC:    'FETCH_STRIPE_CARDS_ASYNC',
    DELETE_STRIPE_CARD_ASYNC:    'DELETE_STRIPE_CARD_ASYNC',
    CHECK_REFUND_DESIGN_ASYNC:   'CHECK_REFUND_DESIGN_ASYNC',
    PUT_REFUND_DESIGN_ASYNC:     'PUT_REFUND_DESIGN_ASYNC',
    RETRY_CHARGE_ASYNC:          'RETRY_CHARGE_ASYNC',
    STRIPE_COMPLETE_ASYNC:       'STRIPE_COMPLETE_ASYNC',
});
