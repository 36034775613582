// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getProjectDetails, authStr } from '../../../../helpers/api';
import { uiActions } from '../../../ui/actions';
import { projectActions } from "../../actions";
import { isNil } from 'ramda';

export function* callSetEditingProjectWorker ({ payload: { project_id, editing }}) {
    try {
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call( fetch, `${api}/${publicKey}/v1/projects/${project_id}/set-editing`,{
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                account_uid: accountId,
                editing
            }),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        const project = yield select(getProjectDetails);

        if ( !isNil(project.editing) && project.editing !== data.editing ) {
            yield put(projectActions.setProjectState('details', { ...project, editing: data.editing }));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
        }));
    }
}
