// Core
import { call, put } from 'redux-saga/effects';

// Instruments
import { api } from '../../../../helpers/api';
import { uiActions } from "../../actions";

export function* callValidateReferralCodeWorker ({ payload: code }) {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(fetch, `${api}/v1/code/validate/${code}`,{
            method: 'GET',
        });

        yield put(uiActions.setUiState('isReferralCodeValid', response.status === 200));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('isReferralCodeValid', false));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
