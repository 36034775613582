// Core
import { useNavigate } from 'react-router-dom';

// Components
import { PayPalButtons } from "@paypal/react-paypal-js";

// Hooks
import { useAuth } from "../../hooks/useAuth";
import { usePayments } from "../../hooks/usePayments";
import { useSocket } from "../../hooks/useSocket";
import { useUi } from "../../hooks/useUi";
import { useUser } from "../../hooks/useUser";
import { useLists } from "../../hooks/useLists";

// Instruments
import { api, authStr, isStage } from "../../helpers/api";
import { notifications } from "../../helpers/notifications";
import { purchaseTrack, getAmountByCurrency } from "../../helpers/helpers";

export const PayPalForm = ({ type }) => {
    /* Hooks */
    const { keys: { privateKey, publicKey, accountId: account_uid }} = useAuth();
    const { pusher: { sessionID }} = useSocket();
    const { activeBundleAccount, activeBundle, company: { branding: { host }}, details: { email }, prepayAmount, updateOrderCount, fetchBalanceAsync } = useUser();
    const { setPaymentsState } = usePayments();
    const { bundlesRef, setUiState, enableFetching, disableFetching, gaTrackingDebugAsync } = useUi();
    const { staticData: { currencies }, setListsState, getOrderListAsync } = useLists();
    const navigate = useNavigate();

    /* Actions */
    const getBtnStyles = () => {
        return {
            size: 'large',
            color: 'gold',
            shape: 'pill',
            height: 48,
            layout: 'horizontal',
            fundingicons: 'false',
            tagline: false,
            label: 'pay',
        };
    };
    const createOrder = async () => {
        setUiState('isBlocking', true);
        const response = await fetch(`${api}/${publicKey}/v1/projects/pay`, {
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                account_uid,
                payment_method : 'paypal',
                pusherData: {
                    sessionID
                }
            }),
        });

        if ( response.status === 410 ) {
            fetchBalanceAsync(account_uid);
            getOrderListAsync(navigate);
        }
        if (response.status !== 200) {
            throw new Error('Payment failed. Please try again or contact support.');
        }

        const { response: { paypal: { orderId }}} = await response.json();
        setPaymentsState('paypal', { orderId });

        return orderId;
    };
    const createOrderBundle = async () => {
        const { name, amount } = activeBundle;
        const isCustom = name === 'Custom';
        const isPrepay = type === 'prepay';

        enableFetching();
        setUiState('isBlocking', true);
        const response = await fetch(`${api}/${publicKey}/v1/bundles/pay`, {
            method: 'PUT',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                account_uid: activeBundleAccount,
                payment_method : 'paypal',
                name: isPrepay ? null : isCustom ? 'Start' : name,
                amount: isPrepay ? prepayAmount : isCustom ? amount : null,
                prepay: isPrepay ? 1 : null,
                pusherData: {
                    sessionID
                },
            }),
        });

        if (response.status !== 200) {
            throw new Error('Payment failed. Please try again or contact support.');
        }

        const { response: { paypal: { orderId }}} = await response.json();

        return orderId;
    };
    const onError = () => {
        setUiState('notification', {
            msg: 'Payment failed. Please try again or contact support.',
            type: 'error',
            fn: null,
        });
        setUiState('isBlocking', false);
    };
    const onCancel = () => {
        setUiState('isBlocking', false);
        if ( type === 'bundle' || type === 'prepay' ) {
            disableFetching()
        }
    };
    const onApprove = (data, actions) => {
        return actions.order.capture().then(
            async (details) => {
                setPaymentsState('paypal', {});
                const response = await fetch(`${api}/${publicKey}/v1/paypal/complete-order`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `${authStr} ${privateKey}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        orderId: data.orderID,
                        captureId: details.purchase_units[0].payments.captures[0].id,
                        account_uid,
                        pusherData: {
                            sessionID
                        }
                    })
                });

                if ( !isStage ) {
                    const { currency_code, value } = details.purchase_units[0].amount;
                    const { amount_usd, rate } = getAmountByCurrency(value, currency_code, currencies);

                    purchaseTrack(amount_usd, host, email);
                    gaTrackingDebugAsync({
                        amount: `${value}`,
                        currency: currency_code,
                        email,
                        items: `type: ${type}`,
                        notes: `host: ${host}, amount_usd: ${amount_usd}, rate: ${rate}, payment_method: paypal, account_uid: ${account_uid}, gtag: ${window.gtag}, url: ${window.location.href}, params: ${window.location.search}`
                    });
                }

                if ( type === 'project' ) {
                    navigate('/current-projects');
                    setListsState('orderList', []);
                    updateOrderCount(account_uid,'0');
                    setUiState('isBlocking', false);
                    setUiState('notification', notifications.orderSuccess);
                } else if ( type === 'bundle' || type === 'prepay' ) {
                    fetchBalanceAsync(account_uid);
                    setUiState('isBlocking', false);

                    if ( bundlesRef === 'transactions' ) {
                        navigate('/transactions');
                        setUiState('bundlesRef',null);
                    } else {
                        navigate('/order/confirm');
                    }
                    disableFetching();
                }

                return response;
            }
        );
    };

    return <PayPalButtons
        style = { getBtnStyles() }
        createOrder = { type === 'bundle' || type === 'prepay' ? createOrderBundle : createOrder }
        onError = { onError }
        onCancel = { onCancel }
        onApprove = { onApprove } /> ;
};