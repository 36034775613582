// Core
import { takeEvery } from 'redux-saga/effects';

// Instruments
import { asyncTypes } from './asyncTypes';
import { callAddProjectToOrderWorker } from './workers/addProjectToOrder';
import { callRemoveProjectFromOrderWorker } from './workers/removeProjectFromOrder';
import { callOrderPayWorker } from './workers/orderPay';
import { callBundlesPayWorker } from './workers/bundlesPay';

export const orderWatchers = Object.freeze({
    * watchAddProjectToOrder () {
        yield takeEvery(asyncTypes.ADD_PROJECT_TO_ORDER_ASYNC, callAddProjectToOrderWorker);
    },
    * watchRemoveProjectFromOrder () {
        yield takeEvery(asyncTypes.REMOVE_PROJECT_FROM_ORDER_ASYNC, callRemoveProjectFromOrderWorker);
    },
    * watchOrderPay () {
        yield takeEvery(asyncTypes.ORDER_PAY_ASYNC, callOrderPayWorker);
    },
    * watchBundlesPay () {
        yield takeEvery(asyncTypes.BUNDLES_PAY_ASYNC, callBundlesPayWorker);
    },
});
