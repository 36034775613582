// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, getUsers, authStr } from '../../../../helpers/api';
import { listsActions } from "../../../lists/actions";
import { uiActions } from '../../../ui/actions';
import animateScrollTo from 'animated-scroll-to';

export function* callCreateUserWorker ({ payload: { user, action, navigate }}) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/users`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(user),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }
        let msg = 'User created';

        if ( action === 'create_user' ) {
            navigate(`/users`);
            animateScrollTo(0);
        } else if ( action === 'create_client' ) {
            msg = `Invite sent to ${user.email}`;
        }

        const users = yield select(getUsers);
        yield put(listsActions.setListsState('users', [ ...users, data ]));
        yield put(uiActions.setUiState('notification', {
            msg,
            type: 'success',
            fn: null,
        }));
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
