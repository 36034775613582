// Core
import React, { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Components
import { Spinner } from '../components/common/Spinner';
import { NoMatch } from "../components/common/NoMatch";
import { LoginPage } from '../components/LoginPage';
import { CompanyNew } from '../components/CompanyNew';
import { ProjectDetails } from "../components/ProjectDetails";
import { OrderPage } from "../components/OrderPage";

// Hooks
import { useUser } from "../hooks/useUser";

// Instruments
import { isWhiteLabel, isEpiic } from '../helpers/helpers';
import { isStage } from "../helpers/api";

const Proposal = lazy(() => import('../components/Proposal/index').catch(() => window.location.reload()));
const ApplyDesigners = lazy(() => import('../components/ApplyDesigners').catch(() => window.location.reload()));
const ApplyDesignersSuccess = lazy(() => import('../components/ApplyDesigners/ApplyDesignersSuccess').catch(() => window.location.reload()));

export const Public = () => {
    /* Hooks */
    const { fetchDataByIpAsync } = useUser();

    /* Did mount */
    useEffect(() => {
        if ( !isStage ) fetchDataByIpAsync();
        document.body.classList.add('gac-not-logged');

        return () => {
            document.body.classList.remove('gac-not-logged');
        };
    }, []);

    //TODO add new page to redirect to /login
    const redirectToLogin = () => {
        return [
            '', '/', '/draft-projects', '/current-projects', '/completed-projects', '/calendar', '/images', '/order',
            '/order/duplicate', '/order/drafts', '/order/confirm', '/payments', '/transactions', '/accounts',
            '/account/new', '/users', '/user/new', '/settings', '/settings/billing', '/designers/plans', '/designers/pricing',
            '/writers/pricing', '/bundles', '/prepay', '/payment/confirm', '/payment/success', '/subscriptions',
            '/order/duplicate/:id', '/order/:id', '/account/:id/billing', '/account/:id/team', '/account/:id/client',
            '/account/:id', '/user/:id/role', '/user/:id', '/project/:id/brief', '/project/:id/messages', '/subscriptions/:id',
            '/website-developers/pricing'
        ]
            .map(path => <Route key = {`id-${path}`} path = { path } element = { <Navigate to = '/login' /> } /> );
    };
    const login = () => {
        return ['/login', '/password', '/password-update', '/password-create']
            .map(path => <Route key = {`id-${path}`} path = { path } element = { <LoginPage/> } /> );
    };

    return <Suspense fallback = { <Spinner isSpinning = { true }/> }>
        <Routes>
            { redirectToLogin() }
            { login() }

            <Route path = '/proposal/:proposalId' element = { <Proposal/> } />

            <Route path = '/order/new' element = { isWhiteLabel() ? <NoMatch/> : <OrderPage page = 'new'/> } />

            <Route path = '/shared/:projectId/:shareCode' element = { <Navigate to = '/project/:projectId/content/:shareCode' /> } />

            <Route path = '/project/:projectId/content/:shareCode' element = { isWhiteLabel() ? <ProjectDetails page = 'content'/> : <Navigate to = '/login' /> } />
            <Route path = '/project/:projectId/content' element = { <Navigate to = '/login' /> } />
            <Route path = '/project/:projectId' element = { <Navigate to = '/login' /> } />

            <Route path = '/company/new' element = { <CompanyNew/> } />

            <Route path = '/apply/designers/ru/success' element = { isEpiic(null) ? <ApplyDesignersSuccess lang='ru'/> : <NoMatch/> } />
            <Route path = '/apply/designers/ru' element = { isEpiic(null) ? <ApplyDesigners lang='ru'/> : <NoMatch/> } />
            <Route path = '/apply/designers/success' element = { isEpiic(null) ? <ApplyDesignersSuccess lang='en'/> : <NoMatch/> } />
            <Route path = '/apply/designers' element = { isEpiic(null) ? <ApplyDesigners lang='en'/> : <NoMatch/> } />

            <Route path = '/403' element = { <NoMatch /> } />
            <Route path = '*' element = { <NoMatch /> } />
        </Routes>
    </Suspense> ;
};
