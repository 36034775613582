// Instruments
import { types } from './types';

export const userActions = Object.freeze({
    /* Accounts */
    createAccountSuccess: (account) => ({
        type:    types.CREATE_ACCOUNT_SUCCESS,
        payload: account,
    }),
    updateAccountSuccess: (account) => ({
        type:    types.UPDATE_ACCOUNT_SUCCESS,
        payload: account,
    }),
    deleteAccountSuccess: (id) => ({
        type:    types.DELETE_ACCOUNT_SUCCESS,
        payload: id,
    }),
    updateCopywriterSuccess: (copywriter) => ({
        type: types.UPDATE_COPYWRITER_SUCCESS,
        payload: copywriter,
    }),
    updateCopywriterStatus: (copywriter) => ({
        type: types.UPDATE_COPYWRITER_STATUS,
        payload: copywriter,
    }),
    updateCopywriterNotes: (copywriter) => ({
        type: types.UPDATE_COPYWRITER_NOTES,
        payload: copywriter,
    }),
    addCopywriterToBlacklist: (copywriter) => ({
        type: types.ADD_COPYWRITER_TO_BLACKLIST,
        payload: copywriter,
    }),
    addCopywriterToAccount: (copywriter) => ({
        type: types.ADD_COPYWRITER_TO_ACCOUNT,
        payload: copywriter,
    }),
    addSuggestedCopywriterToAccount: (copywriter) => ({
        type: types.ADD_SUGGESTED_COPYWRITER_TO_ACCOUNT,
        payload: copywriter,
    }),
    updateOrderCount: (account, order_count) => ({
        type: types.UPDATE_ORDER_COUNT,
        payload: {
            account,
            order_count,
        },
    }),
    /* Publications */
    createPublicationSuccess: (publication, account_uid) => ({
        type:    types.CREATE_PUBLICATION_SUCCESS,
        payload: {
            publication,
            account_uid
        },
    }),
    updatePublicationSuccess: (publication) => ({
        type: types.UPDATE_PUBLICATION_SUCCESS,
        payload: publication,
    }),
    /* Stripe Cards */
    fetchCardsSuccess: (type, cards) => ({
        type: types.FETCH_CARDS_SUCCESS,
        payload: {
            type,
            cards
        },
    }),
    deleteCardSuccess: (type, id) => ({
        type: types.DELETE_CARD_SUCCESS,
        payload: {
            type,
            id
        },
    }),
    // NEW
    setUserState: (prop, data) => ({
        type: types.SET_USER_STATE,
        payload: {
            prop,
            data
        },
    }),
    setUserCompanyState: (prop, data) => ({
        type: types.SET_USER_COMPANY_STATE,
        payload: {
            prop,
            data
        },
    }),
});
