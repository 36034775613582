// Core
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams, Link } from 'react-router-dom';

// Components
import ReactPlayer from 'react-player/file';
import TextareaAutosize from 'react-textarea-autosize';
import EmptyStatev2 from "../../../common/EmptyStatev2";
import { RevisionsBtns } from "../../RevisionsBtns";

// Icons
import Arrow from '../../../icons/Arrow';
import Reload from '../../../icons/Reload';
import Share from '../../../icons/Share';
import Download from '../../../icons/Download';
import CommentAdd from "../../../icons/CommentAdd";
import CommentClose from "../../../icons/CommentClose";
import CommentEdit from "../../../icons/CommentEdit";
import CommentDelete from "../../../icons/CommentDelete";

// Hooks
import { useAuth } from "../../../../hooks/useAuth";
import { useDropzoneEl } from "../../../../hooks/custom/useDropzoneEl";
import { useOnMouseLeave } from "../../../../hooks/custom/useOnMouseLeave";
import { useModals } from "../../../../hooks/useModals";
import { usePayments } from "../../../../hooks/usePayments";
import { useProject } from "../../../../hooks/useProject";
import { useSocket } from "../../../../hooks/useSocket";
import { useUi } from "../../../../hooks/useUi";
import { useUser } from "../../../../hooks/useUser";

// Instruments
import { regexUrls } from "../../../../helpers/constants";
import {
    getDuration,
    isClientFn,
    getShortFileName,
    bytesToSize,
    onOutsideElClick,
    isFetching,
    getCutoffTime,
    getProjectStatus
} from "../../../../helpers/helpers";
import {
    bindAddCommentEvent,
    bindDeleteCommentEvent,
    bindUpdateCommentEvent,
    bindUpdateCommentNumbersEvent,
    bindUpdateProjectEvent,
    subCommentsChannel,
    unsubCommentsChannel,
} from '../../../../init/pusher';
import { includes, indexOf, isEmpty, isNil } from 'ramda';
import moment from 'moment/moment';
import animateScrollTo from 'animated-scroll-to';

const Entities = require('html-entities').XmlEntities;
const entities = new Entities();
const maxSize = 200000000; // = 200Mb = 200000000 bytes

export const Motion = () => {
    /* Ref */
    const contentRef = useRef(null);
    const containerRef = useRef(null);
    const textareaRef = useRef(null);
    const listRef = useRef(null);
    const listWrapRef = useRef(null);
    const mobileCommentsRef = useRef(null);

    /* State */
    const [initialSlide, setInitialSlide] = useState(0);
    const [stateRevision, setStateRevision] = useState(null);
    const [commentText, setCommentText] = useState('');
    const [newCommentText, setNewCommentText] = useState('');
    const [activeComment, setActiveComment] = useState(null);
    const [editingComment, setEditingComment] = useState(null);
    const [isCommentFormError, setIsCommentFormError] = useState(false);
    const [isEmptyComments, setIsEmptyComments] = useState(false);
    const [isSubmitRequest, setIsSubmitRequest] = useState(false);
    const [isDownloadList, setIsDownloadList] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [files, setFiles] = useState([]);

    /* Upload files */
    const onUpdateFiles = (prop, value, id) => {
        if ( prop === 'files' ) {
            setFiles(state => [ ...state, ...value ]);
        } else {
            if ( isNil(id) ) {
                setAttachments(state => [ ...state, ...value.map(o => ({ file_id: o.id, filename: o.name, size: o.size, url: o.url })) ]);
            } else {
                setAttachments(state => [ ...state.filter(o => o.file_id !== id), ...value ]);
            }
        }
    };
    const onRemoveFile = (prop, value) => {
        if ( prop === 'files' ) {
            setFiles(value);
        } else {
            setAttachments(value);
        }
    };

    /* Hooks */
    const { progress, getFilesHtml, getDropzoneComments } = useDropzoneEl(attachments, files, onUpdateFiles, onRemoveFile, maxSize, [], () => {}, '', '', 'files', 'attachments');
    const {
        details,
        revisions,
        comments,
        setProjectState,
        approveProjectAsync,
        reviewProjectAsync,
        createCommentAsync,
        updateCommentAsync,
        deleteCommentAsync,
        fetchProjectCommentsAsync,
        fetchProjectRevisionsAsync,
        requestRevisionAsync,
    } = useProject();
    const { project_id, status, job_type, revision, topic, editing, deadline, client_reviewed, logged_user, rating, feedback, pay_type, is_finished } = details;

    const { keys: { accountId }} = useAuth();
    const { checkRefundDesignAsync } = usePayments();
    const { setModal } = useModals();
    const { pusher, channels: { projectChannel, commentsChannel }} = useSocket();
    const { company: { subscriptions }, details: { user_role, user_id }} = useUser();
    const { isAuth, isMobile, isFakeUser, fetching, setUiState } = useUi();
    const { shareCode } = useParams();

    /* Actions */
    const getCommentsData = () => {
        if ( isNil(revisions) || isNil(stateRevision) || isNil(revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files) ) return [];
        const revisionFiles = revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files;

        return isNil(comments) || isNil(revisionFiles) ? [] : comments.filter(o => revisionFiles.some(a => a.file_id === o.file_id));
    };
    const getMaxRevision = (arr) => {
        if ( isNil(arr) || isEmpty(arr) ) return null;
        return Math.max(...arr.map(o => o.revision_number));
    };
    const getJobType = (type) => {
        return type === 'keywords' ? 'Keyword research' : type;
    };
    const getRevisionBtnsWidth = () => {
        const wrap = document.querySelectorAll(`.gac-project-wrapper`)[0];
        const width = wrap.offsetWidth;

        return isMobile ? width - 34 : width - 39 - 218 - 66;
    };
    const onResize = () => {
        const el = document.querySelectorAll(`.gac-revision-btns`)[0];
        if ( !isNil(el) ) {
            el.style.maxWidth = `${ getRevisionBtnsWidth() }px`;
        }
    };
    const onRevisionChange = (newRevision, newInitialSlide) => {
        if ( revision !== Number(newRevision) || initialSlide !== Number(newInitialSlide) ) {
            setStateRevision(Number(newRevision));
            setInitialSlide(newInitialSlide);
            setCommentText('');
            setIsCommentFormError(false);
            setIsEmptyComments(false);
            setIsDownloadList(false);
            setActiveComment(null);
            setEditingComment(null);
        }
    };

    /* Comments */
    const slideMobileComment = ({ currentTarget: { dataset: { action }}}) => {
        const revisionFiles = revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files;
        const commentsData = comments.filter(o => revisionFiles.some(a => a.file_id === o.file_id));
        const idx = indexOf(activeComment, commentsData.map(o => o.id));
        const i = action === 'prev' ? idx - 1 : idx + 1;

        if ((action === 'prev' && idx !== 0) || ( action === 'next' && idx + 1 < commentsData.length )) {
            setActiveComment(commentsData[i].id);
        }
    };
    const setCommentActive = ({ currentTarget: { dataset: { id }}}) => {
        if ( `${activeComment}` !== `${id}` ) {
            setActiveComment(id);
            setFiles([]);
            setAttachments([]);
            setEditingComment(null);
            setIsCommentFormError(false);
        }
    };
    const onCommentFormClose = () => {
        if ( !isFetching(fetching) && isEmpty(progress) ) {
            setCommentText('');
            setFiles([]);
            setAttachments([]);
            setEditingComment(null);
            setActiveComment(null);
            setIsCommentFormError(false);
        }
    };
    const onCommentTextareaChange = ({ currentTarget: { value }}) => {
        if ( value.length < 1500 ) {
            setCommentText(value);
            setIsCommentFormError(false);
        }
    };
    const onNewCommentTextareaChange = ({ currentTarget: { value }}) => {
        if ( value.length < 1500 ) {
            setNewCommentText(value);
            setIsEmptyComments(false);
            setIsCommentFormError(false);
        }
    };
    const onCommentTextareaKeyDown = ({ key }) => {
        if(key === 'Escape'){
            onCommentFormClose();
        }
    };

    const onCommentEdit = (e) => {
        const { currentTarget: { dataset: { id }}} = e;
        e.preventDefault();
        if ( `${editingComment}` !== `${id}` ) {
            let commentsFiles = comments.filter(o => `${o.id}` === `${id}` )[0].attachments;
            let files = [];
            const commentText = comments.filter(o => `${o.id}` === `${id}`)[0].comment;
            if ( !isNil(commentsFiles) ) {
                files = commentsFiles.map(o => ({
                    ...o,
                    file_id: o.file_id,
                }));
            }

            setEditingComment(id);
            setCommentText(commentText);
            setFiles(files);
            setAttachments(isNil(commentsFiles) || isEmpty(commentsFiles) ? [] : commentsFiles);
        }
    };
    const onCommentUpdate = ({ currentTarget: { dataset: { id }}}) => {
        if ( !isFetching(fetching) && isEmpty(progress) ) {
            if (commentText.length) {
                const attachment = files.map(o => o.file_id);
                updateCommentAsync(entities.encode(entities.decode(commentText)), id, project_id, job_type, null, attachment);

                setEditingComment(null);
                setActiveComment(null);
                setCommentText('');
                setFiles([]);
                setAttachments([]);
                setIsCommentFormError(false);
            } else {
                setIsCommentFormError(true);
            }
        }
    };
    const onCommentDelete = ({ currentTarget: { dataset: { id }}}) => {
        if ( !isFetching(fetching) && isEmpty(progress) ) {
            setFiles([]);
            setAttachments([]);
            deleteCommentAsync(id, project_id, job_type);
        }
    };
    const onCommentSubmit = () => {
        if ( !isFetching(fetching) && isEmpty(progress) ) {
            const revisionFiles = revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files;
            const previewVideos = revisionFiles.filter(o => o.is_preview);

            if ( newCommentText.length ) {
                const attachment = files.map(o => o.file_id);
                const left = 0;
                const top = 0;
                const width = 0;
                const file_id = previewVideos[0].file_id;
                createCommentAsync(project_id, job_type, null, entities.encode(entities.decode(newCommentText)), null, null, null, file_id, left, top, width, attachment);
                setNewCommentText('');
                setFiles([]);
                setAttachments([]);
            } else {
                setIsCommentFormError(true);
            }
        }
    };

    const onSourceLinkClick = (e) => {
        const { currentTarget: { dataset: { href }}} = e;
        e.preventDefault();

        checkRefundDesignAsync(href);
    };
    const onToggleDownloadList = () => {
        setIsDownloadList(state => !state);
    };

    /* Submit */
    const onProjectApprove = () => {
        if ( isNil(rating) ) {
            setModal('rateWriter');
        } else {
            approveProjectAsync({ project_id, project_score: rating, project_feedback: feedback });
        }
    };
    const onProjectReview = () => {
        if ( !isFetching(fetching) ) {
            reviewProjectAsync({ project_id, 'code': shareCode });
        }
    };
    const onRequestRevision = () => {
        if ( !isFetching(fetching) ) {
            if ( isNil(comments) || isEmpty(comments) ) {
                animateScrollTo(document.getElementById('textarea'), {
                    verticalOffset: -100,
                });
                setIsEmptyComments(true);
                setIsCommentFormError(true);
            } else {
                requestRevisionAsync({ project_id, job_type });
            }
        }
    };

    let isCurrentRevision = !isEmpty(revisions) && stateRevision === getMaxRevision(revisions);
    useOnMouseLeave(getCommentsData(), isCurrentRevision);

    useEffect(() => {
        window.addEventListener('resize', onResize, true);

        if ( 'revision/editing/approval/approved/published'.includes(status)
            || (job_type === 'design' && status === 'matching' && revision ) ) {
            fetchProjectRevisionsAsync({ project: { project_id, job_type } });
            fetchProjectCommentsAsync({ project: { project_id, job_type } });
        }

        if ( isClientFn(user_role) && status === 'approval' ) {
            setIsEmptyComments(true);
        }

        return () => {
            window.removeEventListener('resize', onResize, true);
            setProjectState('comments', null);
            setProjectState('revisions', null);
            setUiState('shouldRequestRevision',false);
        };
    }, []);
    useEffect(() => {
        if ( !isEmpty(pusher) ) {
            subCommentsChannel(pusher, project_id);
        }

        return () => {
            if ( !isEmpty(pusher) ) {
                unsubCommentsChannel(pusher,project_id);
            }
        };
    }, [pusher]);
    useEffect(() => {
        if ( !isNil(projectChannel) ) {
            bindUpdateProjectEvent(projectChannel);
            bindUpdateCommentNumbersEvent(projectChannel);
        }
    }, [projectChannel]);
    useEffect(() => {
        if ( !isNil(commentsChannel) ) {
            bindAddCommentEvent(commentsChannel);
            bindUpdateCommentEvent(commentsChannel);
            bindDeleteCommentEvent(commentsChannel);
        }
    }, [commentsChannel]);
    useEffect(() => {
        if ( !isNil(revisions) && !isEmpty(revisions) && !isNil(comments) ) {
            setStateRevision(getMaxRevision(revisions));
        }
    }, [revisions, comments]);
    useEffect(() => {
        if ( !isNil(comments) && !isEmpty(comments) ) {
            setEditingComment(null);
            setActiveComment(null);
            setCommentText('');
            setIsCommentFormError(false);
        }
    }, [comments]);
    useLayoutEffect(() => {
        if ( !isNil(comments) && !isNil(revisions) && !isNil(stateRevision) && !isNil(revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files) && stateRevision === getMaxRevision(revisions) ) {
            const revisionFiles = revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files;
            if ( !isEmpty(comments.filter(o => revisionFiles.some(a => a.file_id === o.file_id))) ) {
                setIsSubmitRequest(true);
            }
        }
        if ( isEmpty(comments) || isNil(comments) || isNil(revisions) || isNil(stateRevision) || isNil(revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files) ) {
            setIsSubmitRequest(false);
        }
    }, [comments, revisions, stateRevision]);
    useEffect(() => {
        if ( isMobile && !isEmpty(comments) && !isNil(comments) && !isNil(revisions) && !isNil(stateRevision) && !isNil(revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files) ) {
            const revisionFiles = revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files;
            const commentsData = comments.filter(o => revisionFiles.some(a => a.file_id === o.file_id));
            if ( !isEmpty(commentsData) ) {
                setActiveComment(commentsData[0].id);
            }
        } else {
            setActiveComment(null);
        }
    }, [isMobile, comments, revisions, stateRevision]);
    useLayoutEffect(() => {
        const onOutsideClick = (e) => {
            onOutsideElClick(e,listWrapRef.current,() => { setIsDownloadList(false) });
        };

        if ( isDownloadList ) {
            if ( !isNil(listRef.current) ) {
                const h = window.innerHeight;
                const { top, height } = listRef.current.getBoundingClientRect();
                if ( top + height > h ) {
                    listRef.current.style.top = `-${height}px`
                }
            }
            document.addEventListener('click', onOutsideClick, true);
            document.addEventListener('touchstart', onOutsideClick, true);
        }

        return () => {
            document.removeEventListener('click', onOutsideClick, true);
            document.removeEventListener('touchstart', onOutsideClick, true);
        };
    }, [isDownloadList]);
    useLayoutEffect(() => {
        const content = document.querySelector('.gac-project-content');
        if ( isNil(mobileCommentsRef.current) ) {
            content.style.marginBottom = '0px';
        } else {
            const height = mobileCommentsRef.current.offsetHeight;
            content.style.marginBottom = `${height}px`;
        }
    }, [mobileCommentsRef.current, activeComment]);

    /* Html */
    const getTopic = () => {
        if ( isMobile ) return null;
        return <div className="gac-project-title">{ topic }</div>;
    };

    if ( 'writing/designing/pitching/editing'.includes(status) || (status === 'matching' && !revision) ) {
        const emptyStatus = status === 'writing' ? getJobType(job_type) : status === 'editing' ? 'reviewing' : status;

        return <div className='gac-project-content'>
            { getTopic() }
            <EmptyStatev2 status = { emptyStatus } type = 'type-1'/>
        </div>;
    }

    const getRevisionsBtns = () => {
        if ( isNil(revisions) || isNil(stateRevision) || revisions.length < 2 ) return null;

        return <RevisionsBtns
            revisions = { revisions }
            type = 'design'
            currentRevision = { stateRevision }
            initialSlide = { initialSlide }
            isMobile = { isMobile }
            maxWidth = { getRevisionBtnsWidth() }
            onRevisionChange = { onRevisionChange } /> ;
    };
    const getHtml = () => {
        if ( isNil(revisions) || isNil(stateRevision) || isNil(revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files) ) return null;

        const revisionFiles = revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files;
        const previewImgs = revisionFiles.filter(o => o.is_preview);
        const previewData = previewImgs
            // .sort((a, b) => a.sequence - b.sequence)
            .sort((a, b) => a['filename'].localeCompare(b['filename']))
            .map((o, i) => {
                return <div key = { i } className = {`gac-preview-images__item ${ i + 1 === previewImgs.length ? '' : 'gac-with-border' }`}>
                    { previewImgs.length > 1 && <div className="gac-preview-images__label">Preview {i+1}</div> }
                    <div className="gac-preview-images__wrap">
                        { includes('mp4', o.filename)
                            ? <ReactPlayer url = { o.url } controls = { true } width = '100%' height = 'auto' />
                            : <img id = { o.file_id } src = { o.url } alt = { `Preview - ${o.file_id}` } /> }
                    </div>
                </div> ;
            });
        const isPreview = !isEmpty(previewImgs);

        return isPreview ? <div className='gac-preview-images'>{ previewData }</div> : <EmptyStatev2 status = { status } type = 'type-1'/> ;
    };
    const getTextareaAutosize = (props, value) => {
        return <TextareaAutosize
            { ...props }
            ref = { textareaRef }
            minRows = { 4 }
            maxRows = { 8 }
            value = { value }
            onKeyDown = { onCommentTextareaKeyDown }
            onChange = { onCommentTextareaChange }/> ;
    };
    const getComments = () => {
        if ( isMobile ) return null;

        const commentsData = getCommentsData().map(({ comment, id, date_create, author, attachments }) => {
            let files = [];
            if ( !isNil(attachments) ) {
                files = attachments.map(({ file_id, filename, size, url}) => {
                    return <a href={url} key = { file_id } className = 'gac-project-file' target='_blank' rel = 'noopener noreferrer'>
                        <i className = { `gac-project-file-type ${ filename.split('.').slice(-1)[0] }` }/>
                        <span title = { filename } className = 'gac-project-file-name'>{ filename }</span>
                        <div className = 'gac-align-right' style={{ marginRight: 10 }}>
                            <span className = 'gac-project-file-size'>{ bytesToSize(size) }</span>
                        </div>
                    </a>
                });
            }

            let name, avatar, unique_id;
            if ( !isNil(author) ) {
                ({ name, avatar, unique_id } = author );
            }

            const year = moment().year();
            const dateObj = moment(date_create);
            const isToday = moment().diff(moment(date_create), 'days') === 0;
            const date = dateObj.format( isToday ? 'h:mma' : dateObj.year() === year ? 'MMM D' : 'MMM, YYYY' );
            const isActive = `${activeComment}` === `${id}`;
            const isCommentEditing = `${editingComment}` === `${id}`;

            const getTextarea = () => {
                let text = entities.decode(comment);
                const matchedUrls = text.match(regexUrls);

                if (matchedUrls) {
                    text = text.replace(regexUrls, (url) => {
                        return `<a href="${url}" target="_blank">${url}</a>`;
                    });
                }

                if ( isCommentEditing ) {
                    return <>
                        { getTextareaAutosize({ autoFocus: true }, entities.decode(commentText)) }
                        { getFilesHtml() }
                        <div className = 'gac-project-comment-btns'>
                            <span data-id = { id } onClick = { onCommentUpdate }><CommentAdd/></span>
                            { getDropzoneComments() }
                            <span onClick = { onCommentFormClose } className = 'gac-cancel'><CommentClose/></span>
                        </div>
                    </> ;
                }
                return <>
                    <p dangerouslySetInnerHTML={{ __html: text }}/>
                    { files.length ? <div className = 'gac-project-files'>{ files }</div> : null }
                </> ;
            };
            const getCommentBtns = () => {
                if ( !isActive ) return null;

                return <>
                    { ((!isClientFn(user_role) && !isFakeUser ) || `${unique_id}` === `${user_id}` || ( isFakeUser && `${unique_id}` === logged_user.user_uid ))
                        && !isCommentEditing
                        && !editing
                        && isCurrentRevision
                        && 'approval/approved'.includes(status)
                        && <div className = 'gac-project-comment-btns'>
                            <span data-id = { id } onClick = { onCommentEdit }><CommentEdit/></span>
                            <span data-id = { id } onClick = { onCommentDelete } className = 'gac-cancel'><CommentDelete/></span>
                        </div> }
                    <div className = 'gac-line'/>
                </> ;
            };

            return <div key = { id } data-id = { id } id = {`comment-${id}`} className = { `gac-project-comment ${isActive ? 'gac-active' : ''} ${isCommentFormError ? 'gac-invalid' : ''}` } onClick = { setCommentActive }>
                <div className = 'gac-project-comment-head'>
                    <div className = 'gac-project-comment-author'>
                        { isNil(avatar) || isEmpty(avatar) ? <div className="gac-no-avatar"/> : <img src = { avatar } alt='Avatar'/> }
                        <div className="gac-name">{ name }</div>
                    </div>
                    <div className = 'gac-project-comment-date'>{ date }</div>
                </div>
                { getTextarea() }
                { getCommentBtns() }
            </div> ;
        });

        if ( isEmpty(commentsData) ) return null;

        return <div style = {{ height: 'calc(100% + 113px)' }} className = 'gac-project-comments motion'>
            { commentsData }
        </div> ;
    };
    const getCommentForm = () => {
        if ( !'approval/approved'.includes(status) || stateRevision !== getMaxRevision(revisions) ) return null;

        return <>
            <div className="gac-project-comment-form-title">Comments</div>
            <div id='textarea' className = { `gac-project-comment-form ${ isCommentFormError ? 'gac-invalid' : '' }` }>
                <textarea rows = { 4 } value = { newCommentText } onChange = { onNewCommentTextareaChange } />
                { isEmptyComments && <div className="gac-error">Please add a comment</div> }
                { isNil(editingComment) && getFilesHtml() }
                <div className = 'gac-project-comment-form-btns'>
                    <span className='gac-project-comment-form__approve' onClick = { onCommentSubmit } >Submit</span>
                    { getDropzoneComments() }
                </div>
            </div>
        </> ;
    };
    const getMobileComments = () => {
        const getMobileComment = () => {
            let mobileComment = null;
            let mobileCommentsCount = 0;
            let mobileCommentIndex = null;
            let isMobileCommentEditing = false;
            let mobileFiles = [];
            let commentsData = getCommentsData();

            if ( !isNil(activeComment) && isMobile ) {
                mobileCommentsCount = commentsData.length;
                mobileCommentIndex = indexOf(activeComment, commentsData.map(o => o.id)) + 1;
                mobileComment = commentsData.filter(o => `${o.id}` === `${activeComment}` )[0];

                if ( !isNil(mobileComment) && !isNil(mobileComment.attachments) ) {
                    mobileFiles = mobileComment.attachments.map(({ file_id, filename, size, url}) => {
                        return <a href={url} key = { file_id } className = 'gac-project-file' target='_blank' rel = 'noopener noreferrer'>
                            <i className = { `gac-project-file-type ${ filename.split('.').slice(-1)[0] }` }/>
                            <span title = { filename } className = 'gac-project-file-name'>{ filename }</span>
                            <div className = 'gac-align-right' style={{ marginRight: 10 }}>
                                <span className = 'gac-project-file-size'>{ bytesToSize(size) }</span>
                            </div>
                        </a>
                    });
                }
                if ( !isNil(mobileComment) ) {
                    isMobileCommentEditing = `${editingComment}` === `${mobileComment.id}`;
                }
            }

            if ( isNil(mobileComment) || !isMobile ) return null;

            let text = entities.decode(mobileComment.comment);
            const matchedUrls = text.match(regexUrls);

            if (matchedUrls) {
                text = text.replace(regexUrls, (url) => {
                    return `<a href="${url}" target="_blank">${url}</a>`;
                });
            }

            return <div ref = { mobileCommentsRef } className = 'gac-mobile-comments'>
                <div className = 'gac-mobile-comments-head'>
                    <div className = 'gac-comments-count'>{ `${ mobileCommentIndex } of ${ mobileCommentsCount }` }</div>
                    { mobileCommentsCount > 1 ? <div className = 'gac-mobile-comments-btns'><span data-action = 'prev' onClick = { slideMobileComment }/><span data-action = 'next' onClick = { slideMobileComment }/></div> : null }
                </div>
                { isMobileCommentEditing
                    ? <>
                        <div className= { `gac-mobile-comment-wrap ${isCommentFormError ? 'gac-invalid' : ''}` } >
                            <textarea ref = { textareaRef } value = { commentText } onKeyDown= { onCommentTextareaKeyDown } onChange = { onCommentTextareaChange }/>
                        </div>
                        { getFilesHtml() }
                    </>
                    : <><div className = 'gac-mobile-comment' dangerouslySetInnerHTML={{ __html: text }}/>
                        { mobileFiles.length ? <div className = 'gac-project-files'>{ mobileFiles }</div> : null }
                    </> }
                { ( (!isClientFn(user_role) && !isFakeUser ) || `${mobileComment.user.unique_id}` === `${user_id}` || ( isFakeUser && `${mobileComment.user.unique_id}` === logged_user.user_uid ))
                    && !editing && isCurrentRevision && status === 'approval'
                    ? <div className = 'gac-comment-actions'>
                        { isMobileCommentEditing
                            ? <>
                                <span data-id = { mobileComment.id } className = 'gac-comment' onClick = { onCommentUpdate }><CommentAdd/></span>
                                { getDropzoneComments() }
                                <span onClick = { onCommentFormClose } className = 'gac-cancel'><CommentClose/></span>
                            </>
                            : <>
                                <span data-id = { mobileComment.id } onClick = { onCommentEdit } className = 'gac-comment'><CommentEdit/></span>
                                <span data-id = { mobileComment.id } onClick = { onCommentDelete } className = 'gac-cancel'><CommentDelete/></span>
                            </> }
                    </div>
                    : null }
            </div> ;
        };

        return getMobileComment() ;
    };
    const getProjectData = () => {
        if ( includes(status, 'revision|editing') && isEmpty(revisions) ) {
            return <EmptyStatev2 status = { isClientFn(user_role) ? 'writing' : status } type = 'type-1'/> ;
        }

        return <>
            { getHtml() }
            { getCommentForm() }
            { getComments() }
        </> ;
    };
    const getBtns = () => {
        let isCurrentRevision = !isEmpty(revisions) && stateRevision === getMaxRevision(revisions);
        if ( status === 'revision' && revision && isEmpty(revisions) ) return null;
        // if ( status === 'matching' && revision && isCurrentRevision ) return null;

        let sourceFiles = [];
        if ( !isEmpty(revisions) && !isNil(revisions) && !isNil(stateRevision) ) {
            sourceFiles = revisions.filter(o => `${o.revision_number}` === `${stateRevision}`)[0].files
        }
        const sourceData = sourceFiles
            .filter(o => !o.is_preview && !o.internal_source)
            .map(o => {
                return <span key = { o.file_id } data-href={o.url} title = { o.filename } className='gac-design-source-item' onClick = { onSourceLinkClick }>
                    { `${getShortFileName(o.filename, 30)}` }
                </span> ;
            });
        const dueIn = deadline ? getDuration(moment.utc(deadline).diff(moment().utc())).duration : '';
        const isApproval = status === 'approval';
        const isApproved = 'approval/approved'.includes(status);

        const getBtnWithList = () => {
            return <div className="gac-btn-with-list" ref = { listWrapRef }>
                <div className = 'gac-btn-v3' onClick = { onToggleDownloadList }>
                    <i className="gac-svg"><Download/></i>
                    <span>Download source files</span>
                    <i className="gac-arrow"><Arrow/></i>
                </div>
                { isDownloadList && <div className='gac-design-source-list' ref = { listRef }>
                    { sourceData }
                </div> }
            </div> ;
        };


        if ( isClientFn(user_role) || !isAuth ) {
            return <div className='gac-project-btns'>
                { isApproval
                    ? !!client_reviewed
                        ? <div style = {{ marginBottom: 16 }} className="gac-reviewed-text">Email sent: project marked as reviewed</div>
                        : <div className='gac-btn gac-btn-s' onClick = { onProjectReview }>Mark as reviewed</div>
                    : null }
            </div> ;
        }

        if ( isNil(revisions) ) return null;
        if ( includes(status, 'revision|editing') || (status === 'matching' && revision) ) {
            return <div className = 'gac-project-btns gac-content-page-btns'>
                <Link className="gac-btn-v3" to = { `/project/${project_id}/sharing` }><i className="gac-svg"><Share/></i><span>Share project</span></Link>
                { getBtnWithList() }
            </div> ;
        }

        return <div className = 'gac-project-btns gac-content-page-btns'>
            { isCurrentRevision
                ? <>
                    { isApproval && <div className="gac-approve-project-wrap">
                        <div className = { isSubmitRequest ? 'gac-btn-v3' : 'gac-btn gac-btn-s'} style={{ margin: '0 16px 8px 0' }} onClick = { onProjectApprove }>Approve project</div>
                        <div className="gac-auto-approval"><i/>Auto-approval in { dueIn }</div>
                        { getProjectStatus(is_finished) }
                        { getCutoffTime(pay_type, accountId, subscriptions) }
                    </div> }
                    { ((pay_type === 'one-off' && isApproval) || ( pay_type === 'subscription' && 'approval/approved'.includes(status))) && <div className = { isSubmitRequest ? 'gac-btn gac-btn-s' : 'gac-btn-v3'} onClick = { onRequestRevision }>{ isSubmitRequest ? null : <i className='gac-svg'><Reload/></i> }<span>{ isSubmitRequest ? 'Submit request' : 'Request revision' }</span></div> }
                    { isApproved && <Link className="gac-btn-v3" to = { `/project/${project_id}/sharing` }><i className="gac-svg"><Share/></i><span>Share project</span></Link> }
                </>
                : null }
            { (isApproved || revision ) && getBtnWithList() }
        </div> ;
    };

    return <div className='gac-project-content'>
        { getTopic() }
        <div ref = { contentRef } className="gac-project-content-wrap">
            { getRevisionsBtns() }
            <div ref = { containerRef } className="gac-project-data-wrap">
                { getProjectData() }
            </div>
        </div>
        { getBtns() }
        { getMobileComments() }
    </div> ;
};