// Core
import { call, put, select  } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { authActions } from "../../../auth/actions";
import { uiActions } from '../../../ui/actions';
import { userActions } from '../../actions';
import { userActionsAsync } from "../asyncActions";
import animateScrollTo from 'animated-scroll-to';
import { setAccountCookies } from "../../../../helpers/helpers";

export function* callCreateAccountWorker ({ payload: { account, navigate } }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/accounts`, {
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify(account),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        yield put(userActionsAsync.fetchCompanySubscriptionsAsync());
        yield put(userActions.createAccountSuccess(data));
        yield put(uiActions.setUiState('isAccountCreated', true));
        navigate(`/account/${data.account_uid}/team`);
        yield put(uiActions.setUiState('notification', {
            msg: 'Account created',
            type: 'success',
            fn: null,
        }));
        animateScrollTo(0);
        yield put( authActions.setAccount(data.account_uid) );
        setAccountCookies(data.account_uid);
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
