// Core
import { asyncTypes } from './asyncTypes';

export const paymentsActionsAsync = Object.freeze({
    fetchBraintreeTokenAsync: (type, action, account_uid) => ({
        type: asyncTypes.FETCH_BRAINTREE_TOKEN_ASYNC,
        payload: {
            type,
            action,
            account_uid
        },
    }),
    fetchStripeCardsAsync: (accountUid, type, subscriptionId, talent, currency) => ({
        type: asyncTypes.FETCH_STRIPE_CARDS_ASYNC,
        payload: {
            accountUid,
            type,
            subscriptionId,
            talent,
            currency
        },
    }),
    deleteStripeCardAsync: (card_id, type) => ({
        type: asyncTypes.DELETE_STRIPE_CARD_ASYNC,
        payload: {
            card_id,
            type
        },
    }),
    checkRefundDesignAsync: (fileUrl) => ({
        type: asyncTypes.CHECK_REFUND_DESIGN_ASYNC,
        payload: {
            fileUrl
        },
    }),
    putRefundDesignAsync: () => ({
        type: asyncTypes.PUT_REFUND_DESIGN_ASYNC,
    }),
    retryChargeAsync: (platform, subscription_uid, account_uid) => ({
        type: asyncTypes.RETRY_CHARGE_ASYNC,
        payload: {
            platform,
            subscription_uid,
            account_uid
        },
    }),
    stripeCompleteAsync: (data, navigate) => ({
        type: asyncTypes.STRIPE_COMPLETE_ASYNC,
        payload: {
            data,
            navigate
        },
    }),
});
