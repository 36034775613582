// Instruments
import { types } from './types';

export const formsActions = Object.freeze({
    setFormState: (prop, value) => ({
        type:    types.SET_FORM_STATE,
        payload: {
            prop,
            value
        },
    }),
    updateFormState: (prop, value) => ({
        type:    types.UPDATE_FORM_STATE,
        payload: {
            prop,
            value
        },
    }),
    updateFormFilesState: (form, prop, value, id) => ({
        type:    types.UPDATE_FORM_FILES_STATE,
        payload: {
            form,
            prop,
            value,
            id
        },
    }),
    clearOrderForms: () => ({
        type: types.CLEAR_ORDER_FORMS,
    }),
});
