// Types
export const types = Object.freeze({
    CREATE_MESSAGE_SUCCESS:         'CREATE_MESSAGE_SUCCESS',
    FETCH_PROJECT_REVISION_SUCCESS: 'FETCH_PROJECT_REVISION_SUCCESS',
    UPDATE_PROJECT_DETAILS:         'UPDATE_PROJECT_DETAILS',
    APPROVE_PITCH_SUCCESS:          'APPROVE_PITCH_SUCCESS',
    CREATE_COMMENT_SUCCESS:         'CREATE_COMMENT_SUCCESS',
    UPDATE_COMMENT_SUCCESS:         'UPDATE_COMMENT_SUCCESS',
    DELETE_COMMENT_SUCCESS:         'DELETE_COMMENT_SUCCESS',
    UPDATE_PROJECT_REVISION:        'UPDATE_PROJECT_REVISION',
    UPDATE_IDEA_FIELD:              'UPDATE_IDEA_FIELD',
    UPDATE_IDEA_REVISION_FIELD:     'UPDATE_IDEA_REVISION_FIELD',
    DELETE_IDEA_FILE:               'DELETE_IDEA_FILE',
    UPDATE_IDEA_REVISION:           'UPDATE_IDEA_REVISION',
    /* NEW */
    SET_PROJECT_STATE:              'SET_PROJECT_STATE',
});
