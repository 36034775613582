// Core
import React, { Component } from 'react';

export default class Avatar extends Component {

    render () {
        const { url, name, isOverlay } = this.props;
        const isName = name && name.length > 0 && (name[0] || name[1]);

        return (
            <div className = 'gac-avatar'>
                { url
                    ? <img src = { url } alt = 'User avatar'/>
                    : <div className = 'gac-no-avatar'>
                        { isName
                            ? <>{ name[0] ? name[0].charAt(0) : ''}{ name[1] ? name[1].charAt(0) : ''}</>
                            : null }
                    </div> }
                { isName && <span className = 'gac-name'>{`${ name[0] } ${ name[1] ? name[1].charAt(0) : '' }.`}</span> }
                { isOverlay && <>
                    <div className = 'gac-item-overlay'/>
                    <i className = 'gac-item-overlay-icon'/></> }
            </div>
        );
    }
}
