// Core
import { useEffect } from "react";

// Components
import { BloggingInfo } from "../JobTypesInfo/BloggingInfo";
import { BloggingRequest } from "./BloggingRequest";
import { BloggingOwn } from "./BloggingOwn";
import { BloggingExisting } from "./BloggingExisting";

// Hooks
import { useAuth } from "../../../hooks/useAuth";
import { useForms } from "../../../hooks/useForms";
import { useLists } from "../../../hooks/useLists";
import { useOrder } from "../../../hooks/useOrder";
import { useUi } from "../../../hooks/useUi";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { getActiveBloggingSub, getLastSubByTalent, upperCaseFirstLetter } from "../../../helpers/helpers";
import { isEmpty, isNil, includes } from "ramda";

export const BloggingSubscription = ({ page, fields }) => {
    /* Hooks */
    const { getTopicIdeasTypeField, getExistingIdeaObj } = fields;
    const { keys: { accountId }} = useAuth();
    const { updateFormState } = useForms();
    const { existingIdeas, getExistingIdeasAsync, setListsState } = useLists();
    const { duplicatedProject, editedProject, topic_ideas_type, setOrderState } = useOrder();
    const { isAuth } = useUi();
    const { balance, company: { subscriptions }} = useUser();
    const { words_balance, type } = balance;

    useEffect(() => {
        if ( isAuth ) {
            let activeBloggingSub = getActiveBloggingSub(accountId, subscriptions);
            if ( !isEmpty(activeBloggingSub) || words_balance > 0 ) {
                getExistingIdeasAsync();
            }
        }
        return () => {
            setListsState('existingIdeas',null);
        };
    }, []);
    useEffect(() => {
        if ( page !== 'editing' && isEmpty(duplicatedProject) ) {
            if ( !isNil(existingIdeas) && !isEmpty(existingIdeas) ) {
                const idea = getExistingIdeaObj(null);
                updateFormState('bloggingExistingForm', { ...idea });
                setOrderState('topic_ideas_type', 'existing');
            } else if ( topic_ideas_type === 'existing' ) {
                setOrderState('topic_ideas_type', 'request');
                // const staticObj = this._getStaticObj();
                // actions.setOrderFormState({
                //     ...orderEmptyState.form,
                //     ...staticObj['is_active_blogging_sub'],
                //     job_type,
                //     brief,
                //     quantity: 1,
                //     format: 'blog',
                //     target_audience: '',
                //     topic_ideas_type: 'request',
                // });
            }
        }
    }, [existingIdeas]);
    useEffect(() => {
        if ( includes(topic_ideas_type, 'own/request') ) {
            let activeBloggingSub = getActiveBloggingSub(accountId, subscriptions);
            let lastBloggingSub = getLastSubByTalent(accountId, subscriptions, 'bloggers');
            let frequency = 4, words = 100, level = 'pro';

            if ( !isEmpty(lastBloggingSub) ) {
                ({ frequency, level, words } = lastBloggingSub);
            }
            if ( !isEmpty(activeBloggingSub) ) {
                ({ frequency, level, words } = activeBloggingSub);
            }

            if ( page === 'editing' ) {
                ({ level, word_count: words } = editedProject);
            }
            if ( page === 'new' && !isEmpty(duplicatedProject) ) {
                ({ level, word_count: words } = duplicatedProject);
            }

            updateFormState(`blogging${upperCaseFirstLetter(topic_ideas_type)}Form`, { idea_count_type: 'custom', idea_count: frequency, word_count: words, level });
        }
    }, [topic_ideas_type, subscriptions]);

    if ( isAuth && isEmpty(balance) ) return null;

    if ( type !== 'bloggers' || (words_balance < 1 || !words_balance) ) {
        return <BloggingInfo/>;
    }

    const content = {
        existing: <BloggingExisting fields = { fields } />,
        request: <BloggingRequest fields = { fields } page = { page } />,
        own: <BloggingOwn fields = { fields } />,
    };

    return <>
        { getTopicIdeasTypeField() }
        { content[topic_ideas_type] }
    </>;
};