// Core
import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';

// Hooks
import { useInvalidFields } from "../../../hooks/custom/useInvalidFields";
import { useModals } from "../../../hooks/useModals";
import { useUser } from "../../../hooks/useUser";

// Instruments
import { subTalentTypeConfig } from "../../../helpers/constants";
import { isEmpty, isNil } from 'ramda';
import moment from 'moment/moment';

export const ModalCancelSubscription = ({ closeModal, setPos }) => {
    /* Ref */
    const action = useRef(null);
    const modalRef = useRef(null);

    /* State */
    const [reason, setReason] = useState('');

    /* Hooks */
    const { invalidFields, removeField, setInvalidFields } = useInvalidFields();
    const { activeSub, setModal, setModalState } = useModals();
    const { talent, next_payment_date, active_from } = activeSub;
    const { cancelSubscriptionAsync } = useUser();
    const navigate = useNavigate();

    /* Actions */
    const onTextareaChange = ({ currentTarget: { value }}) => {
        setReason(value.replace(/ +(?= )/g, ''));
        removeField('reason');
    };
    const validateFields = () => {
        return reason.length < 2 ? ['reason'] : [];
    };
    const showPausePaymentModal = () => {
        action.current = 'go_to_pause_sub';
        setModal('pauseSubscription');
    };
    const onSubmit = () => {
        const fields = validateFields();

        if ( isEmpty(fields) ) {
            if ( isNil(activeSub) ) return;

            const { subscription_uid, accounts, talent } = activeSub;
            const { account_uid } = accounts[0];
            cancelSubscriptionAsync({
                subscription_uid,
                account_uid,
                reason,
                type: subTalentTypeConfig[talent]
            }, null, navigate);
            closeModal();
        } else {
            setInvalidFields(fields);
        }
    };

    useEffect(() => {
        setPos( modalRef );

        return () => {
            if ( isNil(action.current) ) setModalState('activeSub', null);
        };
    }, []);

    /* Html */
    const date = isEmpty(next_payment_date) || isNil(next_payment_date) ? '' : moment(next_payment_date).subtract(1, 'day').format('MMM D, YYYY');
    const isSubInFuture = moment(active_from).isAfter(moment());

    return <div ref = { modalRef } className = 'gac-modal-subscription gac-cancel-subscription'>
        <span className = 'gac-close-modal' onClick = { closeModal }/>
        <h1>Cancel subscription</h1>
        <div className="gac-cancel-desc">We're sad to see you go <i/> <br/> We'd appreciate if you tell us why.</div>
        <div className="gac-reason-textarea">
            <textarea className={`${ invalidFields.includes('reason') ? 'gac-invalid' : '' }`} data-hj-whitelist value = { reason } onChange = { onTextareaChange } />
        </div>
        { talent === 'designers' && <div className="gac-cancel-desc">P.S. The design subscription remains active until { date }.</div> }
        <div className="gac-btn-wrap">
            <div style={{ marginRight: 10 }} className='gac-btn gac-btn-s' onClick = { onSubmit }>Cancel subscription</div>
            { !isSubInFuture && <div style={{ marginLeft: 10 }} className='gac-btn-v2 gac-btn-s' onClick = { showPausePaymentModal }>Pause payment</div> }
        </div>
    </div> ;
};