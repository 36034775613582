// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getForms, getProjectDetails, getOrderState } from '../../../../helpers/api';
import { uiActions } from '../../actions';
import { projectActions } from "../../../project/actions";
import { formsActions } from "../../../forms/actions";
import { includes, isNil } from "ramda";

export function* callUploadFileFromUrlWorker ({ payload: obj}) {
    try {
        yield put(uiActions.enableFetching());
        const response = yield call(fetch, `${api}/v1/upload_file_from_url`, {
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                url: obj.url
            }),
        });
        const { response: data, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( includes('/order/', window.location.href) ) {
            const { job_pay_type } = yield select(getOrderState);
            const formType = job_pay_type === 'subscription' ? 'designSubscriptionForm' : 'designForm';
            const forms = yield select(getForms);
            const { image_option_files } = forms[formType];

            yield put( formsActions.updateFormState(formType, { image_option_files: [ ...image_option_files, data] }));
        } else if ( includes('/brief', window.location.href) ) {
            const project = yield select(getProjectDetails);
            const { image_option_files } = project;

            yield put(projectActions.setProjectState('details', { ...project, 'image_option_files': isNil(image_option_files) ? [ data ] : [ ...image_option_files, data ] }));
        }
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}