// Core
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import { MessagesIcon } from "../../../icons/MessagesIcon";

// Hooks
import { useLists } from "../../../../hooks/useLists";
import { useUi } from "../../../../hooks/useUi";
import { useUser } from "../../../../hooks/useUser";
import { useOrder } from "../../../../hooks/useOrder";

// Instruments
import { domainGAC } from "../../../../helpers/api";
import { isWhiteLabel, getPathByCurrency } from "../../../../helpers/helpers";
import { isNil, isEmpty, includes } from "ramda";

export const MobileHeader = ({ showMenu }) => {
    /* Hooks */
    const { staticData: { support_phone }, messages } = useLists();
    const { isAuth, setUiState } = useUi();
    const { proposal } = useUser();
    const { orderCurrency } = useOrder();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    /* Actions */
    const onBtnClick = () => {
        showMenu();
    };
    const goBack = () => {
        if ( isAuth ) {
            if ( includes('/order', pathname) && pathname !== '/order' ) {
                navigate('/order');
            }
        } else {
            navigate('/login');
        }
    };
    const onPhoneClick = () => {
        window.onPhoneClick = true;
    };
    const showMessagesCenter = () => {
        if ( !isNil(messages) ) {
            setUiState('isMsgCenter', true);
        }
    };

    const phone = support_phone.phone ? support_phone.phone : '';
    const isProposal = pathname.includes('proposal');
    let admin = {};
    if ( isProposal && !isEmpty(proposal) ) {
        ({ admin } = proposal);
    }
    const isWLA = isWhiteLabel() && !isAuth;
    const isUnreadMessages = isNil(messages) ? false : messages.some(o => o.status === 'moderated');

    //TODO get title by path
    const title = '';

    {/* isMobile ? <HeaderMobile page = {`${pathname === '/403' ? '403' : '404'} page`} /> : <HeaderDesktop/> */}

    const getPhone = () => {
        return <div className="gac-support-phone-wrap">
            { phone && <a className = 'gac-support-phone' href = { isProposal && !isEmpty(proposal) ? `tel:${admin.phone.replace(/-/g, '')}` : `tel:${phone.replace(/-/g, '')}` } onClick = { onPhoneClick }><i/></a> }
            <a href = {`https://${domainGAC}.com/${getPathByCurrency(orderCurrency)}demo`} target='_blank' rel = 'noopener noreferrer' className='gac-btn gac-btn-s'>Book a demo</a>
        </div>
    };

    return(
        <div className = 'gac-mobile-header'>
            { !isProposal && !isWLA && <>{ pathname.includes('/order') && pathname !== '/order'
                ? <div className = 'gac-mobile-back' onClick = { goBack }><i/></div>
                : <div className = 'gac-mobile-menu-toggle' onClick = { onBtnClick }>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                </div> }</> }
            <span className = 'gac-mobile-header__title'>{ title }</span>
            { isAuth && <div className="gac-mobile-header__messages-icon" onClick = { showMessagesCenter }>
                <MessagesIcon isActive = { isUnreadMessages } />
            </div> }
            { !isWLA && !isAuth && getPhone() }
        </div>
    );
};