// Core
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { listsActions } from "../bus/lists/actions";
import { listsActionsAsync } from "../bus/lists/saga/asyncActions";

export const useLists = () => {
    const selector = (state) => state.lists;
    const lists = useSelector(selector);
    const dispatch = useDispatch();

    /* Actions */
    const setListsState = (prop, value) => {
        dispatch(listsActions.setListsState(prop, value));
    };

    /* Async */
    const getExistingIdeasAsync = () => {
        dispatch(listsActionsAsync.getExistingIdeasAsync());
    };
    const getAccountsWithoutSubsAsync = (sub_uid) => {
        dispatch(listsActionsAsync.getAccountsWithoutSubsAsync(sub_uid));
    };
    const getAccountsNoWritingSubsAsync = (account_uid, currency, talent, platform) => {
        dispatch(listsActionsAsync.getAccountsNoWritingSubsAsync(account_uid, currency, talent, platform));
    };
    const getQueuedProjectsAsync = () => {
        dispatch(listsActionsAsync.getQueuedProjectsAsync());
    };
    const getStaticDataAsync = () => {
        dispatch(listsActionsAsync.getStaticDataAsync());
    };
    const getMessagesAsync = (page) => {
        dispatch(listsActionsAsync.getMessagesAsync(page));
    };
    const getTransactionsAsync = (id, range) => {
        dispatch(listsActionsAsync.getTransactionsAsync(id, range));
    };
    const getImagesAsync = (query) => {
        dispatch(listsActionsAsync.getImagesAsync(query));
    };
    const getImageCategoriesAsync = () => {
        dispatch(listsActionsAsync.getImageCategoriesAsync());
    };
    const getImagesCarouselAsync = () => {
        dispatch(listsActionsAsync.getImagesCarouselAsync());
    };
    const getProjectsAsync = (view_by, date_from, date_to, type, status, last100) => {
        dispatch(listsActionsAsync.getProjectsAsync(view_by, date_from, date_to, type, status, last100));
    };
    const getUnreadMessagesAsync = () => {
        dispatch(listsActionsAsync.getUnreadMessagesAsync());
    };
    const getOrderListAsync = (navigate) => {
        dispatch(listsActionsAsync.getOrderListAsync(navigate));
    };


    return {
        ...lists,
        setListsState,
        getExistingIdeasAsync,
        getAccountsWithoutSubsAsync,
        getAccountsNoWritingSubsAsync,
        getQueuedProjectsAsync,
        getStaticDataAsync,
        getMessagesAsync,
        getTransactionsAsync,
        getImagesAsync,
        getImageCategoriesAsync,
        getImagesCarouselAsync,
        getProjectsAsync,
        getUnreadMessagesAsync,
        getOrderListAsync,
    };
};
