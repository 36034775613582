// Core
import { useRef } from 'react';

// Components
import ConditionalWrapper from "../../common/ConditionalWrapper";
import { Swiper, SwiperSlide } from 'swiper/react';

// Instruments
import { reverse, includes, isNil } from "ramda";

export const RevisionsBtns = ({ revisions, currentRevision, initialSlide, maxWidth, type, status, isMobile, isFakeRevision, onRevisionChange }) => {
    const swiperRef = useRef(null);

    /* Slider */
    const isSwiper = revisions.length > 6 || (isMobile && revisions.length > 3);
    const swiperConfig = () => ({
        grabCursor: false,
        allowTouchMove: false,
        initialSlide,
        breakpoints: {
            320: {
                slidesPerView: 2,
            },
            480: {
                slidesPerView: 3,
            },
            667: {
                slidesPerView: 5,
            },
            768: {
                slidesPerView: 3,
            },
            850: {
                slidesPerView: 4,
            },
            1024: {
                slidesPerView: 6,
            }
        }
    });

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };
    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };
    const getPrevBtn = () => {
        return <div className="gac-prev" onClick = { goPrev }>
            <svg width="9" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.76.234c.32.313.32.82 0 1.132L1.975 8l6.785 6.634c.32.313.32.82 0 1.132a.831.831 0 0 1-1.157 0L.24 8.566a.787.787 0 0 1 0-1.132l7.363-7.2a.831.831 0 0 1 1.157 0Z" fill = { '#1D1E1E' }/></svg>
        </div> ;
    };
    const getNextBtn = () => {
        return <div className="gac-next" onClick = { goNext }>
            <svg width="9" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M.24 15.766a.788.788 0 0 1 0-1.132L7.025 8 .24 1.366a.787.787 0 0 1 0-1.132.831.831 0 0 1 1.157 0l7.363 7.2c.32.313.32.82 0 1.132l-7.363 7.2a.831.831 0 0 1-1.157 0Z" fill = { '#1D1E1E' }/></svg>
        </div> ;
    };

    /* Actions */
    const onRevisionClick = ({ currentTarget: { dataset: { revision }}}) => {
        const index =
            isNil(swiperRef.current)
            || isNil(swiperRef.current.swiper)
                ? 0
                : swiperRef.current.swiper.activeIndex;
        onRevisionChange(revision, index);
    };

    /* Html */
    const getRevisionBtnHtml = (revision, text, isActive) => {
        return <SwiperSlide
            tag = 'span'
            className = { isActive ? 'gac-active' : '' }
            key = { revision }
            data-revision = { revision }
            onClick = { onRevisionClick }>
            { text }
        </SwiperSlide> ;
    };
    const getBtns = () => {
        let btns = [];

        if ( type === 'design' ) {
            btns = revisions.map(({ revision_number }, i) => {
                const text = i === 0 ? 'Original' : `Revision ${i}`;
                return(
                    getRevisionBtnHtml(revision_number, text, currentRevision === revision_number)
                );
            });
            btns = reverse(btns);
        } else if ( type === 'writing' ) {
            btns = reverse(revisions).map(({ revision_number }, i) => {
                const text = i === 0 ? 'Original' : `Revision ${i}`;
                return(
                    getRevisionBtnHtml(revision_number, text, currentRevision === revision_number)
                );
            });
            btns = reverse(btns);
            if ( includes(status, 'revision|editing') ) {
                btns = [ getRevisionBtnHtml('fake', `Revision ${revisions.length}`, isFakeRevision), ...btns ];
            }
        } else if ( type === 'ideas' ) {
            btns = revisions.map((item, i) => {
                const text = i === 0 ? 'Original' : `Revision ${i}`;
                return(
                    getRevisionBtnHtml(i, text, currentRevision === i)
                );
            });

            btns = reverse(btns);
        }

        return btns;
    };

    return <div className = { `gac-revision-btns ${isSwiper ? '' : 'no-slider'}` } style={{ maxWidth }}>
        { isSwiper && getPrevBtn() }
        <ConditionalWrapper
            condition = { isSwiper }
            wrapper={children => <Swiper ref = { swiperRef } { ...swiperConfig() }>{children}</Swiper>}>
            { getBtns() }
        </ConditionalWrapper>
        { isSwiper && getNextBtn() }
    </div> ;
};