// Async types
export const asyncTypes = Object.freeze({
    FETCH_PROJECT_TAGS_ASYNC:            'FETCH_PROJECT_TAGS_ASYNC',
    FETCH_PROJECT_DETAILS_ASYNC:         'FETCH_PROJECT_DETAILS_ASYNC',
    FETCH_PROJECT_DETAILS_BY_CODE_ASYNC: 'FETCH_PROJECT_DETAILS_BY_CODE_ASYNC',
    FETCH_PROJECT_PITCHES_ASYNC:         'FETCH_PROJECT_PITCHES_ASYNC',
    FETCH_PROJECT_LOGS_ASYNC:            'FETCH_PROJECT_LOGS_ASYNC',
    FETCH_PROJECT_MESSAGES_ASYNC:        'FETCH_PROJECT_MESSAGES_ASYNC',
    FETCH_PROJECT_COMMENTS_ASYNC:        'FETCH_PROJECT_COMMENTS_ASYNC',
    FETCH_PROJECT_REVISIONS_ASYNC:       'FETCH_PROJECT_REVISIONS_ASYNC',
    FETCH_PROJECT_REVISION_ASYNC:        'FETCH_PROJECT_REVISION_ASYNC',
    CREATE_TAG_ASYNC:                    'CREATE_TAG_ASYNC',
    CREATE_MESSAGE_ASYNC:                'CREATE_MESSAGE_ASYNC',
    CREATE_COMMENT_ASYNC:                'CREATE_COMMENT_ASYNC',
    UPDATE_COMMENT_ASYNC:                'UPDATE_COMMENT_ASYNC',
    DELETE_COMMENT_ASYNC:                'DELETE_COMMENT_ASYNC',
    PUT_REQUEST_REVISION_ASYNC:          'PUT_REQUEST_REVISION_ASYNC',
    UPDATE_REVISION_ASYNC:               'UPDATE_REVISION_ASYNC',
    FETCH_REVISION_DIFF_ASYNC:           'FETCH_REVISION_DIFF_ASYNC',
    DOWNLOAD_PROJECT_CONTENT_ASYNC:      'DOWNLOAD_PROJECT_CONTENT_ASYNC',
    CHECK_TOPIC_UNIQUE_ASYNC:            'CHECK_TOPIC_UNIQUE_ASYNC',
    UPDATE_IDEAS_REVISION_ASYNC:         'UPDATE_IDEAS_REVISION_ASYNC',
    CANCEL_PROJECT_ASYNC:                'CANCEL_PROJECT_ASYNC',
    CHANGE_PROJECT_QUEUE_ASYNC:          'CHANGE_PROJECT_QUEUE_ASYNC',
    DELETE_PROJECT_ASYNC:                'DELETE_PROJECT_ASYNC',
    // From projects
    APPROVE_PROJECT_ASYNC:           'APPROVE_PROJECT_ASYNC',
    EXTEND_PROJECT_DEADLINE_ASYNC:   'EXTEND_PROJECT_DEADLINE_ASYNC',
    UPDATE_PROJECT_ASYNC:            'UPDATE_PROJECT_ASYNC',
    MOVE_PROJECT_ASYNC:              'MOVE_PROJECT_ASYNC',
    AWARD_PROJECT_ASYNC:             'AWARD_PROJECT_ASYNC',
    REVIEW_PROJECT_ASYNC:            'REVIEW_PROJECT_ASYNC',
    PUBLISH_PROJECT_ASYNC:           'PUBLISH_PROJECT_ASYNC',
    SET_EDITING_PROJECT_ASYNC:       'SET_EDITING_PROJECT_ASYNC',
    CREATE_PROJECT_ASYNC:            'CREATE_PROJECT_ASYNC',
    CONFIRM_PROJECT_ASYNC:           'CONFIRM_PROJECT_ASYNC',
    SET_IDEA_STATUS_ASYNC:           'SET_IDEA_STATUS_ASYNC',
    GET_PROJECTS_DESIGN_COUNT_ASYNC: 'GET_PROJECTS_DESIGN_COUNT_ASYNC',
    GET_ORIGINALITY_REVISION_RESULTS_ASYNC: 'GET_ORIGINALITY_REVISION_RESULTS_ASYNC',
});
