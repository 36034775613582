// Core
import { call } from 'redux-saga/effects';

// Instruments
import { api } from '../../../../helpers/api';

export function* callErrorReportWorker ({ payload }) {
    try {
        const response = yield call(fetch, `${api}/v1/report-error`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
        const {error: {message}} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
    }
}
