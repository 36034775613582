// Core
import update from 'immutability-helper';

// Instrument
import { types } from './types';
import { types as authTypes } from '../auth/types';

export const initialState = {
    type:      null,
    braintree: {},
    stripe: {},
    paypal: {
        orderId: '',
    },
    bundlesBraintree: {},
    bundlesStripe: {},
    bundlesPaypal: {
        orderId: '',
    },
    subBraintree: {},
    cardBraintree: {},
};

export const paymentsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_PAYMENTS_STATE:
            return update(state, {
                [payload.prop]: {$set: payload.data},
            });

        case authTypes.LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};
