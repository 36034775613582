// Core
import { call, put, select } from 'redux-saga/effects';

// Instruments
import { api, getKeys, authStr } from '../../../../helpers/api';
import { orderActionsAsync } from '../../../order/saga/asyncActions';
import { projectActionsAsync } from "../asyncActions";
import { projectActions } from "../../actions";
import { uiActions } from '../../../ui/actions';
import { includes } from 'ramda';

export function* callCreateProjectWorker ({ payload: { project, action, navigate }  }) {
    try {
        yield put(uiActions.enableFetching());
        const { privateKey, publicKey, accountId } = yield select(getKeys);
        const response = yield call(fetch, `${api}/${publicKey}/v1/projects/${action.job_type}${ action.job_type === 'seoaudit' ? `/${project.seoaudit_type}` : '' }`,{
            method: 'POST',
            headers: {
                'Authorization': `${authStr} ${privateKey}`,
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                ...project,
                account_uid: accountId,
            }),
        });
        const { response: projects, error: { message }} = yield call([response, response.json]);

        if (response.status !== 200) {
            throw new Error(message);
        }

        if ( includes(action.name, 'save_draft/save_draft_from_idea') ) {
            if( action.name === 'save_draft_from_idea' ) {
                const { order_on, project_id, status } = projects[0];
                const { idea_number } = project;
                yield put(projectActions.updateIdeaField('ordered_project_id', project_id, idea_number));
                yield put(projectActions.updateIdeaField('ordered_project_date', order_on, idea_number));
                yield put(projectActions.updateIdeaField('ordered_project_status', status, idea_number));
            } else {
                navigate('/order');
            }
        } else if ( includes(action.name, 'save_add_to_order/save_add_to_order_from_idea') ) {
            if ( projects.length ) {
                let ids = '';
                projects.forEach((item, i) => {
                    if (i === projects.length - 1 ) {
                        ids = ids + `${item.project_id}`
                    } else {
                        ids = ids + `${item.project_id},`
                    }
                });
                yield put(orderActionsAsync.addProjectToOrderAsync(ids, action.name, navigate));
                if( action.name === 'save_add_to_order_from_idea' ) {
                    const { order_on, project_id, status } = projects[0];
                    const { idea_number } = project;
                    yield put(projectActions.updateIdeaField('ordered_project_id', project_id, idea_number));
                    yield put(projectActions.updateIdeaField('ordered_project_date', order_on, idea_number));
                    yield put(projectActions.updateIdeaField('ordered_project_status', status, idea_number));
                }
            }
        }
        yield put(projectActionsAsync.fetchProjectTagsAsync());
    } catch (error) {
        console.warn(`You've just got an error: \n ${error}`);
        yield put(uiActions.setUiState('notification', {
            msg: error.message,
            type: 'error',
            fn: null,
        }));
    } finally {
        yield put(uiActions.disableFetching());
    }
}
