// Core
import { takeEvery } from 'redux-saga/effects';

// Instruments
import { types } from '../types';
import { callAuthenticateWorker } from './workers/authenticate';
import { callLoginWorker } from './workers/login';
import { callLogoutWorker } from './workers/logout';
import { callClearCookiesWorker } from './workers/clearCookies';

export const authWatchers = Object.freeze({
    * watchAuthenticate () {
        yield takeEvery(types.AUTHENTICATE, callAuthenticateWorker);
    },
    * watchLogin () {
        yield takeEvery(types.LOGIN, callLoginWorker);
    },
    * watchLogout () {
        yield takeEvery(types.LOGOUT, callLogoutWorker);
    },
    * watchClearCookies () {
        yield takeEvery(types.CLEAR_COOKIES, callClearCookiesWorker);
    },
});
