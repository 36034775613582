// Core
import { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

// Components
import { Login } from "./Login";
import { Password } from "./Password";
import { PasswordUpdate } from "./PasswordUpdate";
import { PasswordCreate } from "./PasswordCreate";
import ReactPixel from 'react-facebook-pixel';

// Hooks
import { useUi } from "../../hooks/useUi";

// Instruments
import cx from 'classnames';
import { isWhiteLabel } from "../../helpers/helpers";
import { version, isStage, fbPixelId } from '../../helpers/api';
import { isNil } from "ramda";

export const LoginPage = () => {
    /* Ref */
    const formRef = useRef(null);

    /* Hooks */
    const { isMobile, isLoginFail, isPasswordRestored, isPasswordUpdated, setUiState } = useUi();
    const { pathname } = useLocation();

    /* Actions */
    const changePos = () => {
        const form = formRef.current;
        form.style.marginTop = `-${form.offsetHeight / 2}px`;
    };

    useEffect(() => {
        if ( !isStage ) ReactPixel.init(fbPixelId);
        if ( isStage && !isWhiteLabel() ) console.log('v',version);

        return () => {
            setUiState('loginAttempt', 0);
            setUiState('loginEmail', '');
        };
    }, []);
    useLayoutEffect(() => {
        if ( !isMobile ) {
            changePos();
        }
    }, [isMobile]);
    useLayoutEffect(() => {
        changePos();
        if ( isLoginFail ) setUiState('isLoginFail', false);
        if ( !isNil( isPasswordRestored ) ) setUiState('isPasswordRestored', null);
        if ( !isNil( isPasswordUpdated ) ) setUiState('isPasswordUpdated', null);
    }, [pathname]);

    const classNames = cx( 'gac-login-page', {
        'gac-mobile-view': isMobile,
        'gac-white-label': isWhiteLabel(),
    });

    const routes = {
        '/login': <Login/>,
        '/password': <Password/>,
        '/password-update': <PasswordUpdate/>,
        '/password-create': <PasswordCreate/>,
    };

    return(
        <div className = { classNames }>
            <div ref = { formRef } className = 'gac-login-form'>
                { isStage && !isWhiteLabel() && <div className="gac-version">v { version }</div> }
                { routes[pathname] }
            </div>
        </div>
    );
};

