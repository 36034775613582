// Core
import { useEffect, useState, useRef } from "react";

// Components
import FilterBig from "../icons/FilterBig";
import Filter from "../icons/Filter";
import FilterItem from "../common/FilterItem";
import FilterItemSize from "../common/FilterItemSize";
import FilterItemColors from "../common/FilterItemColors";
import CheckedCheckbox from "../icons/CheckedCheckbox";
import Checkbox from "../icons/Checkbox";
import { EmptyState } from "../common/EmptyState";
import ResourceSelect from "./ResourceSelect";
import SingleImage from "./SingleImage";
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { motion } from "framer-motion"

// Hooks
import { useModals } from "../../hooks/useModals";
import { useLists } from "../../hooks/useLists";
import { useUi } from "../../hooks/useUi";

// Instruments
import { isFetching } from "../../helpers/helpers";
import { filters } from "../../helpers/filters";
import { perPage } from "../../helpers/constants";
import animateScrollTo from "animated-scroll-to";
import { keys, isNil, isEmpty, equals, includes } from 'ramda';

const justifiedLayout = require('justified-layout');
const config = {
    modal: {
        wrapWidth: 1230,
        containerWidth: 1128,
        widthOffset: 32,
        heightOffset: 94,
    },
    page: {
        wrapWidth: 1170,
        containerWidth: 1140,
        widthOffset: 30,
        heightOffset: 136,
    },
};
const platformObj = {
    pixabay: {
        url: 'https://pixabay.com/',
        label: 'Pixabay'
    },
    pexels: {
        url: 'https://www.pexels.com/',
        label: 'Pexels'
    },
    unsplash: {
        url: 'https://unsplash.com/',
        label: 'Unsplash'
    }
};
const parametersObj = {
    category: {
        value: '',
        title: 'All categories',
    },
    colors: {
        value: [],
        title: 'Any color',
    },
    image_type: {
        value: 'all',
        title: 'All images',
    },
    safesearch: {
        value: true
    },
    orientation: {
        value: 'all',
        title: 'Any orientation',
    },
    min_width: {
        value: '',
    },
    min_height: {
        value: '',
    },
    q: '',
};

export const ImagesLibrary = ({ type, uploadFileFromUrl }) => {
    /* Ref */
    const isFetchImages = useRef(true);

    /* State */
    const [isFilters, setIsFilters] = useState(false);
    const [containerWidth, setContainerWidth] = useState(null);
    const [platform, setPlatform] = useState('pixabay');
    const [page, setPage] = useState(1);
    const [parameters, setParameters] = useState(parametersObj);

    /* Hooks */
    const { setModal, setModalState } = useModals();
    const { images, imagesCarousel, imageCategories, setListsState, getImagesAsync, getImageCategoriesAsync, getImagesCarouselAsync } = useLists();
    const { isMobile, fetching, imagesTotal: total } = useUi();

    const pagesQty = type === 'modal' ? 12 : perPage;
    const totalPages = total <= pagesQty ? 1 : Math.ceil(total/pagesQty);
    const onResize = () => {
        const width = document.body.clientWidth;
        setContainerWidth( width < config[type].wrapWidth ? width - config[type].widthOffset : config[type].containerWidth );
    };

    /* Did mount */
    useEffect(() => {
        getImageCategoriesAsync();
        getImagesCarouselAsync();
        animateScrollTo(0,{ speed: 300 });

        onResize();
        window.addEventListener('resize', onResize, true);

        return () => {
            window.removeEventListener('resize', onResize, true);
            setListsState('images', null);
            setListsState('imagesCarousel', null);
            setListsState('imageCategories', null);
        }
    }, []);
    useEffect(() => {
        if ( isFetchImages.current ) {
            fetchImages();
        }
    }, [
        page,
        platform,
        parameters.category.value,
        parameters.colors.value,
        parameters.image_type.value,
        parameters.safesearch.value,
        parameters.orientation.value,
        parameters.min_width.value,
        parameters.min_height.value,
        parameters.q,
        isFetchImages.current
    ]);

    const generateQuery = () => {
        const obj = { ...parameters };
        let query = '';

        keys(obj).forEach(key => {
            if ( !isNil(obj[key]) && !isNil(obj[key].value) && !isEmpty(obj[key].value) && obj[key].value ) {
                if (key === 'colors') {
                    query += `&${key}=${obj[key].value.join(',')}`
                } else if (key === 'q') {
                    query += `&${key}=${obj[key].value.toLowerCase().trim().replace(/ /g, "+")}`
                } else {
                    query += `&${key}=${obj[key].value}`
                }

            }
        });
        return `per_page=${type === 'modal' ? '12' : perPage }${query}&platform=${platform}&page=${page}`;
    };
    const fetchImages = () => {
        getImagesAsync(generateQuery());
    };

    /* Filters */
    const toggleFilters = () => {
        setIsFilters(state => !state);
    };
    const applyMobileFilters = () => {
        if ( !isFetchImages.current ) isFetchImages.current = true;
        setIsFilters(false);
    };
    const clearFilters = () => {
        setPage(1);
        setIsFilters(false);
        setParameters(parametersObj);
    };

    /* Parameters */
    const setParam = (prop, value, title) => {
        setParameters( state => {
            return({
                ...state,
                [prop]: {
                    value,
                    title
                }
            });
        });
    };
    const onResourceClick = ({ currentTarget: { dataset: { platform: value }}}) => {
        if ( !equals( platform, value ) ) {
            if ( !isFetchImages.current ) isFetchImages.current = true;
            setPlatform(value);
            setPage(1);
            setParameters(parametersObj);
        }
    };
    const onFilterChange = (parameter, value, title) => {
        if ( isMobile ) {
            if ( isFetchImages.current ) isFetchImages.current = false;
        } else {
            if ( !isFetchImages.current ) isFetchImages.current = true;
        }
        setParam(parameter, value, title);
    };
    const onColorsChange = (checked, value) => {
        const { colors } = parameters;
        let data = isNil(colors) ? [] : colors.value;

        if ( checked ) {
            data = [ ...data, value];
        } else {
            data = data.filter(color => color !== value);
        }
        if ( isMobile ) {
            if ( isFetchImages.current ) isFetchImages.current = false;
        } else {
            if ( !isFetchImages.current ) isFetchImages.current = true;
        }
        setParam('colors', data);
    };
    const onCarouselItemClick = ({ currentTarget: { dataset: { category }}}) => {
        if ( !isFetching(fetching) ) {
            if (category !== parameters.q) {
                if ( !isFetchImages.current ) isFetchImages.current = true;
                setParam('q',category.replace(/ +(?= )/g, ''));
            }
        }
    };
    const onSafeSearchChange = () => {
        const { safesearch } = parameters;
        if ( !isFetchImages.current ) isFetchImages.current = true;
        setParam('safesearch', !safesearch.value)
    };
    const onMinWidthHeightChange = (prop, value) => {
        if ( isFetchImages.current ) isFetchImages.current = false;
        setParam(prop, value);
    };
    const onMinWidthHeightBlur = (parameter, value) => {
        if ( !isFetchImages.current ) isFetchImages.current = true;
        fetchImages();
    };
    const onImageClick = ({ currentTarget: { dataset: { url }}}) => {
        if ( type === 'page' ) {
            setModal('fullImage');
            setModalState('fullImageUrl', url);
        } else {
            uploadFileFromUrl(url);
        }
    };

    /* Search */
    const onSearchInputChange = ({ target: { value }}) => {
        if ( isFetchImages.current ) isFetchImages.current = false;
        setParam('q',value.replace(/ +(?= )/g, ''));
    };
    const onSearchInputBlur = () => {
        if ( !isFetching(fetching) ) {
            fetchImages();
        }
    };
    const onSearchInputKeyPress = ({ key }) => {
        if ( !isFetching(fetching) ) {
            if(key === 'Enter'){
                fetchImages();
            }
        }
    };
    const onSearchIconClick = () => {
        if ( !isFetching(fetching) ) {
            fetchImages();
        }
    };

    /* Pagination */
    const onPaginationBtnClick = ({ currentTarget: { dataset: { page: value }}}) => {
        if ( !isFetching(fetching) ) {
            if ( Number(value) > 0 && Number(value) <= totalPages ) {
                if ( !isFetchImages.current ) isFetchImages.current = true;
                animateScrollTo(0,{
                    speed: 300,
                }).then(() => {
                    setPage(Number(value));
                });
            }
        }
    };
    const onPageInputChange = ({ target: { value }}) => {
        const pageNum = Number(value.replace(/^0+/, ''));

        if ( isFetchImages.current ) isFetchImages.current = false;
        setPage(pageNum <= totalPages ? pageNum : totalPages);
    };
    const onPageInputKeyPress = ({ key }) => {
        if(key === 'Enter'){
            animateScrollTo(0,{
                speed: 300,
            }).then(() => {
                fetchImages();
            });
        }
    };

    /* Html */
    const getGeometry = () => {
        const config = {
            containerPadding: 0,
            containerWidth,
            boxSpacing: {
                horizontal: 8,
                vertical: 8
            },
            showWidows: false,
            targetRowHeight: 250,
            targetRowHeightTolerance: 0.2,
        };
        const sizes = [];
        if ( !isNil(images) ) {
            images.forEach(o => {
                sizes.push({
                    width: o.webformatWidth,
                    height: o.webformatHeight,
                });
            });
        }

        return justifiedLayout(sizes, config);
    };
    const getImages = () => {
        const geometry = getGeometry();
        let items = isNil(images) ? [] : images.filter((image, i) => i <= geometry.boxes.length - 1 );

        if ( type === 'modal' ) {
            items = items.map((o, i) => {
                const top = geometry.boxes[i].top;
                const left = geometry.boxes[i].left;
                const width = geometry.boxes[i].width;
                const height = geometry.boxes[i].height;
                const url640 = o.webformatURL;
                const url = o.imageURL;
                const url1280 = o.largeImageURL;
                return(
                    <div style = {{ top, left, width, height }} key = { i } className="gac-free-images__item gac-loaded">
                        <img src = { url640 } alt = "" height = { geometry.boxes[i].height } width = { geometry.boxes[i].width }/>
                        <span data-url={ isNil(url) ? url1280 : url } onClick = { onImageClick }>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8 0C7.44772 0 7 0.447715 7 1V7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H7V15C7 15.5523 7.44772 16 8 16C8.55229 16 9 15.5523 9 15V9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H9V1C9 0.447715 8.55229 0 8 0Z" fill="#ffffff"/>
                            </svg>
                        </span>
                    </div>
                );
            });
        } else {
            items = items.map((image, i) => (
                <SingleImage
                    isMobile = { isMobile }
                    width = { geometry.boxes[i].width }
                    height = { geometry.boxes[i].height }
                    top = { geometry.boxes[i].top }
                    left = { geometry.boxes[i].left }
                    platform = { platform }
                    image = { image }
                    key = { i }
                    showModal = { onImageClick }/>
            ));
        }

        return items;
    };
    const getSearchInput = () => {
        const { q, image_type, safesearch, orientation, category, colors, min_width, min_height } = parameters;

        const isBtnActive = category.value
            || !isEmpty(colors.value)
            || image_type.value !== 'all'
            || orientation.value !== 'all'
            || min_width.value
            || min_height.value
            || !safesearch.value;

        return <div className = { `gac-images-search ${ isFilters ? 'gac-filters-on' : '' }` }>
            <div className = 'gac-search-input-wrap'>
                <input
                    className = 'gac-search-input'
                    placeholder = 'Search images'
                    type = 'text'
                    value = { isEmpty(q) || !q.value ? '' : q.value }
                    data-hj-whitelist
                    onBlur = { onSearchInputBlur }
                    onChange = { onSearchInputChange }
                    onKeyPress = { onSearchInputKeyPress } />
                <span className = 'gac-search__total'>Total: { isEmpty(q) || !q.value ? '1,400,000' : total }</span>
                <span className = 'gac-search__btn' onClick = { onSearchIconClick }/>
            </div>
            <span
                className = { `gac-filters-btn ${ isBtnActive ? 'gac-filters-btn-active' : '' }` }
                onClick = { toggleFilters }><i>{ isMobile ? <FilterBig/> : <Filter/> }</i>Filters</span>
        </div> ;
    };
    const getFilters = () => {
        const { image_type, safesearch, orientation, category, colors, min_width, min_height } = parameters;

        const categoriesData = [{
            value: '',
            title: 'All categories',
        }];
        if ( !isNil(imageCategories) ) {
            imageCategories.forEach((cat) => {
                categoriesData.push({
                    value: cat['category_name'].toLowerCase(),
                    title: cat['category_name'],
                });
            });
        }

        return(
            <motion.div
                initial = {{ opacity: 0, height: 0 }}
                animate = {{ opacity: isFilters ? 1 : 0, height: isFilters ? isMobile ? `${window.innerHeight - 245}px` : 'auto' : 0 }}
                transition = {{ ease: 'easeInOut' }}
                className = 'gac-images-filters'>
                { platform === 'pixabay' && <FilterItem
                    items = { filters.types }
                    parameter = 'image_type'
                    value = { image_type.value }
                    title = { image_type.title }
                    onChange = { onFilterChange } /> }
                <FilterItem
                    items = { filters.orientation }
                    parameter = 'orientation'
                    value = { orientation.value }
                    title = { orientation.title }
                    onChange = { onFilterChange }/>
                { platform === 'pixabay' && <FilterItem
                    items = { categoriesData }
                    parameter = 'category'
                    value = { category.value }
                    title = { category.title }
                    onChange = { onFilterChange }/> }
                { platform === 'pixabay' && <FilterItemSize
                    minWidth = { min_width.value }
                    minHeight = { min_height.value }
                    onBlur = { onMinWidthHeightBlur }
                    onChange = { onMinWidthHeightChange }/> }
                { includes(platform, 'pixabay/pexels') && <FilterItemColors
                    parameter = 'colors'
                    colors = { colors.value.join(',') }
                    title = { colors.value.map((color) => color.charAt(0).toUpperCase() + color.slice(1)).join(', ') }
                    onChange = { onColorsChange }/> }
                { platform === 'pixabay' && <div className = 'gac-filter-item__safesearch'>
                    <label htmlFor = 'gac-safesearch'>
                        <input
                            id = 'gac-safesearch'
                            name = 'safesearch'
                            type = 'checkbox'
                            checked = { safesearch.value }
                            onChange = { onSafeSearchChange } />
                        <i>{ safesearch.value ? <CheckedCheckbox/> : <Checkbox/> }</i> Safe search
                    </label>
                </div> }
                { isMobile
                    ? <div className = 'gac-filters-btns'>
                        <span className = 'gac-btn gac-btn-s' onClick = { applyMobileFilters }>Apply</span>
                        <span className = 'gac-btn-v2 gac-btn-s' onClick = { clearFilters }>Clear all</span>
                    </div>
                    : null }
            </motion.div>
        );
    };
    const getCarousel = () => {
        const { q } = parameters;
        const isCarousel = isNil(q) || !q.value;
        const carouselConfig = {
            modules: [Navigation],
            slidesPerView: 6,
            spaceBetween: 8,
            slidesPerGroup: 6,
            breakpoints: {
                320: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                800: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
                980: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
                1024: {
                    slidesPerView: 6,
                    slidesPerGroup: 6,
                }
            }
        };

        /* Html */
        const CarouselButtonPrev = () => {
            const swiper = useSwiper();
            return(
                <div className="gac-carousel-btns gac-prev" onClick = { () => { swiper.slidePrev() } }/>
            );
        };
        const CarouselButtonNext = () => {
            const swiper = useSwiper();
            return(
                <div className="gac-carousel-btns gac-next" onClick = { () => { swiper.slideNext() } }/>
            );
        };
        const items = isNil(imagesCarousel) ? [] : imagesCarousel.map((image, i) => {
            return(
                <SwiperSlide data-category = { image.name } className = 'gac-carousel-item' key = { i } onClick = { onCarouselItemClick }>
                    <img alt = '' src = { image['image_large'] }/>
                    <span>{ image.name }</span>
                </SwiperSlide>
            );
        });

        return <motion.div
            animate = {{ opacity: isCarousel ? 1 : 0, height: isCarousel ? 'auto' : 0, marginBottom: isCarousel ? 24 : 0 }}
            transition = {{ ease: 'easeInOut' }}
            className = 'gac-categories-carousel'>
            <div className = 'gac-categories-carousel-wrap'>
                <Swiper { ...carouselConfig }>
                    <CarouselButtonPrev/>
                    { items }
                    <CarouselButtonNext/>
                </Swiper>
            </div>
        </motion.div> ;
    };
    const getContent = () => {
        const geometry = getGeometry();
        const items = getImages();

        return (
            <>
                <ResourceSelect platform = { platform } onResourceClick = { onResourceClick } />
                { getSearchInput() }
                { getFilters() }
                { getCarousel() }
                { !isNil(images) && !isEmpty(items)
                    ? <div className = 'gac-free-images-wrap' style = {{ height: `${geometry.containerHeight}px` }}>{ items }</div>
                    : !isNil(images) && <EmptyState page = 'images' /> }
                { !isNil(images) && !isEmpty(items)
                    ? <div className = 'gac-free-images-pagination-wrap'>
                        <div className = 'gac-free-images-text'>
                            <p>Images released under <a href = 'https://creativecommons.org/publicdomain/zero/1.0/deed.en' rel = 'noopener noreferrer' target = '_blank'>CC0 Creative Commons</a> by <a href = { platformObj[platform].url } rel = 'noopener noreferrer' target = '_blank'>{ platformObj[platform].label }</a>:</p>
                            <p><i/>Free for commercial use</p>
                            <p><i/>No attribution required</p>
                        </div>
                        { total > 12 && !isNil(images) && !isEmpty(items)
                            ? <>
                                <div className = 'gac-free-images-pagination'>
                                    <span data-page={page-1} className = {`gac-btn ${page === 1 ? 'gac-disabled' : ''} gac-btn-s`} onClick = { onPaginationBtnClick }>Previous page</span>
                                    <input
                                        type = 'number'
                                        value = { page }
                                        data-hj-whitelist
                                        onChange = { onPageInputChange }
                                        onKeyPress = { onPageInputKeyPress } />
                                    <span className = 'gac-free-images__total-pages'>/ { total <= perPage ? '1' : Math.ceil(total/pagesQty) }</span>
                                    <span data-page={page+1} className = {`gac-btn ${page === Math.ceil(total/pagesQty) ? 'gac-disabled' : ''} gac-btn-s`} onClick = { onPaginationBtnClick }>Next page</span>
                                </div>
                            </>
                            : null }
                    </div>
                    : null }
            </>
        );
    };
    const getHtml = () => {
        const geometry = getGeometry();
        const items = isNil(images) ? [] : images.filter((image, i) => i <= geometry.boxes.length - 1 );
        const height = type === 'modal'
            ? null
            : ( !isNil(images) && !isEmpty(items)) || isNil(images) ? 'auto' : window.innerHeight;
        const classStr = type === 'modal'
            ? 'gac-library'
            : `gac-free-images main-container ${ isMobile ? 'gac-mobile-view' : '' } ${ (!isNil(images) && !isEmpty(items)) || isNil(images) ? '' : 'gac-free-images-empty' }`;

        return <div className = { classStr } style = {{ height }}>
            { getContent() }
        </div>;
    };

    return getHtml();
};