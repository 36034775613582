// Core
import { asyncTypes } from './asyncTypes';

export const userActionsAsync = Object.freeze({
    fetchUserDetailsAsync: () => ({
        type: asyncTypes.FETCH_USER_DETAILS_ASYNC,
    }),
    fetchCompanyAsync: (action) => ({
        type: asyncTypes.FETCH_COMPANY_ASYNC,
        payload: {
            action
        },
    }),
    fetchTalentsAsync: (data) => ({
        type: asyncTypes.FETCH_TALENTS_ASYNC,
        payload: data,
    }),
    fetchTalentsBlacklistAsync: (type, accountId) => ({
        type: asyncTypes.FETCH_TALENTS_BLACKLIST_ASYNC,
        payload: {
            type,
            accountId
        },
    }),
    fetchCopywritersListAsync: (list) => ({
        type: asyncTypes.FETCH_COPYWRITERS_LIST_ASYNC,
        payload: list,
    }),
    updateTalentAsync: (data, action) => ({
        type: asyncTypes.UPDATE_TALENT_ASYNC,
        payload: {
            data,
            action,
        }
    }),
    fetchBalanceAsync: (account_id) => ({
        type: asyncTypes.FETCH_BALANCE_ASYNC,
        payload: account_id,
    }),
    fetchDataByIpAsync: () => ({
        type: asyncTypes.FETCH_DATA_BY_IP_ASYNC,
    }),
    fetchAccountsAsync: () => ({
        type: asyncTypes.FETCH_ACCOUNTS_ASYNC,
    }),
    fetchAccountAsync: (accountId, action) => ({
        type: asyncTypes.FETCH_ACCOUNT_ASYNC,
        payload: {
            accountId,
            action
        },
    }),
    createCompanyAsync: (company, navigate) => ({
        type: asyncTypes.CREATE_COMPANY_ASYNC,
        payload: {
            company,
            navigate
        }
    }),
    updateCompanyAsync: (company, action) => ({
        type: asyncTypes.UPDATE_COMPANY_ASYNC,
        payload: {
            company,
            action
        },
    }),
    createAccountAsync: (account, navigate) => ({
        type: asyncTypes.CREATE_ACCOUNT_ASYNC,
        payload: {
            account,
            navigate
        },
    }),
    updateAccountAsync: (account, action) => ({
        type: asyncTypes.UPDATE_ACCOUNT_ASYNC,
        payload: {
            account,
            action
        },
    }),
    deleteAccountAsync: (accountId, navigate) => ({
        type: asyncTypes.DELETE_ACCOUNT_ASYNC,
        payload: {
            accountId,
            navigate
        },
    }),
    checkEmailExistAsync: (email) => ({
        type: asyncTypes.CHECK_EMAIL_EXIST_ASYNC,
        payload: email,
    }),
    restorePasswordAsync: (email) => ({
        type: asyncTypes.RESTORE_PASSWORD_ASYNC,
        payload: email,
    }),
    sendProspectAsync: (email, phone) => ({
        type: asyncTypes.SEND_PROSPECT_ASYNC,
        payload: {
            email,
            phone
        },
    }),
    sendNpsAsync: (data) => ({
        type: asyncTypes.SEND_NPS_ASYNC,
        payload: data,
    }),
    updatePasswordAsync: (password, secret, navigate) => ({
        type: asyncTypes.UPDATE_PASSWORD_ASYNC,
        payload: {
            password,
            secret,
            navigate
        },
    }),

    fetchUsersAsync: () => ({
        type: asyncTypes.FETCH_USERS_ASYNC,
    }),
    addUserToAccountAsync: (userId, accountId, isCurrentUser) => ({
        type: asyncTypes.ADD_USER_TO_ACCOUNT_ASYNC,
        payload: {
            userId,
            accountId,
            isCurrentUser
        }
    }),
    removeUserFromAccountAsync: (userId, accountId, isCurrentUser) => ({
        type: asyncTypes.REMOVE_USER_FROM_ACCOUNT_ASYNC,
        payload: {
            userId,
            accountId,
            isCurrentUser
        }
    }),
    createUserAsync: (user, action, navigate) => ({
        type: asyncTypes.CREATE_USER_ASYNC,
        payload: {
            user,
            action,
            navigate
        },
    }),
    updateUserAsync: (user, isCurrentUser, action) => ({
        type: asyncTypes.UPDATE_USER_ASYNC,
        payload: {
            user,
            isCurrentUser,
            action
        },
    }),
    deleteUserAsync: (userId, action, navigate) => ({
        type: asyncTypes.DELETE_USER_ASYNC,
        payload: {
            userId,
            action,
            navigate
        },
    }),
    updateOwnPasswordAsync: (password, action) => ({
        type: asyncTypes.UPDATE_OWN_PASSWORD_ASYNC,
        payload: {
            password,
            action
        },
    }),
    fetchProposalAsync: (id) => ({
        type: asyncTypes.FETCH_PROPOSAL_ASYNC,
        payload: id,
    }),
    updateProposalCompanyAsync: (company, isProposalCompanyUpdated) => ({
        type: asyncTypes.UPDATE_PROPOSAL_COMPANY_ASYNC,
        payload: {
            company,
            isProposalCompanyUpdated
        },
    }),
    getReferralIdAsync: () => ({
        type: asyncTypes.GET_REFERRAL_ID_ASYNC,
    }),
    getReferralInfoAsync: () => ({
        type: asyncTypes.GET_REFERRAL_INFO_ASYNC,
    }),
    createSubscriptionAsync: (subscription, navigate, action) => ({
        type: asyncTypes.CREATE_SUBSCRIPTION_ASYNC,
        payload: {
            subscription,
            navigate,
            action
        },
    }),
    updateSubscriptionAsync: (data, navigate, action) => ({
        type: asyncTypes.UPDATE_SUBSCRIPTION_ASYNC,
        payload: {
            data,
            navigate,
            action
        },
    }),
    updateSubscriptionCustomAsync: (data, navigate, action) => ({
        type: asyncTypes.UPDATE_SUBSCRIPTION_CUSTOM_ASYNC,
        payload: {
            data,
            navigate,
            action
        },
    }),
    cancelSubscriptionAsync: (data, action, navigate) => ({
        type: asyncTypes.CANCEL_SUBSCRIPTION_ASYNC,
        payload: {
            data,
            action,
            navigate
        },
    }),
    pauseSubscriptionAsync: (data, navigate) => ({
        type: asyncTypes.PAUSE_SUBSCRIPTION_ASYNC,
        payload: {
            data,
            navigate
        },
    }),
    cancelPauseSubscriptionAsync: (data, navigate) => ({
        type: asyncTypes.CANCEL_PAUSE_SUBSCRIPTION_ASYNC,
        payload: {
            data,
            navigate
        },
    }),
    createPublicationAsync: (publication, action) => ({
        type: asyncTypes.CREATE_PUBLICATION_ASYNC,
        payload: {
            publication,
            action
        },
    }),
    updatePublicationAsync: (publication, action) => ({
        type: asyncTypes.UPDATE_PUBLICATION_ASYNC,
        payload: {
            publication,
            action
        },
    }),
    changeCardAsync: (data) => ({
        type: asyncTypes.CHANGE_CARD_ASYNC,
        payload: data,
    }),
    validateCnameAsync: (domain) => ({
        type: asyncTypes.VALIDATE_CNAME_ASYNC,
        payload: {
            domain
        },
    }),
    createDesignSubscriptionAsync: (subscription, navigate, action) => ({
        type: asyncTypes.CREATE_DESIGN_SUBSCRIPTION_ASYNC,
        payload: {
            subscription,
            navigate,
            action
        },
    }),
    updateDesignSubscriptionAsync: (data, navigate, action) => ({
        type: asyncTypes.UPDATE_DESIGN_SUBSCRIPTION_ASYNC,
        payload: {
            data,
            navigate,
            action
        },
    }),
    fetchBrandProfilesAsync: () => ({
        type: asyncTypes.FETCH_BRAND_PROFILES_ASYNC,
    }),
    createBrandProfileAsync: (profile) => ({
        type: asyncTypes.CREATE_BRAND_PROFILE_ASYNC,
        payload: {
            profile,
        },
    }),
    updateBrandProfileAsync: (profile) => ({
        type: asyncTypes.UPDATE_BRAND_PROFILE_ASYNC,
        payload: {
            profile,
        },
    }),
    fetchCompanySubscriptionsAsync: (action) => ({
        type: asyncTypes.FETCH_COMPANY_SUBSCRIPTIONS_ASYNC,
        payload: {
            action
        },
    }),
    createDesignerAsync: (data, navigate) => ({
        type: asyncTypes.CREATE_DESIGNER_ASYNC,
        payload: {
            data,
            navigate
        },
    }),
    assignAccountsToSubAsync: (data) => ({
        type: asyncTypes.ASSIGN_ACCOUNTS_TO_SUB_ASYNC,
        payload: data,
    }),
    getHubspotTokenAsync: (email, firstName, lastName) => ({
        type: asyncTypes.GET_HUBSPOT_TOKEN_ASYNC,
        payload: {
            email,
            firstName,
            lastName
        },
    }),
    downloadTransactionsAsync: (id, range) => ({
        type: asyncTypes.DOWNLOAD_TRANSACTIONS_ASYNC,
        payload: {
            id,
            range
        },
    }),
});
