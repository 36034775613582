// Core
import { useEffect } from "react";

// Components
import Dotdotdot from 'react-dotdotdot';
import { ProjectType } from '../../Manage/ProjectType';
import { Link, useNavigate } from 'react-router-dom';

// Hooks
import { useModals } from "../../../hooks/useModals";
import { useLists } from "../../../hooks/useLists";
import { useProject } from "../../../hooks/useProject";
import { useUser } from "../../../hooks/useUser";
import { useUi } from "../../../hooks/useUi";

// Instruments
import { isNil, isEmpty } from 'ramda';
import { /*addUtcOffset,*/ getDesignFormat, getFormat, isSelfClientFn } from '../../../helpers/helpers';

export const ModalEditProject = ({ closeModal }) => {
    /* Hooks */
    const { activeProject, setModalState } = useModals();
    const { staticData: { formats } } = useLists();
    const { deleteProjectAsync, fetchProjectDetailsAsync } = useProject();
    const { details: { user_role, /*timezone*/ }} = useUser();
    const { setUiState } = useUi();
    const navigate = useNavigate();

    const { project_id, topic, status, format, project_tags, created_by, creator_logo, writer_name, /*deadline, planned_publish*/ talent, job_type } = activeProject;

    // const deadlineData = isNil(timezone) ? deadline : addUtcOffset(deadline, timezone);
    let formatTitle = '';
    if ( job_type === 'design' ) {
        formatTitle = getDesignFormat(formats['design'], format)
    } else if (job_type === 'motion') {
        formatTitle = getDesignFormat(formats['motion'], format)
    } else if (job_type === 'keywords') {
        formatTitle = 'Keyword research';
    } else if (format) {
        formatTitle = getFormat(formats['writing'], format)
    }

    /* Actions */
    const generateTeam = ({ invite_writers }) => {
        const team = invite_writers ? JSON.parse(invite_writers) : [];

        let myTeamHtml, teamList;
        if ( team.length > 1 && team.length < 4 ) {
            myTeamHtml = team.reverse()
                .map((item, i) => {
                    return(
                        <div
                            className = 'gac-my-team-item'
                            key = { i }>
                            <div className = 'gac-my-team-item-avatar'/>
                            <div className = 'gac-my-team-item-name'>
                                { `${item.writer_first_name} ${item.writer_last_name}` }
                            </div>
                        </div>
                    );
                });
        } else if ( team.length > 3 ) {
            myTeamHtml = team.filter((item, i) => i <= 2)
                .reverse()
                .map((item, i) => {
                    return(
                        <div
                            className = 'gac-my-team-item'
                            key = { i }>
                            <div className = 'gac-my-team-item-avatar'/>
                            <div className = 'gac-my-team-item-name'>
                                { `${item.writer_first_name} ${item.writer_last_name}` }
                            </div>
                        </div>
                    );
                });

            teamList = team.filter((item, i) => i > 2 && i < 13)
                .map((item, i) => {
                    return(
                        <div key = { i }>{ `${item.writer_first_name} ${item.writer_last_name}` }</div>
                    );
                });
        }

        return (
            team.length === 0
                ? null
                : team.length === 1
                    ? <div className = 'gac-modal-project-field'>
                        <span className = 'gac-modal-project-field__label'>Writer:</span>
                        <span className = 'gac-modal-project-field__value gac-modal__avatar'>
                    { `${ team[0].writer_first_name } ${team[0].writer_last_name}` }
                            <span/>
                </span>
                    </div>
                    : <div className = 'gac-modal-project-field'>
                        <span className = 'gac-modal-project-field__label'>Writer:</span>
                        <span className = 'gac-modal-project-field__value'>
                            <div className = 'gac-my-team'>
                                { myTeamHtml }
                                {
                                    team.length > 3
                                        ? <div className = "gac-my-team-rest">
                                            { `+${team.length - 3}` }
                                            <div
                                                className = "gac-my-team-rest-list"
                                                style={{
                                                    top: `-${40 + 22*(teamList.length - (team.length > 13 ? 0 : 1))}px`
                                                }}>
                                                { teamList }
                                                { team.length > 13 && <div>{ `+ ${team.length - 13} more` }</div> }
                                            </div>
                                        </div>
                                        : <div className = "gac-my-team-rest"/>
                                }
                            </div>
                        </span>
                    </div>
        );
    };
    const onMessageWriter = () => {
        setUiState('shouldOpenMessageForm',true);
    };
    const onProjectOrder = () => {
        fetchProjectDetailsAsync(project_id,'editing',navigate);
        closeModal();
    };
    const onProjectDuplicate = () => {
        fetchProjectDetailsAsync(project_id,'duplicating',navigate);
        closeModal();
    };
    const onProjectDelete = () => {
        deleteProjectAsync(project_id, 'delete_from_calendar');
        closeModal();
    };

    useEffect(() => {
        return () => {
            setModalState('activeProject', null);
        };
    }, []);

    /* Html */
    const getFields = () => {
        return(
            <div className = 'gac-modal-project-fields'>
                <div className = 'gac-modal-project-field'>
                    <span className = 'gac-modal-project-field__label'>Project ID:</span>
                    <div className='gac-field-right'>
                        <span className = 'gac-modal-project-field__value'>{ project_id }</span>
                    </div>
                </div>
                { formatTitle && <div className = 'gac-modal-project-field'>
                    <span className = 'gac-modal-project-field__label'>Format:</span>
                    <div className='gac-field-right'>
                        <ProjectType type = { talent === 'bloggers' && job_type === 'writing' ? 'blogging' : job_type } />
                        <span className = 'gac-modal-project-field__value'>{ formatTitle }</span>
                    </div>
                </div> }
                <div className = 'gac-modal-project-field'>
                    <span className = 'gac-modal-project-field__label'>Tags:</span>
                    <Dotdotdot className = 'gac-modal-project-field__value' clamp = { 1 }>
                        { project_tags ? JSON.parse(project_tags).join(', ') : '' }
                    </Dotdotdot>
                </div>
                { !isNil(created_by) && !isEmpty(created_by) ?
                    <div className = 'gac-modal-project-field'>
                        <span className = 'gac-modal-project-field__label'>Created by:</span>
                        <span className = 'gac-modal-project-field__value gac-modal__avatar'>
                        { created_by }
                            { isNil(creator_logo) || isEmpty(creator_logo)
                                ? <span/> :
                                <img
                                    className='gac-creator-logo'
                                    src = {creator_logo}
                                    alt = 'Creator logo'/> }
                    </span>
                    </div>
                    : null }
                { 'draft,pitching,matching'.includes(status)
                    ? generateTeam(activeProject)
                    : writer_name
                        ? <div className = 'gac-modal-project-field'>
                            <span className = 'gac-modal-project-field__label'>Writer:</span>
                            <span className = 'gac-modal-project-field__value gac-modal__avatar'>
                            { writer_name }
                                <span/>
                        </span>
                        </div>
                        : null }
                <div className = 'gac-modal-project-field'>
                    <span className = 'gac-modal-project-field__label'>Status:</span>
                    <span style={{ textTransform: 'capitalize' }} className = 'gac-modal-project-field__status'><i className = { status }/>
                        { status === 'auto' ? 'Approved' : status === 'matching' ? 'Queued' : status }
                    </span>
                </div>
            </div>
        );
    };
    const getBtns = () => {
        return(
            <div className = 'gac-modal-btns'>
                { status === 'draft' ? <span className = 'gac-btn gac-btn-s' onClick = { onProjectOrder }>Edit</span> : null }
                { status !== 'draft' && status !== 'approval' ? <Link className = 'gac-btn gac-btn-s' to = { `/project/${project_id}` }>View</Link> : null }
                { status === 'approval' ? <Link className = 'gac-btn gac-btn-s' to = { `/project/${project_id}/content` }>Review</Link> : null }
                { 'writing/revision/editing/approved/cancelled'.includes(status) && !isSelfClientFn(user_role)
                    ? <Link className = 'gac-btn-link' onClick = { onMessageWriter } to = { `/project/${project_id}/messages` }>Message writer</Link>
                    : null }
                <span className = 'gac-btn-link' onClick = { onProjectDuplicate }>Duplicate</span>
                { status === 'draft' ? <span className = 'gac-btn-link' onClick = { onProjectDelete }>Delete</span> : null }
            </div>
        );
    };

    return <div className = 'gac-modal gac-edit-project-modal'>
        <div className = 'gac-modal-mobile-wrap'>
            <span className = 'gac-close-modal' onClick = { closeModal }/>
            <h2>{ topic }</h2>
            { getFields() }
            {/* job_type !== 'design' && <div className = 'gac-modal-project-time'>
            <div className = 'gac-modal-project-time-field'>
                <span className = 'gac-modal-project-time-field__label'>Current step due:</span>
                <span className = 'gac-modal-project-time-field__value'>{ moment(deadlineData).format('MMM D, YYYY h:mma') }</span>
            </div> */}
            {/*<div className = 'gac-modal-project-time-field'>*/}
            {/*<span className = 'gac-modal-project-time-field__label'>Planned publish:</span>*/}
            {/*<span className = 'gac-modal-project-time-field__value'>{ moment(planned_publish).format('MMM D, YYYY h:mma') }</span>*/}
            {/*</div>*/}
            {/*</div> }*/}
        </div>
        { getBtns() }
    </div> ;
};