// Core
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { socketActions } from "../bus/socket/actions";

export const useSocket = () => {
    const selector = (state) => state.socket;
    const socket = useSelector(selector);
    const dispatch = useDispatch();

    const initPusher = () => {
        dispatch(socketActions.initPusher());
    };
    const initPusherSuccess = (pusher) => {
        dispatch(socketActions.initPusherSuccess(pusher));
    };
    const setChannel = (name,channel) => {
        dispatch(socketActions.setChannel(name,channel));
    };

    return {
        ...socket,
        initPusher,
        initPusherSuccess,
        setChannel,
    };
};
