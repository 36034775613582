// Core
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

// Components
import { MobileHeader } from "./MobileHeader";
import { MobileMenu } from './MobileMenu';

// Hooks
import { useUi } from "../../../hooks/useUi";

// Instruments
import { isWhiteLabel, setBodyOverflow } from '../../../helpers/helpers';

export const Mobile = () => {
    /* State */
    const [isMobileMenu, setIsMobileMenu] = useState(false);
    const [mobileMenu, setMobileMenu] = useState('');

    /* Hooks */
    const { isAuth } = useUi();
    const { pathname } = useLocation();

    /* Actions */
    const showMobileMenu = () => {
        setBodyOverflow('hidden');
        setIsMobileMenu(true);
    };
    const hideMobileMenu = () => {
        setBodyOverflow('auto');
        setIsMobileMenu(false);
    };
    const toggleMobileMenu = (item) => {
        setMobileMenu(item);
    };

    /* Did mount */
    useEffect(() => {
        const menu =
            pathname === '/calendar'
                ? 'plan'
                :  pathname.includes('/images') || pathname.includes('/order')
                    ? 'order'
                    : '/draft-projects/current-projects/completed-projects'.includes(pathname)
                        ? 'manage'
                        : '';

        setMobileMenu(menu);

        return () => {
            setBodyOverflow('auto');
        };
    }, []);

    /* Did update */
    useEffect(() => {
        hideMobileMenu();
    }, [pathname]);

    const isProposal = pathname.includes('proposal');
    const isWLA = isWhiteLabel() && !isAuth;

    return(
        <>
            <MobileHeader showMenu = { showMobileMenu }/>
            { !isProposal && !isWLA && <MobileMenu
                activeMenu = { mobileMenu }
                isMobileMenu = { isMobileMenu }
                hideMenu = { hideMobileMenu }
                toggleMenu = { toggleMobileMenu }/> }
        </>
    );
};